import CheckCircle from "@mui/icons-material/CheckCircle";
import WarningAmber from "@mui/icons-material/WarningAmber";
import {
  Alert,
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import { BigTooltip } from "components/BigTooltip";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import {
  getRegulatoryStatusDiscrepancies,
  wasteGeneratorStatusToLabel,
} from "encamp-shared/src/waste/index";
import { RcraInfoFederalWasteGeneratorStatus } from "generated-graphql/graphql";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { useWasteFacility } from "./useWasteFacility";

const GeneratorStatusTooltips: Record<
  RcraInfoFederalWasteGeneratorStatus,
  ReactNode
> = {
  [RcraInfoFederalWasteGeneratorStatus.VerySmallQuantityGenerator]: (
    <Box>
      <Typography variant="caption" fontStyle="italic">
        Very Small Quantity Generator (VSQG)
      </Typography>
      <br />
      <Typography variant="caption">
        Generated 220 lbs or less of hazardous waste per month [or combo of
        hazardous + acutely hazardous] or generated less than 2.2 lbs of acutely
        hazardous waste per month
      </Typography>
    </Box>
  ),
  [RcraInfoFederalWasteGeneratorStatus.SmallQuantityGenerator]: (
    <Box>
      <Typography variant="caption" fontStyle="italic">
        Small Quantity Generator (SQG)
      </Typography>
      <br />
      <Typography variant="caption">
        Generated more than 220 lbs and less than 2,200 lbs of hazardous waste
        per month [or combo of hazardous + acutely hazardous] or generated less
        than 2.2 lbs of acutely hazardous waste per month
      </Typography>
    </Box>
  ),
  [RcraInfoFederalWasteGeneratorStatus.LargeQuantityGenerator]: (
    <Box>
      <Typography variant="caption" fontStyle="italic">
        Large Quantity Generator (LQG)
      </Typography>
      <br />
      <Typography variant="caption">
        Generated more than 2,200 lbs of hazardous waste per month [or combo of
        hazardous + acutely hazardous] or generated more than 2.2 lbs of acutely
        hazardous waste per month
      </Typography>
    </Box>
  ),
  [RcraInfoFederalWasteGeneratorStatus.NotAGenerator]: null,
  [RcraInfoFederalWasteGeneratorStatus.Pending]: null,
  [RcraInfoFederalWasteGeneratorStatus.Undetermined]: null,
};

type GeneratorStatusIndicatorProps = {
  status: RcraInfoFederalWasteGeneratorStatus;
  type?: "circle" | "chip";
  active?: boolean;
};

function GeneratorStatusIndicator({
  status,
  type = "chip",
  active,
}: GeneratorStatusIndicatorProps) {
  const theme = useTheme();

  const infoTooltip = GeneratorStatusTooltips[status];

  const backgroundColor = active ? yellow[400] : grey[50];
  const border = active
    ? `${type === "circle" ? "3px" : "2px"} solid ${yellow[600]}`
    : "none";
  const textColor = active ? theme.palette.text.primary : grey[400];

  const childElement =
    type === "chip" ? (
      <Chip
        label={wasteGeneratorStatusToLabel(status)}
        size="small"
        variant="filled"
        clickable={false}
        sx={{
          width: "56px",
          height: "24px",
          backgroundColor,
          border,
          color: textColor,
          cursor: "pointer",
        }}
      />
    ) : type === "circle" ? (
      <Box
        sx={{
          width: "56px",
          height: "56px",
          borderRadius: "50%",
          backgroundColor,
          border,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography variant="body2" color={textColor}>
          {wasteGeneratorStatusToLabel(status)}
        </Typography>
      </Box>
    ) : (
      <></>
    );

  return <BigTooltip title={infoTooltip}>{childElement}</BigTooltip>;
}

function GeneratorStatusRow({
  status,
  type,
}: {
  status: GeneratorStatusIndicatorProps["status"];
  type?: GeneratorStatusIndicatorProps["type"];
}) {
  return (
    <Stack direction="row" spacing={3}>
      {[
        RcraInfoFederalWasteGeneratorStatus.VerySmallQuantityGenerator,
        RcraInfoFederalWasteGeneratorStatus.SmallQuantityGenerator,
        RcraInfoFederalWasteGeneratorStatus.LargeQuantityGenerator,
      ].map((rcraStatus) => (
        <GeneratorStatusIndicator
          key={rcraStatus}
          status={rcraStatus}
          type={type}
          active={status === rcraStatus}
        />
      ))}
    </Stack>
  );
}

function GeneratorStatusInfoTooltip() {
  return (
    <Stack spacing={2} width="200px">
      <Typography variant="caption" fontWeight={700}>
        Generator Status
      </Typography>
      <Typography variant="caption">
        This shows the facility's generator status obtained or calculated from a
        variety of sources:
      </Typography>
      <Typography variant="caption">
        <Typography variant="caption" fontStyle="italic" component="span">
          Current
        </Typography>
        <br />
        <Typography variant="caption">
          Generator Status currently listed in RCRAInfo.
        </Typography>
      </Typography>
      <Typography variant="caption">
        <Typography variant="caption" fontStyle="italic" component="span">
          Calculated Vendor
        </Typography>
        <br />
        <Typography variant="caption">
          The highest generator status for a month within the current year,
          calculated from vendor manifests ingested by Encamp.
        </Typography>
      </Typography>
      <Typography variant="caption">
        <Typography variant="caption" fontStyle="italic" component="span">
          Calculated e-Manifest
        </Typography>
        <br />
        <Typography variant="caption">
          The highest generator status for a month within the current year,
          calculated from e-Manifest manifests ingested by Encamp.
        </Typography>
      </Typography>
      <Typography variant="caption">
        If the facility's calculated generator statuses differ from the current
        reported generator status, you may need to file an update notification
        with the EPA.
      </Typography>
      <Typography variant="caption">
        If a status is not highlighted, it has either not been reported or we do
        not have this data.
      </Typography>
    </Stack>
  );
}

export const GeneratorStatusTile = () => {
  return (
    <DashboardTile xs={5}>
      <GeneratorStatusTileContent />
    </DashboardTile>
  );
};

const GeneratorStatusTileContent = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { data } = useWasteFacility(facilityId);

  const regulatoryStatus = data.facility?.regulatoryStatus;

  if (!regulatoryStatus) {
    return null;
  }

  const rcraInfoStatus = regulatoryStatus?.rcraInfo;
  const vendorStatus = regulatoryStatus?.vendorHighestStatusThisYear;
  const eManifestStatus = regulatoryStatus?.eManifestHighestStatusThisYear;
  const discrepancies = getRegulatoryStatusDiscrepancies(regulatoryStatus);
  const hasStatusDiscrepancy = discrepancies.length > 0;
  const hasVendorDiscrepancy = discrepancies.some((d) =>
    d.includes("vendor calculated generator status.")
  );
  const hasEManifestDiscrepancy = discrepancies.some((d) =>
    d.includes("eManifest calculated generator status.")
  );
  const missingStatus = RcraInfoFederalWasteGeneratorStatus.Undetermined;
  const hasCombinedDiscrepancies =
    hasVendorDiscrepancy &&
    hasEManifestDiscrepancy &&
    // Both missing or neither missing
    ((vendorStatus === missingStatus && eManifestStatus === missingStatus) ||
      (vendorStatus !== missingStatus && eManifestStatus !== missingStatus));

  return (
    <>
      <DashboardTile.Header
        title="Generator Status"
        infoTooltip={<GeneratorStatusInfoTooltip />}
      >
        {!hasStatusDiscrepancy ? (
          <BigTooltip title="The statuses between what's calculated from ingested manifest data compared to RCRAInfo are the same and you likely do not need to file an update notification with the EPA.">
            <CheckCircle color="success" sx={{ cursor: "pointer" }} />
          </BigTooltip>
        ) : null}
      </DashboardTile.Header>

      <DashboardTile.ContentArea>
        <Stack spacing={2}>
          <Typography variant="caption" fontWeight={700}>
            Current Generator Status via RCRAInfo
          </Typography>

          <GeneratorStatusRow status={rcraInfoStatus} type="circle" />

          <Divider />

          <Stack spacing={1}>
            <Typography variant="caption">
              Calculated Vendor Generator Status YTD
            </Typography>

            <GeneratorStatusRow status={vendorStatus} />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="caption">
              Calculated e-Manifest Generator Status YTD
            </Typography>

            <GeneratorStatusRow status={eManifestStatus} />
          </Stack>

          {hasStatusDiscrepancy && (
            <>
              {/* Show combined message if both have discrepancies */}
              {hasCombinedDiscrepancies ? (
                <Alert severity="warning" icon={<WarningAmber />}>
                  <Typography variant="caption">
                    {vendorStatus ===
                    RcraInfoFederalWasteGeneratorStatus.Undetermined
                      ? "Vendor and e-Manifest manifest data missing."
                      : `Based on your vendor and e-Manifest data, your calculated
                    generator status is different from what is reported in
                    RCRAInfo. You may need to file an update notification with
                    the EPA.`}
                  </Typography>
                </Alert>
              ) : (
                // Otherwise show individual messages as needed
                <>
                  {hasVendorDiscrepancy && (
                    <Alert severity="warning" icon={<WarningAmber />}>
                      <Typography variant="caption">
                        {vendorStatus ===
                        RcraInfoFederalWasteGeneratorStatus.Undetermined
                          ? "Vendor manifest data missing."
                          : `Based on your vendor manifest data, your calculated
                    generator status is different from what is reported in
                    RCRAInfo. You may need to file an update notification with
                    the EPA.`}
                      </Typography>
                    </Alert>
                  )}
                  {hasEManifestDiscrepancy && (
                    <Alert severity="warning" icon={<WarningAmber />}>
                      <Typography variant="caption">
                        {eManifestStatus ===
                        RcraInfoFederalWasteGeneratorStatus.Undetermined
                          ? "e-Manifest manifest data missing."
                          : `Based on your e-Manifest data, your calculated generator
                    status is different from what is reported in RCRAInfo. You
                    may need to file an update notification with the EPA.`}
                      </Typography>
                    </Alert>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </DashboardTile.ContentArea>
    </>
  );
};
