import {
  FacilityRegulatoryStatus,
  RcraInfoFederalWasteGeneratorStatus,
  WasteManifestSource,
} from "../generated-graphql/oak-resolver-types";
import { prettyPrintEnumValue } from "../utils/prettyPrintEnumValue";

export const wasteGeneratorStatusToLabel = (
  status: RcraInfoFederalWasteGeneratorStatus
) => {
  switch (status) {
    case RcraInfoFederalWasteGeneratorStatus.VerySmallQuantityGenerator:
      return "VSQG";
    case RcraInfoFederalWasteGeneratorStatus.SmallQuantityGenerator:
      return "SQG";
    case RcraInfoFederalWasteGeneratorStatus.LargeQuantityGenerator:
      return "LQG";
    case RcraInfoFederalWasteGeneratorStatus.Undetermined:
      return "Missing";
    default:
      return prettyPrintEnumValue(status);
  }
};

export function wasteManifestSourceToLabel(
  source?: WasteManifestSource | null
): string {
  if (source === null || source === undefined) {
    return "";
  }

  switch (source) {
    case WasteManifestSource.CleanEarth:
      return "Clean Earth";
    case WasteManifestSource.UsEcology:
      return "US Ecology";
    case WasteManifestSource.Republic:
      return "Republic";
    case WasteManifestSource.CleanHarbors:
      return "Clean Harbors";
    case WasteManifestSource.Wm:
      return "Waste Management";
    case WasteManifestSource.EManifest:
      return "e-Manifest";
    case WasteManifestSource.Other:
      return "Other";
    default:
      ((_: never) => {
        throw new Error(`Unexpected value: ${_}`);
      })(source);
  }
}

export function regulatoryStatusesDiscrepanceExists(
  ...statuses: RcraInfoFederalWasteGeneratorStatus[]
) {
  // For the purposes of comparison, treat Undetermined and NotAGenerator as the same
  const normalizedStatuses = statuses.map((status) =>
    status === RcraInfoFederalWasteGeneratorStatus.Undetermined
      ? RcraInfoFederalWasteGeneratorStatus.NotAGenerator
      : status
  );

  return new Set(normalizedStatuses).size > 1;
}

export function getRegulatoryStatusDiscrepancies(
  regulatoryStatus: FacilityRegulatoryStatus
) {
  const {
    rcraInfo,
    eManifestHighestStatusThisYear: eManifest,
    vendorHighestStatusThisYear: vendor,
  } = regulatoryStatus;

  const discrepancies: string[] = [];

  if (regulatoryStatusesDiscrepanceExists(rcraInfo, eManifest)) {
    discrepancies.push(
      `There is a discrepancy between the last reported generator status in RCRAInfo and the eManifest calculated generator status.\n    RCRAInfo: ${wasteGeneratorStatusToLabel(
        rcraInfo
      )}\n    eManifest: ${wasteGeneratorStatusToLabel(eManifest)}`
    );
  }

  if (regulatoryStatusesDiscrepanceExists(rcraInfo, vendor)) {
    discrepancies.push(
      `There is a discrepancy between the last reported generator status in RCRAInfo and the vendor calculated generator status.\n    RCRAInfo: ${wasteGeneratorStatusToLabel(
        rcraInfo
      )}\n    Vendor: ${wasteGeneratorStatusToLabel(vendor)}`
    );
  }

  if (regulatoryStatusesDiscrepanceExists(eManifest, vendor)) {
    discrepancies.push(
      `There is a discrepancy between the eManifest and vendor calculated generator statuses.\n    eManifest: ${wasteGeneratorStatusToLabel(
        eManifest
      )}\n    Vendor: ${wasteGeneratorStatusToLabel(vendor)}`
    );
  }

  return discrepancies;
}

export function getRegulatoryStatusIssueDetails(
  regulatoryStatus: FacilityRegulatoryStatus
) {
  const {
    rcraInfo,
    eManifestHighestStatusThisYear: eManifest,
    vendorHighestStatusThisYear: vendor,
  } = regulatoryStatus;

  if (regulatoryStatusesDiscrepanceExists(rcraInfo, eManifest, vendor)) {
    return `RCRAInfo: ${wasteGeneratorStatusToLabel(rcraInfo)}
e-Manifest: ${wasteGeneratorStatusToLabel(eManifest)}
Vendor: ${wasteGeneratorStatusToLabel(vendor)}`;
  }

  return null;
}
export function getRegulatoryStatusIssueSummary(
  regulatoryStatus: FacilityRegulatoryStatus
) {
  const {
    rcraInfo,
    eManifestHighestStatusThisYear: eManifest,
    vendorHighestStatusThisYear: vendor,
  } = regulatoryStatus;

  if (regulatoryStatusesDiscrepanceExists(rcraInfo, eManifest, vendor)) {
    return `There is a discrepancy between the last reported
generator status in RCRAInfo and calculated
generator status.

    RCRAInfo: ${wasteGeneratorStatusToLabel(rcraInfo)}
    e-Manifest: ${wasteGeneratorStatusToLabel(eManifest)}
    Vendor: ${wasteGeneratorStatusToLabel(vendor)}`;
  }

  return null;
}
