import { startCase } from "lodash";

export function prettyPrintModelIssueModelName(modelName: string) {
  switch (modelName) {
    case "ChemicalComponent":
      return "Chemical Component";
    case "ChemicalStateField":
      return "Chemical State Field";
    case "FacilityAlternateId":
      return "Government ID";
    case "FacilityChemicalMeasurement":
    case "FacilityChemical":
    case "FacilityChemicalStorageLocation":
      return "Chemical Inventory";
    case "FacilityChemicalStateField":
      return "Chemical Inventory State Field";
    case "FacilityContact":
    case "Person":
      return "Contact";
    case "FacilityDocument":
      return "Document";
    case "FacilityProductMeasurement":
    case "FacilityProduct":
    case "FacilityProductStorageLocation":
      return "Product Inventory";
    case "FacilityStateField":
      return "Facility State Field";
    case "ProductChemicalOverride":
    case "ProductChemical":
    case "Product":
      return "Product Catalog";
    case "ReportingFacilityChemicalOrigin":
    case "ReportingFacilityChemical":
    case "ReportingFacilityChemicalStorageLocation":
      return "Report Chemical";
    case "ReportingFacilityChemicalStateField":
      return "Report Chemical State Field";
    case "StorageLocation":
      return "Storage Location";
    case "Tenant":
      return "Organization";
    case "TierIIReport":
      return "Report";
    default:
      return startCase(modelName);
  }
}
