import { useSuspenseQuery } from "@apollo/client";
import { GridProps, Typography } from "@mui/material";
import { grey, lightGreen } from "@mui/material/colors";
import { DefaultizedPieValueType } from "@mui/x-charts";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import DonutChart from "components/Dashboard/DonutChart";
import { gql } from "generated-graphql";
import { TierIiReportKind } from "generated-graphql/graphql";
import { useTenant } from "hooks/useTenant";
import { useDeferredValue } from "react";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";
import { ReportKindPicker } from "./ReportKindPicker";

const ENCAMP_FILING_STATUS_QUERY = gql(`
  query EncampFilingStatus($tenantId: ID!, $reportKind: TierIIReportKind!, $filter: ChemicalOverviewFilter) {
    encampFilingStatus(tenantId: $tenantId, reportKind: $reportKind, filter: $filter) {
      totalWaitingToReceive
      totalWaitingToFile
      totalFiled
    }
  }
`);

export function EncampFilingStatusTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <EncampFilingStatusContent />
    </DashboardTile>
  );
}

function EncampFilingStatusContent() {
  const {
    chemicalOverviewState: {
      deferredFilters: { filingStatusReportKind = TierIiReportKind.Annual },
    },
    setChemicalOverviewState,
  } = useChemicalOverviewState();

  const setReportKind = (reportKind: TierIiReportKind) => {
    setChemicalOverviewState((state) => {
      state.filingStatusReportKind = reportKind;
    });
  };

  return (
    <>
      <DashboardTile.Header
        title="Encamp Filing Status"
        infoTooltip={<EncampFilingStatusTooltip />}
      >
        <ReportKindPicker
          reportKind={filingStatusReportKind}
          setReportKind={setReportKind}
        />
      </DashboardTile.Header>
      <DashboardTile.ContentArea>
        <EncampFilingStatusChart reportKind={filingStatusReportKind} />
      </DashboardTile.ContentArea>
    </>
  );
}

function EncampFilingStatusChart(props: { reportKind: TierIiReportKind }) {
  const { reportKind } = props;
  const { tenantId = "" } = useTenant();
  const {
    chemicalOverviewState: { deferredFilters: filter },
    drillDown,
  } = useChemicalOverviewState();

  const { data } = useSuspenseQuery(ENCAMP_FILING_STATUS_QUERY, {
    variables: {
      tenantId,
      reportKind: useDeferredValue(reportKind),
      filter: {
        assignedToMe: filter.assignedToMe,
        tagNames: filter.facilityTags,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const totalFiled = data?.encampFilingStatus.totalFiled ?? 0;
  const totalWaitingToFile = data?.encampFilingStatus.totalWaitingToFile ?? 0;
  const totalWaitingToReceive =
    data?.encampFilingStatus.totalWaitingToReceive ?? 0;

  const totalReports = totalFiled + totalWaitingToFile + totalWaitingToReceive;

  const percentageFiled =
    totalReports > 0 ? Math.round((totalFiled / totalReports) * 100) : 0;

  const seriesData = [
    {
      id: 1,
      label: `Waiting to Receive (${totalWaitingToReceive})`,
      value: totalWaitingToReceive,
    },
    {
      id: 2,
      label: `Received by Encamp (${totalWaitingToFile})`,
      value: totalWaitingToFile,
    },
    {
      id: 3,
      label: `Filing Complete (${totalFiled})`,
      value: totalFiled,
    },
  ];

  const handleDrillDown = (item: DefaultizedPieValueType) => {
    drillDown((f) => {
      const filter = {
        ...f,
        reportKind:
          reportKind === TierIiReportKind.Annual ? "Annual|null" : "Initial",
      };

      if (item.id === 1) {
        return {
          ...filter,
          encampStatus: "AWAITING_VERIFICATION|null",
          organizationStatus: "!NOT_REPORTING",
        };
      } else if (item.id === 2) {
        return {
          ...filter,
          organizationStatus: "VERIFIED",
          encampStatus: "PROCESSING",
        };
      } else if (item.id === 3) {
        return {
          ...filter,
          encampStatus: "FILING_COMPLETE",
        };
      }

      return filter;
    });
  };

  return (
    <DonutChart
      seriesData={seriesData}
      containerHeight="20rem"
      legend={{
        direction: "column",
        position: "below",
      }}
      colors={[grey[200], lightGreen[100], lightGreen[400]]}
      label={`${percentageFiled}%`}
      subLabel={`${totalFiled} of ${totalReports}\n reports filed`}
      seriesOverrides={{
        innerRadius: 95,
        outerRadius: 150,
      }}
      onItemClick={(event, pieItemIdentifier, item) => handleDrillDown(item)}
      onLegendItemClick={(item) => handleDrillDown(item)}
    />
  );
}

function EncampFilingStatusTooltip() {
  return (
    <Typography variant="caption">
      <Typography component="span" variant="caption" fontWeight={800}>
        Encamp Filing Status
      </Typography>{" "}
      displays the progress of your reports in the submission process. "Waiting
      to Receive" reports are expected from your reporting facilities but have
      not yet been submitted to Encamp.
    </Typography>
  );
}
