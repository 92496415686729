import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { ConfirmDialog } from "components/ConfirmDialog";
import { IssueCount } from "components/IssueCount";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  Issue,
  ProductChemicalFormFragment,
  ProductInput,
} from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import React, { useCallback, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { formatChemicalName } from "util/chemicalName";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { ProductFormState } from ".";
import { DataGrid } from "../../../../../components/DataGrid";
import { NoRowsOverlay } from "../../Inventory/Facility/NoRowsOverlay";

export type ProductChemicalRow = ProductChemicalFormFragment & {
  issues: Issue[];
};

interface ProductChemicalDataGridProps {
  productName: string;
  selectedProductChemical?: ProductChemicalRow;
  setSelectedProductChemical: (pc: ProductChemicalRow | undefined) => void;
  setOpenAddEditModal: (open: boolean) => void;
}

const ProductChemicalDataGrid: React.FC<ProductChemicalDataGridProps> = ({
  productName,
  selectedProductChemical,
  setSelectedProductChemical,
  setOpenAddEditModal,
}) => {
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "chemicalName", sort: "asc" },
  ]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { watch } = useFormContext<ProductFormState>();

  const productChemicals = watch("productChemicals");

  const selectedProductChemicalIndex = useMemo(
    () =>
      productChemicals.findIndex(
        (productChemical) => productChemical.id === selectedProductChemical?.id
      ),
    [productChemicals, selectedProductChemical]
  );

  const { remove } = useFieldArray<ProductInput>({
    name: "productChemicals",
  });

  const handleDelete = async () => {
    if (!selectedProductChemical) return;

    remove(selectedProductChemicalIndex);
    setOpenDeleteDialog(false);
  };

  const handleOpenProductForm = useCallback(
    (productChemical?: ProductChemicalRow) => {
      setSelectedProductChemical(productChemical);
      setOpenAddEditModal(true);
    },
    [setSelectedProductChemical, setOpenAddEditModal]
  );

  const handleDeleteClick = useCallback(
    (chemical: ProductChemicalRow) => {
      setSelectedProductChemical(chemical);
      setOpenDeleteDialog(true);
    },
    [setSelectedProductChemical, setOpenDeleteDialog]
  );

  const productChemicalColumns: GridColDef<
    NonNullable<typeof productChemicals>[number]
  >[] = [
    {
      field: "chemicalName",
      headerName: "Chemical Name",
      flex: 1,
      valueGetter({ row }) {
        return row.chemical.name;
      },
      renderCell: ({ row }) => (
        <Tooltip title={formatChemicalName(row.chemical)}>
          <span>{row.chemical?.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) =>
        prettyPrintQuantity(params.row.amount, params.row.unit, true),
    },
    {
      field: "storageType",
      headerName: "Storage Type",
      flex: 1,
      renderCell: (params) => prettyPrintEnumValue(params.row.storageType),
    },
    {
      field: "pressure",
      headerName: "Pressure",
      flex: 1,
      renderCell: (params) => prettyPrintEnumValue(params.row.pressure),
    },
    {
      field: "temperature",
      headerName: "Temperature",
      flex: 1,
      renderCell: (params) => prettyPrintEnumValue(params.row.temperature),
    },
    {
      field: "issues",
      headerName: "Issues",
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell(params) {
        return <IssueCount issueCount={params.row.issues?.length} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <Tooltip title="Edit" key={1}>
          <GridActionsCellItem
            onClick={() => handleOpenProductForm(params.row)}
            label="Edit Chemical"
            icon={<EditIcon />}
          />
        </Tooltip>,
        <Tooltip title="Delete" key={2}>
          <GridActionsCellItem
            onClick={() => handleDeleteClick(params.row)}
            label="Delete Chemical"
            icon={<DeleteIcon />}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          sx={{ marginBottom: 2, marginRight: "auto", flex: "1" }}
        >
          {`Chemicals in ${productName}`}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenProductForm()}
          sx={{ marginBottom: 2, marginLeft: "auto" }}
          size="small"
        >
          Add Chemical
        </Button>
      </Box>

      <DataGrid
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            minHeight: "18rem",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
            border: "none",
          },
        }}
        disableRowSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rows={productChemicals}
        columns={productChemicalColumns}
        slots={{
          noRowsOverlay: (props) => (
            <NoRowsOverlay
              {...props}
              onButtonClick={() => handleOpenProductForm()}
              message="No chemicals have been added to this product yet."
              buttonText="Add Chemical"
            />
          ),
        }}
        onRowClick={({ row }) => handleOpenProductForm(row)}
      />

      <ConfirmDialog
        msg={`Are you sure you want to remove
              ${selectedProductChemical?.chemical.name} from this product?`}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default ProductChemicalDataGrid;
