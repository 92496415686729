import { getEPCRAContactRoles } from "encamp-shared/src/facilityContact/roles";
import { ContactReportRole } from "generated-graphql/graphql";
import { useFeatureFlags } from "./useFeatureFlags";

/**
 * Get the EPCRA facility contact role options for the state these facilities reside in
 */
export function useEpcraContactRoles(
  ...states: Array<string | null | undefined>
): {
  roles: ContactReportRole[];
  loading: boolean;
} {
  const { featureFlags, loading: featureFlagsLoading } = useFeatureFlags();

  const roles = getEPCRAContactRoles(featureFlags?.hmbp ?? false, ...states);

  return { roles, loading: featureFlagsLoading };
}
