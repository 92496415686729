import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback, useEffect, useState } from "react";

const CHECK_FOR_FILE = gql(`query CheckForFile($key: String!) {
    checkForFile(key: $key) {
      exists
      signedUrl
    }
  }`);

export const useCheckForFile = ({ key }: { key?: string | undefined }) => {
  const [checkForFile] = useLazyQuery(CHECK_FOR_FILE, {
    fetchPolicy: "network-only",
  });

  const fetchFile = useCallback(async () => {
    if (key) {
      const { data } = await checkForFile({ variables: { key } });
      return data?.checkForFile?.signedUrl;
    }
    return undefined;
  }, [key, checkForFile]);

  return { fetchFile };
};
