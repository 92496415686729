import { Grid, Skeleton, Stack, useTheme } from "@mui/material";

export function StateInfoSkelly() {
  const theme = useTheme();
  return (
    <Stack gap={theme.spacing(2)}>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>
    </Stack>
  );
}
