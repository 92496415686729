import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useTenant } from "hooks/useTenant";
import { Suspense } from "react";
import { FacilityDetailsTile } from "./FacilityDetailsTile";
import { GeneratorStatusTile } from "./GeneratorStatusTile";
import { WasteFacilitySkeleton } from "./WasteFacilitySkeleton";
import { WasteShipmentsTile } from "./WasteShipments/WasteShipmentTile";
import { WasteStreamsTile } from "./WasteStreamsTile";
import { WasteFacilityProvider } from "./WasteFacilityContext";

export const WasteFacility = () => {
  const { tenantId } = useTenant();
  useBreadcrumb([
    {
      label: "Waste",
      to: tenantId ? `/o/${tenantId}/waste` : undefined,
    },
    {
      label: "Facilities",
      to: tenantId ? `/o/${tenantId}/waste/facilities` : undefined,
    },
  ]);

  return (
    <DashboardLayout>
      <Suspense fallback={<WasteFacilitySkeleton />}>
        <WasteFacilityProvider>
          <FacilityDetailsTile />
          <GeneratorStatusTile />
          <WasteShipmentsTile />
          <WasteStreamsTile />
        </WasteFacilityProvider>
      </Suspense>
    </DashboardLayout>
  );
};
