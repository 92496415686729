import { useQuery } from "@apollo/client";
import Launch from "@mui/icons-material/Launch";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem, GridSortModel } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { prettyPrintModelIssueModelName } from "encamp-shared/src/utils/prettyPrintModelIssue";
import { gql } from "generated-graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useTenant } from "hooks/useTenant";
import { useState } from "react";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";

const PROLIFIC_ISSUES_QUERY = gql(`
  query ProlificIssues($tenantId: ID!, $filter: ChemicalOverviewFilter, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    prolificIssues(tenantId: $tenantId, filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        issue {
          id
          modelName
          dataName
          message
        }
        reportsAffected
        url
      }
      count
    }
  }
`);

export const ProlificIssuesDataGrid = () => {
  const { tenantId } = useTenant();
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "reportsAffected", sort: "desc" },
  ]);
  const {
    chemicalOverviewState: { deferredFilters: filter },
  } = useChemicalOverviewState();

  const { data, previousData, loading } = useQuery(PROLIFIC_ISSUES_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: {
        assignedToMe: filter.assignedToMe,
        tagNames: filter.facilityTags,
      },
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
    },
  });

  return (
    <DataGrid
      columns={[
        {
          field: "modelName",
          headerName: "Data Type",
          flex: 1,
          valueGetter: ({
            row: {
              issue: { modelName },
            },
          }) => prettyPrintModelIssueModelName(modelName),
          sortable: false,
        },
        {
          field: "dataName",
          headerName: "Data",
          flex: 1,
          valueGetter: ({
            row: {
              issue: { dataName },
            },
          }) => dataName,
          sortable: false,
        },
        {
          field: "message",
          headerName: "Issue",
          flex: 1,
          valueGetter: ({
            row: {
              issue: { message },
            },
          }) => message,
          sortable: false,
        },
        {
          field: "reportsAffected",
          headerName: "Reports Affected",
          headerAlign: "center",
          align: "center",
          width: 160,
          sortable: false,
        },
        {
          field: "actions",
          type: "actions",
          getActions: ({ row: { issue, url } }) => [
            url ? (
              <GridActionsCellItem
                key="open"
                onClick={() => {
                  window.open(url, "_blank");
                }}
                label={`View ${prettyPrintModelIssueModelName(
                  issue.modelName
                )}`}
                icon={
                  <Tooltip
                    title={`View ${prettyPrintModelIssueModelName(
                      issue.modelName
                    )}`}
                  >
                    <Launch />
                  </Tooltip>
                }
              />
            ) : (
              <></>
            ),
          ],
        },
      ]}
      rows={
        data?.prolificIssues.items ?? previousData?.prolificIssues.items ?? []
      }
      rowCount={
        data?.prolificIssues.count ?? previousData?.prolificIssues.count ?? 0
      }
      getRowId={(row) => row.issue.id}
      loading={loading}
      noRowsMessage="No prolific issues found!"
      isRowSelectable={() => false}
      onRowClick={(event) => {
        if (event.row.url) {
          window.location.href = event.row.url;
        }
      }}
      getRowClassName={(params) => (!params.row.url ? "non-clickable-row" : "")}
      sx={{
        Height: "200px",
        "& .non-clickable-row": {
          cursor: "default !important",
        },
      }}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
    />
  );
};
