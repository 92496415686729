export function extractKeyValuePairs(
  search: string
): { key: string; value: string[]; start: number; end: number }[] {
  const results: {
    key: string;
    value: string[];
    start: number;
    end: number;
  }[] = [];
  const regex =
    /([^:\s]+):((?:"[^"]*"|[^,\s"]+)(?:,\s*(?:"[^"]*"|[^,\s"]+))*)/g;

  let match;
  while ((match = regex.exec(search)) !== null) {
    const key = match[1];
    const valueString = match[2];
    const start = match.index;
    const end = start + match[0].length; // Adjusted end calculation

    const values =
      valueString
        .match(/"[^"]*"|[^,\s"]+/g)
        ?.map((v) => v.replace(/^"(.*)"$/, "$1").trim()) || [];

    results.push({ key, value: values, start, end });
  }

  return results;
}
