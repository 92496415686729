import { useMutation, useQuery } from "@apollo/client";
import SyncAlt from "@mui/icons-material/SyncAlt";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import AsyncAutocomplete from "components/Forms/AsyncAutocomplete";
import { FormSelect } from "components/Forms/FormSelect";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { contactRoleToLabel } from "encamp-shared/src/facilityContact/roles";
import { gql } from "generated-graphql";
import {
  ContactReportRole,
  Person,
  SearchContactForReplaceQuery,
} from "generated-graphql/graphql";
import { useEpcraContactRoles } from "hooks/useEpcraContactRoles";
import { useTenant } from "hooks/useTenant";
import pluralize from "pluralize";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

enum ReplacePersonFacilityStrategy {
  ALL,
  SPECIFIC_ROLE,
}

type ContactReplaceDialogProps = {
  open: boolean;
  onClose: () => void;
};

type ReplaceContactFormInputs = {
  oldPersonId: string;
  newPersonId: string;
  strategy: ReplacePersonFacilityStrategy;
  role?: ContactReportRole;
};

export function ContactReplaceDialog({
  open,
  onClose,
}: ContactReplaceDialogProps) {
  const { tenantId } = useTenant();
  const theme = useTheme();
  const alerts = useAlerts();

  const query = gql(/* GraphQL */ `
    query SearchContactForReplace(
      $search: String
      $page: Int
      $pageSize: Int
      $sort: [SortModel!]
    ) {
      people(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          id
          tenantId
          first
          last
          email
        }
        count
      }
    }
  `);

  const mutation = gql(/* GraphQL */ `
    mutation ReplacePersonFacilityAssociations(
      $oldPersonId: ID!
      $newPersonId: ID!
      $reportingRoles: [ContactReportRole!]
    ) {
      replacePersonFacilityAssociations(
        newPersonId: $newPersonId
        oldPersonId: $oldPersonId
        reportingRoles: $reportingRoles
      )
    }
  `);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const [replacePerson, { loading }] = useMutation(mutation, {
    refetchQueries: ["Contacts"],
  });

  const {
    control,
    formState: { isValid: formIsValid },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<ReplaceContactFormInputs>({
    defaultValues: {
      strategy: ReplacePersonFacilityStrategy.ALL,
    },
  });

  const strategy = watch("strategy");

  const onSubmit = async () => {
    setConfirmDialogOpen(true);
  };

  const onConfirm = async () => {
    const data = getValues();

    await replacePerson({
      variables: {
        oldPersonId: data.oldPersonId,
        newPersonId: data.newPersonId,
        reportingRoles: data.role ? [data.role] : undefined,
      },
      onCompleted: () => {
        onClose();
        alerts.success("Reporting contact was successfully replaced");
      },
      onError: (error) => {
        console.error(error);
        alerts.error("An error occurred while replacing the reporting contact");
      },
    });
  };

  const { roles } = useEpcraContactRoles("CA");
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Replace Reporting Contact</DialogTitle>
        <DialogContent>
          <Stack gap={theme.spacing(1)}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Replace one contact with another across all of your facilities
                for one or all reporting roles.
              </Typography>

              <Controller
                control={control}
                name="oldPersonId"
                rules={{ required: "Old contact is required" }}
                render={() => (
                  <AsyncAutocomplete<
                    SearchContactForReplaceQuery,
                    Pick<Person, "id" | "first" | "last" | "email">,
                    Record<string, unknown>
                  >
                    query={query}
                    getItems={(data) => data.people.items}
                    placeholderLabel="Old Contact"
                    getOptionLabel={(item) => {
                      const fullName = `${item.first?.trim() ?? ""} ${
                        item.last?.trim() ?? ""
                      }`.trim();
                      return fullName
                        ? `${fullName} <${item.email}>`
                        : item.email ?? "";
                    }}
                    additionalSearchString={`tenantId:${tenantId}`}
                    onChange={(person) =>
                      setValue("oldPersonId", person?.id ?? "", {
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="newPersonId"
                rules={{ required: "New contact is required" }}
                render={() => (
                  <AsyncAutocomplete<
                    SearchContactForReplaceQuery,
                    Pick<Person, "id" | "first" | "last" | "email">,
                    Record<string, unknown>
                  >
                    query={query}
                    getItems={(data) => data.people.items}
                    placeholderLabel="New Contact"
                    getOptionLabel={(item) => {
                      const fullName = `${item.first?.trim() ?? ""} ${
                        item.last?.trim() ?? ""
                      }`.trim();
                      return fullName
                        ? `${fullName} <${item.email}>`
                        : item.email ?? "";
                    }}
                    additionalSearchString={`tenantId:${tenantId}`}
                    onChange={(person) =>
                      setValue("newPersonId", person?.id ?? "", {
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              />

              <RadioGroupField
                control={control}
                label=""
                subtitle=""
                radioOptions={[
                  {
                    value: ReplacePersonFacilityStrategy.ALL,
                    label:
                      "Replace the old contact for every role they are associated with at a facility.",
                  },
                  {
                    value: ReplacePersonFacilityStrategy.SPECIFIC_ROLE,
                    label:
                      "Replace the old contact only when they are associated with this role at a facility.",
                  },
                ]}
                name="strategy"
                direction="column"
                rules={{ required: "Strategy is required" }}
                onChange={(selection) => {
                  if (selection.value === ReplacePersonFacilityStrategy.ALL) {
                    setValue("role", undefined, {
                      shouldValidate: true,
                    });
                  } else {
                    setValue("role", ContactReportRole.EpcraTierIiContact, {
                      shouldValidate: true,
                    });
                  }
                }}
              />

              <FormSelect
                control={control}
                name={`role`}
                label="Reporting Role"
                selectItems={roles.map((r) => ({
                  value: r,
                  display: contactRoleToLabel(r),
                }))}
                disabled={
                  strategy !== ReplacePersonFacilityStrategy.SPECIFIC_ROLE
                }
                sx={{ marginLeft: 4, marginTop: -4, width: "50%" }}
              />

              <Box display="flex" justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  startIcon={<SyncAlt />}
                  disabled={!formIsValid}
                  loading={loading}
                >
                  Replace
                </LoadingButton>
              </Box>
            </form>
          </Stack>
        </DialogContent>
      </Dialog>
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          onCancel={() => setConfirmDialogOpen(false)}
          onConfirm={onConfirm}
          replacing={loading}
          formData={getValues()}
        />
      )}
    </>
  );
}

type ConfirmDialogProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  replacing: boolean;
  formData: ReplaceContactFormInputs;
};

function ConfirmDialog({
  open,
  onCancel,
  onConfirm,
  replacing,
  formData,
}: ConfirmDialogProps) {
  const query = gql(/* GraphQL */ `
    query ReplaceConfirmQuery(
      $search: String
      $page: Int
      $pageSize: Int
      $sort: [SortModel!]
    ) {
      people(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          id
          tenantId
          first
          last
          facilityContacts {
            id
            reportingRoles
          }
        }
        count
      }
    }
  `);

  const { data, loading } = useQuery(query, {
    variables: {
      search: `id:${formData.oldPersonId}|${formData.newPersonId}`,
    },
  });

  const oldPerson = data?.people.items.find(
    (p) => p.id === formData.oldPersonId
  );
  const oldPersonFullName = `${oldPerson?.first} ${oldPerson?.last}`.trim();
  const newPerson = data?.people.items.find(
    (p) => p.id === formData.newPersonId
  );
  const newPersonFullName = `${newPerson?.first} ${newPerson?.last}`.trim();
  const affectedFacilities =
    formData.strategy === ReplacePersonFacilityStrategy.ALL
      ? oldPerson?.facilityContacts?.length ?? 0
      : oldPerson?.facilityContacts?.filter((fc) =>
          fc.reportingRoles.find((rr) => rr === formData.role)
        ).length ?? 0;

  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Replace</DialogTitle>

      <DialogContent>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
        {data && (
          <>
            <Typography>
              You are about to replace{" "}
              <Typography component="span" sx={{ fontStyle: "italic" }}>
                {oldPersonFullName}
              </Typography>{" "}
              with{" "}
              <Typography component="span" sx={{ fontStyle: "italic" }}>
                {newPersonFullName}
              </Typography>{" "}
              as the reporting contact at all facilities and for all roles where
              they are associated.
            </Typography>

            <Typography sx={{ fontWeight: "800", mb: 2 }}>
              {affectedFacilities} {pluralize("facility", affectedFacilities)}{" "}
              will be affected.
            </Typography>

            <Typography sx={{ mb: 2 }}>
              This action will{" "}
              <Typography component="span" sx={{ fontWeight: "800" }}>
                not
              </Typography>{" "}
              affect the contact record for{" "}
              <Typography component="span" sx={{ fontStyle: "italic" }}>
                {oldPersonFullName}
              </Typography>
              , only their facility associations. Previously submitted reports
              will also not be affected.
            </Typography>

            <Typography sx={{ mb: 2 }}>
              Are you sure you want to replace the facility association for this
              contact?
            </Typography>

            <Box display="flex" justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>

              <LoadingButton
                variant="contained"
                startIcon={<SyncAlt />}
                loading={replacing}
                onClick={onConfirm}
              >
                Replace
              </LoadingButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
