import { DateTime } from "luxon";

export function prettyPrintDateMed(isoDateStr: string) {
  return DateTime.fromISO(isoDateStr, { zone: "utc" }).toLocaleString(
    DateTime.DATE_MED
  );
}

export function prettyPrintDateFull(isoDateStr: string) {
  return DateTime.fromISO(isoDateStr, { zone: "utc" }).toLocaleString(
    DateTime.DATE_FULL
  );
}

export function prettyPrintDate(isoDateStr: string) {
  return DateTime.fromISO(isoDateStr, { zone: "utc" }).toFormat("LL/dd/yyyy");
}

export function prettyPrintDateTime(isoDateStr: string) {
  return DateTime.fromISO(isoDateStr).toFormat("LL/dd/yyyy hh:mm a");
}

export function prettyPrintDateTimeWithSeconds(isoDateStr: string) {
  return DateTime.fromISO(isoDateStr).toFormat("LL/dd/yyyy hh:mm:ss a");
}

/**
 * Relative time is useful for logs, but after about a week it's better to see
 * the full date time.
 * After relativeCutoffHours, the date will be formatted as a full date -- defaults to a week.
 */
export function relativeLogTime(
  isoDateStr: string,
  relativeCutoffHours = 24 * 7
) {
  const now = DateTime.now();
  const relativeCutoffMoment = now.minus({ hours: relativeCutoffHours });
  const dateTime = DateTime.fromISO(isoDateStr);

  // Check if the date is more than a year ago
  if (dateTime < relativeCutoffMoment) {
    // Format it in a specific way, e.g., 'MM/dd/yyyy'
    return prettyPrintDateTime(isoDateStr);
  } else {
    // Use relative formatting
    return dateTime.toRelative() || "";
  }
}
