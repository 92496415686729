import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-premium";
import { Dialog } from "components/Dialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { contactRoleToLabel } from "encamp-shared/src/facilityContact/roles";
import { gql } from "generated-graphql";
import { ContactReportRole, FacilitiesQuery } from "generated-graphql/graphql";
import { useEpcraContactRoles } from "hooks/useEpcraContactRoles";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { ChangeEvent, useCallback, useState } from "react";
import { AssociatedFacilityContact } from "./AssociatedFacilities";

const CONTACT_FACILITIES_QUERY = gql(`
  query ContactFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        streetAddress1
        city
        state
        zip
        country
      }
      count
    }
  }
`);

export type Row = FacilitiesQuery["facilities"]["items"][number];

type ContactFacilitiesDialogProps = {
  open: boolean;
  facilityContacts: AssociatedFacilityContact[];
  onClose: () => void;
  onSave: (facilityContacts: AssociatedFacilityContact[]) => void;
};

export default function ContactFacilitiesDialog({
  open,
  facilityContacts,
  onClose,
  onSave,
}: ContactFacilitiesDialogProps) {
  const theme = useTheme();

  const { tenantId } = useTenant();

  // State to keep track of which facilities and roles are selected
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);
  const [selectedReportingRoles, setSelectedReportingRoles] = useState<
    ContactReportRole[]
  >([]);

  // Grid Columns Definition
  const columns: OmnisearchGridColDef<Row>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      sortable: true,
      filterKeyType: "facility",
    },
    {
      field: "streetAddress",
      headerName: "Street Address",
      flex: 0.5,
      sortable: true,
      renderCell(params) {
        const streetAddress = `${params.row.streetAddress1}, ${params.row.city}, ${params.row.state} ${params.row.zip}`;

        return <Typography variant="body2">{streetAddress}</Typography>;
      },
    },
  ];

  const onSelectedRowsChanged = useCallback((rows: Row[]) => {
    setSelectedRows((prevSelectedRows) => {
      const mergedRows: Record<string, Row> = {};
      [...prevSelectedRows, ...rows].forEach((row) => {
        mergedRows[row.id] = row;
      });
      return Object.values(mergedRows);
    });
  }, []);

  const onSelectedIdsChanged = useCallback((ids: GridRowId[]) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.filter((row) => ids.includes(row.id))
    );
  }, []);

  function onReportingRoleChange(
    event: ChangeEvent<HTMLInputElement>,
    role: ContactReportRole
  ) {
    if (event.target.checked) {
      setSelectedReportingRoles([...selectedReportingRoles, role]);
    } else {
      setSelectedReportingRoles(
        selectedReportingRoles.filter((r) => r !== role)
      );
    }
  }

  function onLinkFacilitiesClick() {
    onSave(
      selectedRows.map((row) => ({
        id: facilityContacts.find((fc) => fc.facility.id === row.id)?.id ?? "",
        facility: row,
        reportingRoles: selectedReportingRoles,
      }))
    );
    onClose();
  }

  function handleClose() {
    setSelectedRows([]);
    setSelectedReportingRoles([]);
    onClose();
  }

  const { roles } = useEpcraContactRoles(
    ...selectedRows.map((row) => row.state)
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{ sx: { minHeight: "95vh" } }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle>Select Facilities</DialogTitle>
      <DialogContent sx={{ p: theme.spacing(2) }}>
        <Grid container spacing={theme.spacing(2)}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ paddingX: theme.spacing(1) }}>
              Select one or more facilities and roles to associate with this
              contact.
            </Typography>
          </Grid>
          <Grid item xs={9.5}>
            <OmnisearchDataGrid
              columns={columns}
              dataQuery={CONTACT_FACILITIES_QUERY}
              isURLDriven={false}
              initialSortModel={[{ field: "name", sort: "asc" }]}
              defaultSearch={`tenantId:${tenantId}`}
              getItems={(data) => data.facilities.items}
              getCount={(data) => data.facilities.count ?? 0}
              noDataMessage="No facilities found for this organization yet."
              initialPageSize={10}
              onSelectedRowsChanged={onSelectedRowsChanged}
              onSelectedIdsChanged={onSelectedIdsChanged}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack>
              <Typography variant="body1" fontWeight={500}>
                EPCRA Reporting Roles
              </Typography>
              {roles.map((role) => (
                <Stack key={role} direction="row" alignItems="center">
                  <Checkbox
                    onChange={(event) => onReportingRoleChange(event, role)}
                  />
                  <Typography variant="body2">
                    {contactRoleToLabel(role)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FloatingSaveBar
          saveText="Link Facilities"
          onCancel={handleClose}
          onSaveClick={onLinkFacilitiesClick}
          saveDisabled={selectedRows.length < 1}
          sx={{ m: 0, px: 0, py: 1 }}
        />
      </DialogActions>
    </Dialog>
  );
}
