import {
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  SelectProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";
import React from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

export type SelectItem = {
  value: any;
  display: string;
  disabled?: boolean;
};

export const FormMultiselectWithOther = <T extends FieldValues>({
  name,
  label,
  tooltip,
  disabled,
  control,
  selectItems,
  helperText,
  rules,
  sx,
  hideErrors,
}: {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  selectProps?: SelectProps;
  selectItems: SelectItem[];
  helperText?: string;
  rules?: RegisterOptions;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
  selectSx?: SxProps<Theme>;
  hideErrors?: boolean;
} & UseControllerProps<T>) => {
  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  return (
    <FormControl fullWidth sx={sx} required={!!rules?.required}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <MultiselectWithOtherControl
            value={field.value}
            onChange={field.onChange}
            selectItems={selectItems}
            error={error}
            helperText={helperText}
            hideErrors={hideErrors}
            labelWithTooltip={labelWithTooltip}
            disabled={disabled}
          />
        )}
      />
    </FormControl>
  );
};

type MultiselectWithOtherControlProps = {
  value: string[];
  onChange: (value: string[]) => void;
  selectItems: SelectItem[];
  error: FieldError | undefined;
  helperText: string | undefined;
  hideErrors: boolean | undefined;
  labelWithTooltip: React.ReactNode;
  disabled: boolean | undefined;
};

const MultiselectWithOtherControl = (
  props: MultiselectWithOtherControlProps
): React.ReactNode => {
  const textFieldRef = useRef<HTMLInputElement>(null);

  const {
    value = [],
    onChange,
    selectItems,
    error,
    helperText,
    hideErrors,
    labelWithTooltip,
    disabled,
  } = props;
  const valueArray = useMemo(() => value ?? [], [value]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [otherValue, setOtherValue] = useState("");
  const [lastOtherValue, setLastOtherValue] = useState("");

  useEffect(() => {
    if (otherValue == "") {
      const foundOtherValue =
        valueArray.find((v) => !selectItems.some((i) => i.value === v)) ?? "";
      setOtherValue(foundOtherValue);
      setLastOtherValue(foundOtherValue);
    }
  }, [otherValue, valueArray, selectItems]);

  const open = Boolean(anchorEl);

  const handleItemClick = (itemValue: string) => {
    const newValue = valueArray.includes(itemValue)
      ? valueArray.filter((v) => v !== itemValue) // remove if already selected
      : [...valueArray, itemValue]; // add if not selected
    onChange(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOther = () => {
    if (otherValue !== lastOtherValue) {
      const newValue = [
        ...valueArray.filter((v) => v !== lastOtherValue),
        otherValue,
      ];
      onChange(newValue);
      setLastOtherValue(otherValue);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        ref={textFieldRef}
        error={!!error}
        disabled={disabled}
        label={labelWithTooltip}
        onClick={handleClick}
        value={valueArray.join(", ")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
                "& input": { cursor: "pointer" },
              }}
            >
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        sx={{
          cursor: "pointer",
          "& input": { cursor: "pointer" },
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: textFieldRef.current
                ? textFieldRef.current.offsetWidth
                : "auto",
            },
          },
        }}
      >
        {selectItems?.map((item: SelectItem) => (
          <MenuItem
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            onClick={() => handleItemClick(item.value)}
          >
            <Checkbox checked={valueArray.includes(item.value)} />
            <ListItemText primary={item.display} />
          </MenuItem>
        ))}
        <MenuItem key="other">
          <Checkbox
            onClick={() => handleItemClick(otherValue)}
            checked={valueArray.includes(otherValue)}
          />
          <TextField
            value={otherValue}
            onChange={(e) => setOtherValue(e.target.value)}
            onBlur={handleOther}
          />
        </MenuItem>
      </Popover>
      <ErrorDisplay
        error={error}
        helperText={helperText}
        hideErrors={hideErrors}
      />
    </>
  );
};
