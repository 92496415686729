import { useCallback } from "react";
import { useQueryParams } from "./useQueryParams";

/**
 * A custom React hook that provides navigation functionality with Omnisearch support.
 *
 * @template T - A type extending Record<string, any> to represent the filters object.
 * @param {Object} options - An object containing the initial navigation options.
 * @param {string} options.to - The initial navigation path.
 * @param {T} options.filters - An object containing the initial filter values.
 * @returns {(toOrUpdateFn: string | ((filters: T) => Record<string, any>), updateFn?: (filters: T) => Record<string, any>) => void}
 *          A function that, when called, will navigate to the specified path with updated Omnisearch parameters.
 *
 * @example
 * // Usage example:
 * const navigate = useNavigateWithOmnisearch({ to: "../reports", filters: { foo: 'bar' } });
 *
 * // Navigate with default path and updated filters
 * navigate(f => ({ ...f, hello: 'world' })); // ../reports?omnisearch=foo:bar%20hello:world
 *
 * // Navigate with custom path and filters
 * navigate("../chemicals", f => ({ ...f, hello: 'world' })); // ../chemicals?omnisearch=foo:bar%20hello:world
 *
 * // Navigate with default path and filters
 * navigate(); // ../reports?omnisearch=foo:bar
 */

export type NavigateCallback<T extends Record<string, any>> = (
  toOrUpdateFn?: string | ((filters: T) => Record<string, any>),
  updateFn?: (filters: T) => Record<string, any>
) => void;

export function useNavigateWithOmnisearch<T extends Record<string, any>>({
  to: initialTo,
  filters: initialFilters,
}: {
  to: string;
  filters: T;
}): NavigateCallback<T> {
  const { navigateWithFilters } = useQueryParams();

  return useCallback(
    (
      toOrUpdateFn?: string | ((filters: T) => Record<string, any>),
      updateFn?: (filters: T) => Record<string, any>
    ) => {
      let to: string;
      let filterUpdateFn: (filters: T) => Record<string, any>;

      if (typeof toOrUpdateFn === "function") {
        to = initialTo;
        filterUpdateFn = toOrUpdateFn;
      } else if (typeof toOrUpdateFn === "string") {
        to = toOrUpdateFn;
        filterUpdateFn = updateFn || ((f) => f);
      } else {
        to = initialTo;
        filterUpdateFn = (f) => f;
      }

      const newFilters = filterUpdateFn(initialFilters);
      navigateWithFilters(to, (f) => {
        // Convert the newFilters object into an Omnisearch string
        f.omnisearch = Object.entries(newFilters)
          .filter(([_, value]) => value !== undefined)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return `${key}:${value
                // Quote the value if it includes spaces or commas
                .map((v) => (v.includes(" ") || v.includes(",") ? `"${v}"` : v))
                .join(",")}`;
            }

            // Quote the value if it includes spaces or commas
            return `${key}:${
              value.includes(" ") || value.includes(",") ? `"${value}"` : value
            }`;
          })
          .join(" ");
      });
    },
    [initialTo, initialFilters, navigateWithFilters]
  );
}
