import { useTenant } from "hooks/useTenant";
import { ExportDataButton } from "components/ExportDataButton";

export function FacilitiesExportButton({ tenantId }: { tenantId?: string }) {
  const { tenant } = useTenant();

  return (
    <ExportDataButton
      exportType="facilities"
      disabled={!tenantId}
      tenantId={tenantId ?? ""}
      fileName={tenant?.name ? `${tenant.name}-facilities` : "facilities"}
    />
  );
}
