import { Stack } from "@mui/material";
import { blue, deepOrange, deepPurple, lime, pink } from "@mui/material/colors";
import { BarSeriesType } from "@mui/x-charts";
import { BarChart } from "components/Dashboard/BarChart";
import ChartLegend from "components/Dashboard/ChartLegend";
import { WasteShipmentsQuery } from "generated-graphql/graphql";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { formatNumber } from "util/formatNumber";
import { PriorityChartColorsPurple } from "util/insights";
import { WasteShipmentFilter } from "./WasteShipmentTile";

export const ACUTELY_HAZARDOUS_THRESHOLD = 2.2;
export const HAZARDOUS_THRESHOLD = 2200;
export const CHART_HAZARDOUS_THRESHOLD_TOLERANCE = 508;

export const allowedGraphTypes = [
  "hazardousWaste",
  "hazardousWasteByMonth",
  "highestGeneratingWasteStreams",
] as const;

type AllowedGraphType = (typeof allowedGraphTypes)[number];

export type WasteShipmentsGraphsProps = {
  graphType: AllowedGraphType;
  data: WasteShipmentsQuery | undefined;
  onItemClick: (event: any, item: any) => void;
  filter: WasteShipmentFilter;
};

export const WasteShipmentsGraphs = ({
  graphType,
  data,
  onItemClick,
  filter,
}: WasteShipmentsGraphsProps) => {
  if (!data) return null;

  switch (graphType) {
    case "hazardousWaste":
      return (
        <HazardousWaste filter={filter} data={data} onItemClick={onItemClick} />
      );
    case "hazardousWasteByMonth":
      return <HazardousWasteByMonth data={data} />;
    case "highestGeneratingWasteStreams":
      return <HighestGeneratingWasteStreams data={data} />;
    default:
      return null;
  }
};

const HazardousWaste = ({
  data,
  onItemClick,
  filter,
}: {
  data: WasteShipmentsQuery;
  onItemClick: (event: any, item: any) => void;
  filter: WasteShipmentFilter;
}) => {
  const focus = filter.hazardousWaste?.focus;
  const dataset = useMemo(() => {
    const monthlyData =
      data.wasteShipmentsByFacility?.hazardousWasteShipments?.byMonth ?? [];
    return monthlyData.map((monthData, index) => ({
      month: formatMonth(monthData.month, index === 0),
      acutelyHazardous: monthData.acutelyHazardous,
      hazardous: monthData.hazardous,
    }));
  }, [data]);

  const hasAcuteWaste = dataset.some(
    (d) => d.acutelyHazardous >= ACUTELY_HAZARDOUS_THRESHOLD
  );
  const hasHazWaste = dataset.some(
    (d) =>
      d.hazardous >= HAZARDOUS_THRESHOLD - CHART_HAZARDOUS_THRESHOLD_TOLERANCE
  );

  const allSeries: BarSeriesType[] = useMemo(
    () => [
      {
        type: "bar",
        id: "acutelyHazardous",
        dataKey: "acutelyHazardous",
        stack: "total",
        color: deepOrange[900],
        valueFormatter: (value) =>
          ` Acutely Hazardous Waste (${formatNumber(value)} lbs)`,
      },
      {
        type: "bar",
        id: "hazardous",
        dataKey: "hazardous",
        stack: "total",
        color: deepOrange[300],
        valueFormatter: (value) =>
          `Hazardous Waste (${formatNumber(value)} lbs)`,
      },
    ],
    []
  );

  const series = useMemo(() => {
    if (!focus) return allSeries;

    return allSeries.filter((series) => series.dataKey === focus);
  }, [allSeries, focus]);

  const referenceLines = [];
  if (hasAcuteWaste && filter.hazardousWaste?.focus !== "hazardous") {
    // Add the acute reference line
    referenceLines.push({
      y: ACUTELY_HAZARDOUS_THRESHOLD,
      lineStyle: {
        stroke: pink[300],
        strokeDasharray: "3 2",
        strokeWidth: 1,
      },
    });
  }

  if (hasHazWaste && filter.hazardousWaste?.focus !== "acutelyHazardous") {
    // Add the hazardous reference line
    referenceLines.push({
      y: HAZARDOUS_THRESHOLD,
      lineStyle: { stroke: blue[900], strokeDasharray: "3 2", strokeWidth: 1 },
    });
  }

  const scaleType: "linear" | "sqrt" = dataset.some(
    (d) => d.acutelyHazardous >= 100 || d.hazardous >= 100
  )
    ? "sqrt"
    : "linear";

  return (
    <BarChart
      dataset={dataset.length ? dataset : [{ amount: 0, label: "No data" }]}
      series={series}
      onItemClick={onItemClick}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "month",
          categoryGapRatio: 0.4,
        },
      ]}
      yAxis={[{ scaleType }]}
      referenceLines={referenceLines}
    />
  );
};

const HazardousWasteByMonth = ({ data }: { data: WasteShipmentsQuery }) => {
  const dataset = useMemo(() => {
    return (
      data.wasteShipmentsByFacility?.wasteShipmentsByMonth?.byMonth.map(
        (monthData, index) => ({
          month: formatMonth(monthData.month, index === 0),
          nonHazardous: monthData.nonHazardous,
          hazardous: monthData.hazardous,
          universal: monthData.universal,
        })
      ) ?? []
    );
  }, [data]);

  return (
    <BarChart
      dataset={dataset.length ? dataset : [{ amount: 0, label: "No data" }]}
      series={[
        {
          type: "bar",
          dataKey: "nonHazardous",
          color: lime[800],
          valueFormatter: (value) =>
            ` Non-hazardous Waste (${formatNumber(value)} lbs)`,
        },
        {
          type: "bar",
          dataKey: "hazardous",
          color: deepOrange[300],
          valueFormatter: (value) =>
            `Hazardous Waste (${formatNumber(value)} lbs)`,
        },
        {
          type: "bar",
          dataKey: "universal",
          color: deepPurple[300],
          valueFormatter: (value) =>
            `Universal Waste (${formatNumber(value)} lbs)`,
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "month",
          categoryGapRatio: 0.4,
          barGapRatio: 0.4,
        },
      ]}
      yAxis={[{ scaleType: "sqrt" }]}
    />
  );
};

const HighestGeneratingWasteStreams = ({
  data,
}: {
  data: WasteShipmentsQuery;
}) => {
  const dataset = useMemo(() => {
    return (
      data.wasteShipmentsByFacility?.highestWasteStreamShipments?.map(
        (stream) => ({
          name: stream.wasteStream?.dotDescription ?? "",
          amount: stream.amount,
        })
      ) ?? []
    );
  }, [data]);

  return (
    <Stack display="flex" direction="row" height="100%">
      <BarChart
        dataset={dataset.length ? dataset : [{ amount: 0, label: "No data" }]}
        series={[
          {
            type: "bar",
            dataKey: "amount",
            valueFormatter: (value) => ` ${formatNumber(value)} lbs`,
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "name",
            categoryGapRatio: 0.4,
            colorMap: {
              type: "ordinal",
              colors: PriorityChartColorsPurple,
            },
          },
        ]}
        yAxis={[{ scaleType: "linear" }]}
        indexAxisLabelProperty={dataset.length ? "name" : undefined}
      />
      <ChartLegend
        items={dataset.map((d, index) => ({
          color: PriorityChartColorsPurple[index],
          value: `${index + 1}. ${d.name}`,
        }))}
        sx={{ flex: 1, maxWidth: "35%" }}
      />
    </Stack>
  );
};

function formatMonth(date: string, isFirstMonth: boolean) {
  const month = DateTime.fromFormat(date, "yyyy-MM-dd");
  if (month.month === 1 || isFirstMonth) {
    return month.toFormat("LLL\nyyyy");
  }
  return month.toFormat("LLL");
}
