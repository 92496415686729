import { v4, v5 } from "uuid";
import { uuidV5Namespace } from "../constants/uuid";

export function uuidv5(name: string | ArrayLike<number>) {
  return v5(name, uuidV5Namespace);
}

export function sanitizeUuid(uuid: string) {
  return uuid.toLowerCase();
}

export function sanitizeOrGenerateUuid(uuid: string | undefined) {
  return uuid === "" || !uuid ? v4() : sanitizeUuid(uuid);
}
