import { Box, Stack } from "@mui/material";
import { ContactGrid } from "components/Contacts/ContactGrid";
import { ExportDataButton } from "components/ExportDataButton";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useTenant } from "hooks/useTenant";

export const Contacts: React.FC = () => {
  useBreadcrumb([{ label: "Contacts" }]);
  const { tenantId } = useTenant();

  if (!tenantId) {
    return <Stack spacing={2}>No tenant selected</Stack>;
  }

  const buttons = [
    <ExportDataButton
      key="export"
      disabled={!tenantId}
      tenantId={tenantId ?? ""}
      exportType="contact"
      fileName={`contact-catalog`}
    />,
  ];

  return (
    <Box sx={{ pt: 3 }}>
      <ContactGrid tenantId={tenantId} commandButtons={buttons} />
    </Box>
  );
};
