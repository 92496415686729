import { getFullName } from "encamp-shared/src/utils/name";
import { TierIiReportKind } from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import {
  NavigateCallback,
  useNavigateWithOmnisearch,
} from "hooks/useNavigateWithOmnisearch";
import { useQueryParams } from "hooks/useQueryParams";
import {
  createContext,
  useCallback,
  useContext,
  useDeferredValue,
  useMemo,
  useTransition,
} from "react";

type Filter = {
  assignedToMe: boolean;
  facilityTags: string[];
  filingStatusReportKind: TierIiReportKind;
  reportStatusReportKind: TierIiReportKind;
};

type DrillDownFilters = {
  assignedTo?: string;
  facilityTags?: string[];
};

export type ChemicalOverviewState = {
  immediateFilters: Partial<Filter>;
  deferredFilters: Partial<Filter>;
  isPending: boolean;
};

type ChemicalOverviewContextType = {
  chemicalOverviewState: ChemicalOverviewState;
  setChemicalOverviewState: (cb: (state: Partial<Filter>) => void) => void;
  drillDown: NavigateCallback<DrillDownFilters>;
};

const ChemicalOverviewStateContext = createContext<
  ChemicalOverviewContextType | undefined
>(undefined);

export function useChemicalOverviewState() {
  const context = useContext(ChemicalOverviewStateContext);
  if (!context) {
    throw new Error(
      "useChemicalOverviewState must be used within a ChemicalOverviewStateProvider"
    );
  }
  return context;
}

export const ChemicalOverviewStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useCurrentUser();
  const { filters, setFilters } = useQueryParams<Filter>();

  const deferredFilters = useDeferredValue(filters);

  const [isPending, startTransition] = useTransition();

  const chemicalOverviewState = useMemo(
    () => ({ deferredFilters, immediateFilters: filters, isPending }),
    [deferredFilters, filters, isPending]
  );

  const setChemicalOverviewState = useCallback(
    (cb: (state: Partial<Filter>) => void) => {
      startTransition(() => {
        setFilters((f) => {
          cb(f);
        });
      });
    },
    [setFilters]
  );

  const drillDown = useNavigateWithOmnisearch({
    to: "../reports",
    filters: {
      assignedTo:
        deferredFilters.assignedToMe && user?.person
          ? `"${getFullName(user.person)}"`
          : deferredFilters.assignedToMe
          ? user?.email
          : undefined,
      reportKind: deferredFilters.reportStatusReportKind ?? undefined,
      facilityTags: deferredFilters.facilityTags,
    },
  });

  const value = useMemo(
    () => ({
      chemicalOverviewState,
      setChemicalOverviewState,
      drillDown,
    }),
    [chemicalOverviewState, setChemicalOverviewState, drillDown]
  );

  return (
    <ChemicalOverviewStateContext.Provider value={value}>
      {children}
    </ChemicalOverviewStateContext.Provider>
  );
};
