import {
  createContext,
  useCallback,
  useContext,
  useDeferredValue,
  useMemo,
  useTransition,
} from "react";
import { useQueryParams } from "hooks/useQueryParams";

type GraphType =
  | "hazardousWaste"
  | "hazardousWasteByMonth"
  | "highestGeneratingWasteStreams";

type Filter = {
  graphType: GraphType;
  year: string;
};

export type WasteFacilityState = {
  immediateFilters: Partial<Filter>;
  deferredFilters: Partial<Filter>;
  isPending: boolean;
};

type WasteFacilityContextType = {
  wasteFacilityState: WasteFacilityState;
  setWasteFacilityState: (cb: (state: Partial<Filter>) => void) => void;
};

const WasteFacilityStateContext = createContext<
  WasteFacilityContextType | undefined
>(undefined);

export function useWasteFacilityState() {
  const context = useContext(WasteFacilityStateContext);
  if (!context) {
    throw new Error(
      "useWasteFacilityState must be used within a WasteFacilityStateProvider"
    );
  }
  return context;
}

export const WasteFacilityProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { filters, setFilters } = useQueryParams<Filter>();

  const deferredFilters = useDeferredValue(filters);

  const [isPending, startTransition] = useTransition();

  const wasteFacilityState = useMemo(
    () => ({ deferredFilters, immediateFilters: filters, isPending }),
    [deferredFilters, filters, isPending]
  );

  const setWasteFacilityState = useCallback(
    (cb: (state: Partial<Filter>) => void) => {
      startTransition(() => {
        setFilters((f) => {
          cb(f);
        });
      });
    },
    [setFilters]
  );

  const value = useMemo(
    () => ({
      wasteFacilityState,
      setWasteFacilityState,
    }),
    [wasteFacilityState, setWasteFacilityState]
  );

  return (
    <WasteFacilityStateContext.Provider value={value}>
      {children}
    </WasteFacilityStateContext.Provider>
  );
};
