import { Theme } from "@mui/material";
import { JobStatus } from "generated-graphql/graphql";

export function getJobStatusColor({
  jobStatus,
  theme,
}: {
  jobStatus: JobStatus | undefined | null;
  theme: Theme;
}) {
  switch (jobStatus) {
    case JobStatus.Succeeded:
      return theme.palette.success;
    case JobStatus.Running:
    case JobStatus.Queued:
      return theme.palette.info;
    case JobStatus.Failed:
      return theme.palette.error;
    default:
      return theme.palette.info;
  }
}
