import { useMutation, useQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { FacilityStateFieldsQuery, Issue } from "generated-graphql/graphql";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { filterIssues, regulatoryFields } from "../Chemicals/Report/useReport";
gql(`
  fragment stateFieldFragment on DynamicField {
      key
      value
      type
      jurisdiction
      inputType
      label
      layout
      renderMetadata
      helperText
      tooltip
      selectOptions {
        label
        value
      }
  }
`);

export const FACILITY_STATE_FIELDS = gql(`
  query FacilityStateFields($input: FacilityStateFieldsInput!) {
    facilityStateFields(input: $input) {
      ...stateFieldFragment
    }
  }
`);

gql(`
  fragment facilityProfile on Facility {
    id
    name
    businessUnit
    customerFacilityId
    companyName
    department
    phone
    phone24Hour
    maxNumOccupants
    isUnmanned
    facilityType
    collectionMode
    dbNumber
    naicsCode
    status
    statusReason
    dateOpened
    dateClosed
    dateInactive
    isInactive
    isSubjectToChemicalAccidentPrevention
    isSubjectToEmergencyPlanning
    isSubjectToToxicsReleaseInventoryReporting
    colocationReportingFacility {
      ...FacilityPicker
    }
    county
    municipality
    facilityAlternateIds {
      id
      type
      value
      hasNoId
      expiresAt
    }
    fireDepartment {
      ...FireDepartmentPicker
    }
    lepc {
      ...LepcPicker
    }
    streetAddress1
    streetAddress2
    city
    zip
    state
    country

    mailingStreetAddress1
    mailingStreetAddress2
    mailingCity
    mailingState
    mailingZip
    mailingCountry

    isMailingAddressEncamp
    isMailingAddressSameAsFacility
    latitude
    longitude
    stateFields {
      ...stateFieldFragment
    }
    issues {
      message
      key
      modelId
      modelName
      jurisdictions
      level
    }
    tags {
      id
      name
    }
  }
`);

export const GET_FACILITY = gql(`
  query GetFacility($id: ID!) {
    facility(id: $id) {
      ...facilityProfile
    }
  }`);

const UPDATE_FACILITY_PROFILE = gql(`
  mutation UpdateFacilityProfile($id: ID!, $facility: FacilityInput!) {
    updateFacility(id: $id, facility: $facility) {
      ...facilityProfile
    }
  }`);

export function useUpdateFacilityProfile() {
  return useMutation(UPDATE_FACILITY_PROFILE);
}

/**
 *
 * @param id ID of facility to fetch.
 * If not provided, the ID of the `:facilityId` path parameter will be used instead, if it is present.
 */
export function useFacility(id?: string) {
  const { facilityId: pathParamFacilityId } = useParams<{
    facilityId: string;
  }>();

  const facilityId = id ?? pathParamFacilityId ?? "";

  return useQuery(GET_FACILITY, {
    variables: { id: facilityId },
    skip: !facilityId,
  });
}

export function useFacilityStateFields(
  facilityId?: string,
  jurisdictionOverrides?: string[],
  options?: { onCompleted?: (data: FacilityStateFieldsQuery) => void }
) {
  return useQuery(FACILITY_STATE_FIELDS, {
    skip: !facilityId,
    variables: {
      input: {
        facilityId: facilityId ?? "",
        jurisdictions: jurisdictionOverrides ?? undefined,
      },
    },
    onCompleted: options?.onCompleted,
  });
}

export function useFacilityIssues(
  filterFn?: (issue: Issue) => boolean
): Issue[] {
  const { data } = useFacility();
  return useMemo(
    () => filterIssues(data?.facility?.issues ?? [], filterFn),
    [data, filterFn]
  );
}

export function useRegulatoryInfoIssues() {
  return useFacilityIssues(
    (issue) =>
      issue.modelName === "Facility" &&
      regulatoryFields.some((re) => issue.key?.startsWith(re))
  );
}

export function useFacilityProfileIssues() {
  return useFacilityIssues(
    (issue) =>
      (issue.modelName === "Facility" ||
        issue.modelName === "FacilityStateField" ||
        issue.modelName === "FacilityAlternateId") &&
      issue.key !== "contacts" &&
      issue.key !== "facilityDocuments" &&
      // Not sure what this is filtering for
      !regulatoryFields.some((re) => issue.key?.startsWith(re))
  );
}

export function useFacilityDocumentIssues() {
  return useFacilityIssues(
    (issue) =>
      issue.key === "facilityDocuments" && issue.modelName === "Facility"
  );
}

export function useContactIssues() {
  return useFacilityContactIssues().concat(useFacilityContactModelIssues());
}

export function useFacilityContactIssues() {
  return useFacilityIssues((issue) => issue.key === "contacts");
}

export function useFacilityContactModelIssues() {
  return useFacilityIssues(
    (issue) =>
      issue.modelName === "FacilityContact" || issue.modelName === "Person"
  );
}
