import { useSuspenseQuery } from "@apollo/client";
import InsightsIcon from "@mui/icons-material/Insights";
import { Alert, GridProps, Typography } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { HorizontalBarChart } from "components/Dashboard/HorizontalBarChart";
import { gql } from "generated-graphql";
import {
  TierIiReportKind,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";
import { PriorityChartColors } from "util/insights";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";
import { ReportKindPicker } from "./ReportKindPicker";

const MY_REPORT_STATUS_QUERY = gql(`
  query MyReportStatus($tenantId: ID!, $reportKind: TierIIReportKind!, $filter: ChemicalOverviewFilter) {
    myReportStatus(tenantId: $tenantId, reportKind: $reportKind, filter: $filter) {
      notStarted {
        count
        percent
      }
      inProgress {
        count
        percent
      }
      inReview {
        count
        percent
      }
      submittedToEncamp {
        count
        percent
      }
      notReporting {
        count
        percent
      }
    }
  }
`);

export function MyReportStatusTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <MyReportStatusContent />
    </DashboardTile>
  );
}

function MyReportStatusContent() {
  const { tenantId } = useTenant();
  const {
    drillDown,
    chemicalOverviewState: {
      deferredFilters: {
        assignedToMe,
        facilityTags,
        reportStatusReportKind = TierIiReportKind.Annual,
      },
    },
    setChemicalOverviewState,
  } = useChemicalOverviewState();

  const setReportKind = (reportKind: TierIiReportKind) => {
    setChemicalOverviewState((state) => {
      state.reportStatusReportKind = reportKind;
    });
  };

  const { data } = useSuspenseQuery(MY_REPORT_STATUS_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      reportKind: reportStatusReportKind,
      filter: { assignedToMe, tagNames: facilityTags },
    },
    fetchPolicy: "cache-and-network",
  });

  const myReportStatus = data?.myReportStatus;
  const chartData = useMemo(() => {
    return [
      {
        label: "Not started",
        total: myReportStatus?.notStarted.count ?? 0,
        value: TierIiReportOrgStatus.NotStarted,
        percentage: myReportStatus?.notStarted.percent ?? 0,
        color: PriorityChartColors[4], // cyan[200]
      },
      {
        label: "In progress",
        total: myReportStatus?.inProgress.count ?? 0,
        value: TierIiReportOrgStatus.InProgress,
        percentage: myReportStatus?.inProgress.percent ?? 0,
        color: PriorityChartColors[3], // pink[200]
      },
      {
        label: "In Review",
        total: myReportStatus?.inReview.count ?? 0,
        value: TierIiReportOrgStatus.InReview,
        percentage: myReportStatus?.inReview.percent ?? 0,
        color: PriorityChartColors[1], // purple[300]
      },
      {
        label: "Submitted to Encamp",
        total: myReportStatus?.submittedToEncamp.count ?? 0,
        value: TierIiReportOrgStatus.Verified,
        percentage: myReportStatus?.submittedToEncamp.percent ?? 0,
        color: PriorityChartColors[5], // deepOrange[300]
      },
    ];
  }, [myReportStatus]);

  return (
    <>
      <DashboardTile.Header
        title="My Report Status"
        infoTooltip={<MyReportStatusTooltip />}
      >
        <ReportKindPicker
          reportKind={reportStatusReportKind}
          setReportKind={setReportKind}
        />
      </DashboardTile.Header>
      <DashboardTile.ContentArea>
        <HorizontalBarChart
          barLabelGrid={2}
          onClick={(d) => {
            drillDown((f) => {
              // for NOT_STARTED, we need to include |null to get reports that
              // have not been initialized
              if (d === TierIiReportOrgStatus.NotStarted) {
                return {
                  ...f,
                  organizationStatus: `${d}|null`,
                };
              }

              return {
                ...f,
                organizationStatus: d,
              };
            });
          }}
          title=""
          data={chartData}
        />
      </DashboardTile.ContentArea>
      <DashboardTile.Footer>
        <Alert
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            drillDown((f) => ({
              ...f,
              organizationStatus: TierIiReportOrgStatus.NotReporting,
            }));
          }}
          severity="info"
          icon={<InsightsIcon />}
        >
          <Typography>
            <Typography component="span" fontWeight="800">
              {myReportStatus?.notReporting.count ?? 0}
            </Typography>{" "}
            ({myReportStatus?.notReporting.percent ?? 0}%) of your facilities
            are marked <i>Not Reporting</i>
          </Typography>
        </Alert>
      </DashboardTile.Footer>
    </>
  );
}

function MyReportStatusTooltip() {
  return (
    <Typography variant="caption">
      <Typography component="span" variant="caption" fontWeight={800}>
        My Report Status
      </Typography>{" "}
      displays reports grouped by status for the current reporting year.
    </Typography>
  );
}
