import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { FacilityContactInput } from "generated-graphql/graphql";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { EditContactFormData } from "../../../../../components/Contacts/EditFacilityContactDialog";

const VALIDATE_FACILITY_CONTACT_INPUT = gql(`
  query ValidateFacilityContactInput($data: FacilityContactInput!) {
    facilityContactInputValidator(data: $data) {
      ...issue
    }
  }
`);

export function useFacilityContactInputValidation(facilityId: string) {
  const [query] = useLazyQuery(VALIDATE_FACILITY_CONTACT_INPUT, {
    fetchPolicy: "no-cache",
  });

  const { tenantId = "" } = useParams();
  return useCallback(
    async (input: EditContactFormData) => {
      const variables: FacilityContactInput = {
        facilityId,
        person: {
          id: input.person?.id,
          tenantId,
          first: input.person?.first,
          last: input.person?.last,
          email: input.person?.email,
          title: input.person?.title,
          company: input.person?.company,
          phones: input.person?.phones?.map((p) => ({
            number: p.number,
            type: p.type,
          })),
          streetAddress1: input.person?.streetAddress1,
          streetAddress2: input.person?.streetAddress2,
          city: input.person?.city,
          state: input.person?.state,
          zip: input.person?.zip,
          country: input.person?.country,
          tankOwnerType: input.person?.tankOwnerType,
        },
        reportingRoles: input.reportingRoles,
      };
      const { data } = await query({ variables: { data: variables } });
      return data?.facilityContactInputValidator ?? [];
    },
    [facilityId, query, tenantId]
  );
}
