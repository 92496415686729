import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

/**
 * Hook to manage a selected ID in the URL.
 *
 * Be sure to update `main.tsx` to include your optional param in your route.
 * Like the following:
 *
 * ```
 * {
 *   path: "jobs/:jobId?",
 *   element: <Jobs />,
 * }
 * ```
 */
export const useSelectedIdInUrl = (
  paramName: string
): {
  selectedId: string | undefined;
  pushIdToUrl: (id: string) => void;
  popIdFromUrl: () => void;
} => {
  const params = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedId = useMemo(() => params[paramName], [params, paramName]);

  const pushIdToUrl = useCallback(
    (id: string) => {
      const pathSegments = location.pathname.split("/").filter(Boolean);
      const newPath = `/${[...pathSegments, id].join("/")}${location.search}`;
      navigate(newPath);
    },
    [navigate, location.pathname, location.search]
  );

  const popIdFromUrl = useCallback(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    pathSegments.pop(); // Remove the last segment
    const newPath = `/${pathSegments.join("/")}${location.search}`;
    navigate(newPath);
  }, [navigate, location.pathname, location.search]);

  return {
    selectedId,
    pushIdToUrl,
    popIdFromUrl,
  };
};
