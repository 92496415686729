import { useApolloClient, useLazyQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import { Box, Tooltip } from "@mui/material";
import { ExportDataButton } from "components/ExportDataButton";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { TruncateTypography } from "components/TruncateTypography";
import { DeepPartial } from "encamp-shared/src/utils/types";
import { wasteManifestSourceToLabel } from "encamp-shared/src/waste";
import { gql } from "generated-graphql";
import {
  WasteLine,
  WasteLinesQuery,
  WasteManifestSource,
  WasteManifestsQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCurrentUser } from "hooks/useCurrentUser";
import {
  OmnisearchGridColDef,
  useOmnisearchDatagrid,
} from "hooks/useOmnisearchDatagridSettings";
import invariant from "invariant";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { prettyPrintDateMed } from "util/dates";
import { ExpandableCell } from "./ExpandableCell";
import { StaffManifestActionsMenu } from "./StaffManifestActionsMenu";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import Warning from "@mui/icons-material/Warning";

// Using this fragment to ensure we get cache replacement for the waste lines
gql(/* GraphQL */ `
  fragment WasteLineFragment on WasteLine {
    quantity
    manifestId
    qtyUnitOfMeasureDesc
    dotHazardous
    dotPrintedInformation
    nonHazWasteDescription
    vendorProfileDescription
    vendorProfileNumber
    managementMethodCode
    dotIdNumberDescription
    federalWasteCodes
    quantity
    qtyUnitOfMeasureCode
    qtyUnitOfMeasureDesc
    quantityTons
    quantityAcuteTons
    quantityNonAcuteTons
    quantityKg
    quantityAcuteKg
    quantityNonAcuteKg
  }
`);

const MANIFESTS_QUERY = gql(/* GraphQL */ `
  query WasteManifests(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    wasteManifests(
      search: $search
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      items {
        id
        displayedManifestId
        epaId
        manifestTrackingNumber
        vendor
        shippedDate
        facilityName
        hasEManifest
        hasVendorManifest
        hasIssues
        hasIssuesReason {
          field
        }
        wasteLines {
          count
          items {
            ...WasteLineFragment
          }
        }
      }
      count
    }
  }
`);

const WASTE_LINES_QUERY = gql(/* GraphQL */ `
  query WasteLines($wasteManifestId: String!) {
    wasteManifestLineItems(wasteManifestId: $wasteManifestId) {
      count
      items {
        ...WasteLineFragment
      }
    }
  }
`);

export type Row = WasteManifestsQuery["wasteManifests"]["items"][0];

const replaceWasteLinesInCache = (
  cache: ReturnType<typeof useApolloClient>["cache"],
  wasteLinesResult: WasteLinesQuery["wasteManifestLineItems"],
  processedManifestId: string | null | undefined
) => {
  const cacheId = cache.identify({
    __typename: "ProcessedManifestSummary",
    id: processedManifestId,
  });
  cache.modify({
    broadcast: true,
    optimistic: true,
    id: cacheId,
    fields: {
      wasteLines() {
        return wasteLinesResult;
      },
    },
  });
};

export function Manifests() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { isStaff } = useCurrentUser();
  const { omnisearch } = useOmnisearchDatagrid();
  const minWidth = 120;

  invariant(tenantId, "tenantId not found");

  const reportingYear = useSelectedReportingYear();

  const filename = useMemo(
    () => `waste-manifests-${reportingYear}`,
    [reportingYear, tenantId]
  );

  const defaultSearch = useMemo(
    () => `tenantId:${tenantId} reportingYear:${reportingYear}`,
    [tenantId, reportingYear]
  );

  const client = useApolloClient();
  const [fetchWasteLines, { loading: loadingWasteLines }] =
    useLazyQuery(WASTE_LINES_QUERY);

  const handleFetchWasteLines = useCallback(
    (
      wasteManifestId: string | null | undefined,
      processedManifestId: string | null | undefined
    ) => {
      if (!wasteManifestId) {
        return;
      }

      fetchWasteLines({
        variables: {
          wasteManifestId,
        },
        onCompleted: (data) => {
          if (data?.wasteManifestLineItems) {
            replaceWasteLinesInCache(
              client.cache,
              data.wasteManifestLineItems,
              processedManifestId
            );
          }
        },
      });
    },
    [client.cache, fetchWasteLines]
  );
  const btns = [
    <TierIIYearPicker
      key="yearPicker"
      selectProps={{
        size: "small",
      }}
    />,
    <ExportDataButton
      key="export"
      fileName={filename}
      tenantId={tenantId}
      reportingYear={reportingYear}
      exportType="wasteManifests"
      dialog={true}
    />,
  ];

  useBreadcrumb([
    {
      label: "Waste",
      to: tenantId ? `/o/${tenantId}/waste` : undefined,
    },
    {
      label: "Manifests",
    },
  ]);

  if (isStaff) {
    btns.push(<StaffManifestActionsMenu key="staff" />);
  }
  const columns: OmnisearchGridColDef<Row>[] = [
    {
      field: "epaId",
      headerName: "EPA ID",
      minWidth,
      flex: 1.2,
      topAligned: true,
    },
    {
      field: "facility",
      headerName: "Facility",
      filterKeyType: "facility",
      minWidth,
      flex: 1.3,
      topAligned: true,
      valueGetter: ({ row }) => row?.facilityName ?? "",
    },
    {
      field: "manifestTrackingNumber",
      headerName: "Manifest #",
      minWidth,
      flex: 1.2,
      topAligned: true,
      valueGetter: ({ row }) => row.manifestTrackingNumber,
    },
    {
      field: "shippedDate",
      headerName: "Ship Date",
      width: 120,
      minWidth,
      topAligned: true,
      valueGetter: ({ row }) => {
        return prettyPrintDateMed(row?.shippedDate);
      },
    },
    {
      field: "wasteLines",
      headerName: "Line Item",
      flex: 1.5,
      minWidth,
      sortable: false,
      topAligned: true,
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography key={idx} showTooltip variant="body">
                        {determineLineDisplay(line)}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.7,
      minWidth: 80,
      sortable: false,
      topAligned: true,
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              viewMoreDisabled
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography key={idx} showTooltip variant="body">
                        {determineAmount(line)}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "wasteProfileNumber",
      headerName: "Waste Profile #",
      flex: 1.5,
      minWidth: 150,
      sortable: false,
      topAligned: true,
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              viewMoreDisabled
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography
                        key={`wasteProfileNumber${idx}`}
                        showTooltip
                        variant="body"
                      >
                        {determineWasteProfileNumber(line)}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "managementMethodCode",
      headerName: "Management Method",
      flex: 1,
      minWidth,
      sortable: false,
      topAligned: true,
      headerClassName: "wrapHeader",
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              viewMoreDisabled
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography
                        key={`managementMethodCode${idx}`}
                        showTooltip
                        variant="body"
                      >
                        {line.managementMethodCode}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 0.7,
      topAligned: true,
      filterKeyType: "enum",
      enumValues: Object.values(WasteManifestSource).filter(
        (s) => s !== WasteManifestSource.EManifest
      ),
      enumPresentationFunction: (enumValue) =>
        wasteManifestSourceToLabel(enumValue as WasteManifestSource),
      valueGetter: ({ row }) => {
        return wasteManifestSourceToLabel(row.vendor);
      },
    },

    {
      field: "eManifest",
      headerName: "e-Manifest",
      flex: 0.7,
      sortable: true,
      align: "center",
      renderCell: ({ row }) => {
        if (!row?.hasEManifest) {
          return <></>;
        }

        return <Check />;
      },
    },
    {
      field: "vendorManifest",
      headerName: "Vendor Manifest",
      flex: 0.8,
      sortable: true,
      align: "center",
      headerClassName: "wrapHeader",
      renderCell: ({ row }) => {
        if (!row?.hasVendorManifest) {
          return <></>;
        }

        return <Check />;
      },
    },
    {
      field: "hasIssues",
      headerName: "Issues",
      align: "center",
      headerAlign: "center",
      filterKeyType: "boolean",
      renderCell: ({ row }) => {
        if (row.hasIssues) {
          return (
            <Tooltip
              title={
                <Box sx={{ whiteSpace: "pre-wrap" }}>
                  {getIssuesSummary(row.hasIssuesReason)}
                </Box>
              }
            >
              <Warning color="error" />
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={MANIFESTS_QUERY}
        getItems={(data) => data.wasteManifests.items}
        getCount={(data) => data.wasteManifests.count}
        noDataMessage={`No manifests have been added to this organization.`}
        isRowSelectable={() => false}
        defaultSearch={defaultSearch}
        initialSortModel={[{ field: "shippedDate", sort: "desc" }]}
        rowHeight={"auto"}
        commandButtons={btns}
        excludeFilterColumns={[
          "wasteLines",
          "wasteProfileNumber",
          "amount",
          "eManifest",
          "vendorManifest",
          "issues",
        ]}
      />
    </Box>
  );
}

function determineLineDisplay(line: DeepPartial<WasteLine>) {
  if (line?.vendorProfileDescription) {
    return line.vendorProfileDescription;
  }

  return line?.dotHazardous
    ? line.dotPrintedInformation
    : line?.nonHazWasteDescription;
}

function determineAmount(line: DeepPartial<WasteLine>) {
  if (line?.quantity && line.qtyUnitOfMeasureCode) {
    return `${line.quantity} ${getQtyUnits(line.qtyUnitOfMeasureCode)}`;
  }

  if (line?.quantityTons) {
    return `${line.quantityTons} tons`;
  }

  if (line?.quantityKg) {
    return `${line.quantityKg} kg`;
  }
}

function determineWasteProfileNumber(line: DeepPartial<WasteLine>) {
  if (line?.vendorProfileNumber) {
    return line.vendorProfileNumber ?? "";
  }

  return `${line?.dotIdNumberDescription ?? ""} ${
    line?.federalWasteCodes?.join(" ") ?? ""
  }`;
}

function getQtyUnits(code: string) {
  switch (code) {
    case "G":
      return "gals";
    case "K":
      return "kg";
    case "L":
      return "L";
    case "M":
      return "MT";
    case "N":
      return "cm";
    case "P":
      return "lbs";
    case "T":
      return "T";
    case "Y":
      return "cy";
    default:
      return "";
  }
}

function getIssuesSummary(reasons: { field: string }[] | null | undefined) {
  if (!reasons?.length) return "";

  const fieldMap: Record<string, string> = {
    generatorName: "Generator Name",
    generatorLocationStreetAddress: "Street Address",
    generatorLocationStreet2: "Address Line 2",
    generatorLocationCity: "City",
    generatorLocationState: "State",
    generatorLocationZip: "ZIP Code",
    shippedDate: "Shipped Date",
    desFacilityId: "Destination Facility ID",
  };

  // Group waste line issues separately
  const wasteLineIssues = reasons.filter((r) =>
    r.field.startsWith("wasteLine")
  );
  const otherIssues = reasons.filter((r) => !r.field.startsWith("wasteLine"));

  const issues: string[] = [];

  // Handle non-waste line issues
  otherIssues.forEach((reason) => {
    const friendlyName = fieldMap[reason.field] || reason.field;
    issues.push(friendlyName);
  });

  // Add waste line issues as a single item if they exist
  if (wasteLineIssues.length > 0) {
    issues.push("Waste Line Items");
  }

  return `Discrepancies:\n${issues.join("\n")}`;
}
