import Warning from "@mui/icons-material/Warning";
import { Box, Tooltip } from "@mui/material";
import { ExportDataButton } from "components/ExportDataButton";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { FacilityAlternateIdKnownKind } from "encamp-shared/src/facility/alternateId";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { formatAddress } from "encamp-shared/src/utils/address";
import {
  getRegulatoryStatusIssueSummary,
  wasteGeneratorStatusToLabel,
} from "encamp-shared/src/waste/index";
import { gql } from "generated-graphql";
import {
  RcraInfoFederalWasteGeneratorStatus,
  WasteFacilitiesQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useNavigate } from "react-router-dom";

const WASTE_FACILITIES = gql(`
  query WasteFacilities(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        streetAddress1
        city
        state
        zip
        facilityAlternateIds {
          id
          type
          value
          hasNoId
        }
        regulatoryStatus {
          rcraInfo
          rcraInfoHighestStatusThisYear
          eManifestHighestStatusThisYear
          vendorHighestStatusThisYear
        }
      }
      count
    }
  }
`);

export const WasteFacilities = () => {
  const navigate = useNavigate();
  const { tenantId } = useTenant();

  useBreadcrumb([
    {
      label: "Waste",
      to: tenantId ? `/o/${tenantId}/waste` : undefined,
    },
    {
      label: "Facilities",
    },
  ]);

  const columns: OmnisearchGridColDef<
    WasteFacilitiesQuery["facilities"]["items"][number]
  >[] = [
    {
      field: "epaId",
      headerName: "EPA ID",
      minWidth: 150,
      valueGetter({ row }) {
        return findAlternateId(row.facilityAlternateIds ?? [], [
          FacilityAlternateIdKnownKind.EPA,
        ])?.value;
      },
      sortable: false,
    },
    {
      field: "name",
      headerName: "Facility",
      filterKeyType: "facility",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      valueGetter({ row }) {
        return formatAddress(row.streetAddress1, row.city, row.state, row.zip);
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "currentWasteGeneratorStatus",
      headerName: "Current Reported Generator Status",
      minWidth: 185,
      flex: 0.4,
      sortable: true,
      headerClassName: "wrapHeader",
      filterKeyType: "enum",
      enumValues: Object.values(RcraInfoFederalWasteGeneratorStatus),
      valueGetter({ row }) {
        if (
          !row.regulatoryStatus?.rcraInfo ||
          row.regulatoryStatus.rcraInfo ===
            RcraInfoFederalWasteGeneratorStatus.Undetermined
        ) {
          return "";
        }

        return wasteGeneratorStatusToLabel(row.regulatoryStatus.rcraInfo);
      },
    },
    {
      field: "issues",
      headerName: "Issues",
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        if (!row.regulatoryStatus) {
          return null;
        }

        const discrepancy = getRegulatoryStatusIssueSummary(
          row.regulatoryStatus
        );

        if (discrepancy) {
          return (
            <Tooltip
              title={<Box sx={{ whiteSpace: "pre-wrap" }}>{discrepancy}</Box>}
            >
              <Warning color="error" />
            </Tooltip>
          );
        }

        return null;
      },
    },
  ];

  const btns = [
    <ExportDataButton
      key="export"
      tenantId={tenantId ?? ""}
      exportType="wasteFacilities"
      fileName="waste-facilities"
    />,
  ];

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        defaultSearch={`hasManifests:true tenantId:${tenantId}`}
        columns={columns}
        commandButtons={btns}
        dataQuery={WASTE_FACILITIES}
        getItems={(data) => data.facilities.items}
        getCount={(data) => data.facilities.count}
        showFavorites
        isRowSelectable={() => false}
        noDataMessage={
          "No waste facilities have been added to this organization"
        }
        excludeFilterColumns={["issues", "address"]}
        additionalFilterColumns={[
          { header: "Street Address", key: "streetAddress1" },
          { header: "City", key: "city" },
          { header: "State", key: "state" },
          { header: "Zip", key: "zip" },
        ]}
        onRowClick={(row) => navigate(`${row.id}`)}
        initialSortModel={[{ field: "name", sort: "asc" }]}
      />
    </Box>
  );
};
