import { Box, Grid, Typography, useTheme } from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { JobStepsDone } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { prettyPrintDateTimeWithSeconds } from "util/dates";
import { STAFF_JOB_STEPS_DONE_TABLE_QUERY } from "./api";

export function JobStepsDoneTable({ jobId }: { jobId: string }) {
  const theme = useTheme();
  const cols: OmnisearchGridColDef<JobStepsDone>[] = [
    {
      field: "name",
      headerName: "name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      renderCell: ({ row }) => (
        <span>{prettyPrintDateTimeWithSeconds(row.createdAt)}</span>
      ),
    },
    {
      field: "updatedAt",
      headerName: "updated At",
      width: 150,
      renderCell: ({ row }) => (
        <span>{prettyPrintDateTimeWithSeconds(row.createdAt)}</span>
      ),
    },
  ];
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" fontWeight="medium">
        Steps Done:
      </Typography>
      <Box marginTop={theme.spacing(1)}>
        <OmnisearchDataGrid
          isURLDriven={false}
          initialSortModel={[{ field: "createdAt", sort: "desc" }]}
          columns={cols}
          skip={!jobId}
          dataQuery={STAFF_JOB_STEPS_DONE_TABLE_QUERY}
          defaultSearch={`jobId:${jobId}`}
          getItems={(data) => data.jobStepsDone.items}
          getCount={(data) => data.jobStepsDone.count}
          withPadding={false}
          initialPageSize={5}
        />
      </Box>
    </Grid>
  );
}
