import Check from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import Edit from "@mui/icons-material/Edit";
import { Chip, Tooltip, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { gql } from "generated-graphql";
import { ReportingFacilityChemicalsQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useMemo, useState } from "react";
import {
  prettyPrintAmountCode,
  prettyPrintQuantity,
} from "encamp-shared/src/utils/prettyPrintUnits";
import { ReportingFacilityChemicalEditor } from "./ReportingFacilityChemicalEditor";
import { formatChemicalName } from "util/chemicalName";

type Row =
  ReportingFacilityChemicalsQuery["reportingFacilityChemicals"]["items"][number];

export const REPORTING_FACILITY_CHEMICALS_QUERY = gql(`
  query ReportingFacilityChemicals($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    reportingFacilityChemicals(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        chemical {
          ...ChemicalPicker
          alternateId
          isEhs
          noHazardsNotReporting
        }
        facility {
          state
        }
        maxAmount
        maxAmountCode
        averageAmount
        averageAmountCode
        unit
        isOverThreshold
        isAlwaysReported
        issueCount
        aggregationErrorMessage
      }
      count
    }
  }
`);

export function ReportingFacilityChemicalTable({
  facilityId,
  reportId,
}: {
  facilityId: string;
  reportId?: string;
}) {
  const reportingYear = useSelectedReportingYear();
  const [selectedRfcId, setSelectedRfcId] = useState<undefined | string>(
    undefined
  );

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Chemical",
        flex: 2.0,
        renderCell: ({ row }) => (
          <Tooltip title={row.chemical ? formatChemicalName(row.chemical) : ""}>
            <Typography
              variant="body2"
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {row.chemical?.name}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "casNumber",
        headerName: "CAS #",
        flex: 1,
        valueGetter(params) {
          return params.row.chemical?.casNumber;
        },
      },
      {
        field: "alternateId",
        headerName: "Alt ID",
        flex: 1,
        valueGetter(params) {
          return params.row.chemical?.alternateId;
        },
      },
      {
        field: "isEhs",
        headerName: "Is EHS",
        align: "center",
        headerAlign: "center",
        filterKeyType: "boolean",
        flex: 0.7,
        renderCell({ row: { chemical } }) {
          if (chemical?.isEhs) return <Check />;
          return <></>;
        },
      },
      {
        field: "maxAmount",
        headerName: "Max Amt",
        filterKeyType: "number",
        flex: 1,
        renderCell: (params) => {
          if (params.row.aggregationErrorMessage) {
            return "--";
          }
          if (params.row.maxAmount) {
            return prettyPrintQuantity(
              params.row.maxAmount,
              params.row.unit,
              true
            );
          } else if (params.row.maxAmountCode) {
            return prettyPrintAmountCode(
              params.row.facility?.state,
              params.row.maxAmountCode,
              params.row.unit,
              true
            );
          }
          return "";
        },
      },
      {
        field: "averageAmount",
        headerName: "Avg Amt",
        filterKeyType: "number",
        flex: 1,
        renderCell: (params) => {
          if (params.row.aggregationErrorMessage) {
            return "--";
          }
          if (params.row.averageAmount) {
            return prettyPrintQuantity(
              params.row.averageAmount,
              params.row.unit,
              true
            );
          } else if (params.row.averageAmountCode) {
            return prettyPrintAmountCode(
              params.row.facility?.state,
              params.row.averageAmountCode,
              params.row.unit,
              true
            );
          }
          return "";
        },
      },
      {
        field: "isOverThreshold",
        headerName: "Over Threshold",
        align: "center",
        headerAlign: "center",
        filterKeyType: "boolean",
        flex: 1,
        renderCell({ row: { isOverThreshold } }) {
          if (isOverThreshold) return <Check />;
          return <></>;
        },
      },
      {
        field: "willReport",
        headerName: "Will Report",
        headerAlign: "center",
        align: "center",
        flex: 0.7,
        renderCell({ row }) {
          let willReport = false;
          if (row.chemical?.noHazardsNotReporting) {
            willReport = false;
          } else if (row.isAlwaysReported || row.isOverThreshold) {
            willReport = true;
          }

          return willReport ? <Check /> : <></>;
        },
        sortable: false,
      },
      {
        field: "issueCount",
        headerName: "Issues",
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => {
          if (row.aggregationErrorMessage) {
            return (
              <Tooltip title={row.aggregationErrorMessage}>
                <WarningIcon color="error" />
              </Tooltip>
            );
          }
          return <IssueCount issueCount={row.issueCount} />;
        },
        sortable: false,
        flex: 0.8,
      },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row: { id } }) => [
          <Tooltip title="Open preview" key={1}>
            <GridActionsCellItem
              onClick={() => setSelectedRfcId(id)}
              label="Open Preview"
              icon={<Edit />}
            />
          </Tooltip>,
        ],
      },
    ],
    []
  );

  return (
    <>
      <OmnisearchDataGrid
        columns={columns}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        dataQuery={REPORTING_FACILITY_CHEMICALS_QUERY}
        getItems={(data) => data.reportingFacilityChemicals.items}
        getCount={(data) => data.reportingFacilityChemicals.count}
        onRowClick={({ row: { id } }) => setSelectedRfcId(id)}
        defaultSearch={`facilityId:${facilityId} reportingYear:${reportingYear}`}
        noDataMessage="No chemicals found."
        excludeFilterColumns={["issueCount", "willReport"]}
        withPadding={false}
        commandButtons={[
          !reportId ? (
            <TierIIYearPicker
              key="yearPicker"
              selectProps={{
                size: "small",
              }}
            />
          ) : undefined,
        ]}
      />
      {selectedRfcId && (
        <ReportingFacilityChemicalEditor
          id={selectedRfcId}
          onClose={() => setSelectedRfcId(undefined)}
        />
      )}
    </>
  );
}
