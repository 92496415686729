import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { GetJobQuery, JobStatus } from "generated-graphql/graphql";
import { useCallback, useEffect, useState } from "react";

const GET_JOB = gql(`query GetJob($jobId: ID!) {
    job(id: $jobId) {
      status
    }
  }`);

const terminalJobStatuses: JobStatus[] = [
  JobStatus.Cancelled,
  JobStatus.Failed,
  JobStatus.AwaitingManualIntervention,
  JobStatus.FailedMayAutoRetry,
  JobStatus.FailedNeedsHuman,
  JobStatus.FailedProvisioningEcsTask,
  JobStatus.FailedQualityControlCheck,
  JobStatus.Promoted,
  JobStatus.Succeeded,
];

export const useLongPollingForJob = ({
  timeoutInSeconds = 60,
}: {
  timeoutInSeconds?: number;
}) => {
  const [polling, setPolling] = useState(false);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [job, setJob] = useState<undefined | GetJobQuery["job"]>(undefined);
  const [timeoutId, setTimeoutId] = useState<undefined | NodeJS.Timeout>(
    undefined
  );

  const startPolling = useCallback(
    (jobId: string) => {
      setJobId(jobId);
      setPolling(true);
    },
    [setJobId, jobId, setPolling]
  );

  const [getJob, { data, startPolling: startPollingQuery, stopPolling }] =
    useLazyQuery(GET_JOB, {
      fetchPolicy: "network-only",
      variables: { jobId: jobId ?? "" },
    });

  useEffect(() => {
    if (polling && jobId) {
      getJob();
      startPollingQuery(1000);
    } else {
      stopPolling();
    }

    setTimeoutId(
      setTimeout(() => {
        setPolling(false);
        stopPolling();
      }, timeoutInSeconds * 1000)
    );

    return () => {
      stopPolling();
    };
  }, [polling, getJob, startPollingQuery, stopPolling]);

  useEffect(() => {
    setPolling(false);
    setJob(undefined);
  }, [jobId]);

  useEffect(() => {
    if (data?.job && terminalJobStatuses.includes(data.job.status)) {
      setJob(data.job);
      setPolling(false);
    }
  }, [data]);

  return { polling, job, startPolling };
};
