import { Chip, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import { Dialog } from "components/Dialog";
import { JobStatus, StaffJobDialogQuery } from "generated-graphql/graphql";
import { prettyPrintDateTime } from "util/dates";
import { useQuery } from "@apollo/client";
import { getJobStatusColor } from "./utils";
import { JobLogTable } from "./JobLogsTable";
import { JobStepsDoneTable } from "./JobStepsDoneTable";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import OpenInNew from "@mui/icons-material/OpenInNew";

import { STAFF_JOB_DIALOG_QUERY } from "./api";

export function JobDialog({
  jobId,
  onClose,
}: {
  jobId: string;
  onClose: () => void;
}) {
  const theme = useTheme();
  const { data } = useQuery<StaffJobDialogQuery>(STAFF_JOB_DIALOG_QUERY, {
    variables: { id: jobId },
  });

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose();
      }}
      maxWidth="lg"
    >
      <DialogContent sx={{ overflow: "unset" }}>
        <Typography variant="h6">Job Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Job ID:
            </Typography>
            <Typography variant="body2">{data?.job.id}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Status:
            </Typography>
            <Chip
              size="small"
              label={data?.job.status}
              sx={{
                backgroundColor: getJobStatusColor({
                  jobStatus: data?.job.status as JobStatus,
                  theme,
                }).main,
                color: getJobStatusColor({
                  jobStatus: data?.job.status as JobStatus,
                  theme,
                }).contrastText,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Tenant:
            </Typography>
            <Typography variant="body2">
              {data?.job.tenant?.name ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {data?.job.document ? (
              <>
                <Typography variant="subtitle1" fontWeight="medium">
                  Document:
                </Typography>
                <Box display="flex" alignItems="center">
                  <Link
                    to={`/o/${data.job.tenant?.id}/documents/${data.job.document.id}`}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                      {data.job.document.title}
                    </Typography>
                    <OpenInNew fontSize="small" color="primary" />
                  </Link>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Created:
            </Typography>
            <Typography variant="body2"></Typography>
            {prettyPrintDateTime(data?.job.createdAt)}
            <Typography variant="body2">{data?.job.createdBy}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="medium">
              Updated:
            </Typography>
            <Typography variant="body2">
              {prettyPrintDateTime(data?.job.updatedAt)}
            </Typography>
            <Typography variant="body2">{data?.job.updatedBy}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight="medium">
              Parameters:
            </Typography>
            <pre>{JSON.stringify(data?.job.parameters, null, 2)}</pre>
          </Grid>

          <Grid item xs={12}>
            <JobLogTable jobId={data?.job.id ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <JobStepsDoneTable jobId={data?.job.id ?? ""} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
