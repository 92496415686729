import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TabLink, TabLinks } from "components/TabLinks";
import WasteUpsell from "components/UpsellLandingPages/WasteUpsell";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useMemo } from "react";
import {
  Navigate,
  Outlet,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";

export function Waste() {
  const { featureFlags: flags, loading } = useFeatureFlags();

  const hasWaste = flags?.["module-waste"];
  const { isStaff, loading: userLoading } = useCurrentUser();

  if (loading || userLoading) {
    return <WasteSkeleton />;
  } else if (hasWaste || isStaff) {
    return <WasteOutlet />;
  } else {
    return <WasteUpsell />;
  }
}

export function WasteOutlet() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { pathname } = useLocation();

  const tabs: TabLink[] = useMemo(
    () => [
      {
        title: "Waste Facilities",
        to: tenantId ? `/o/${tenantId}/waste/facilities` : "",
      },
      {
        title: "Manifests",
        to: tenantId ? `/o/${tenantId}/waste/manifests` : "",
      },
    ],
    [tenantId]
  );
  const currentTab = tabs.find(({ to }) => matchPath(`${to}/*`, pathname));

  // Redirect to first tab if we don't have a match
  if (!currentTab) {
    return <Navigate to={tabs[0].to} replace={true} />;
  }
  const tabIndex = tabs.indexOf(currentTab);

  return (
    <Box>
      <TabLinks
        value={tabs[tabIndex].to}
        tabs={tabs}
        aria-label="waste tabs nav"
        role="navigation"
      />
      <Outlet />
    </Box>
  );
}

const WasteSkeleton = () => {
  return (
    <Box>
      {/* Skeletons for the tabs */}
      <Stack direction="row" gap={2} mb={2}>
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
      </Stack>

      {/* Skeleton for the search bar */}
      <Stack direction="row">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ mb: 2 }}
        />

        {/* Skeleton for the button */}
        <Skeleton
          variant="rectangular"
          width={120}
          height={40}
          sx={{ mb: 2 }}
        />
      </Stack>

      {/* Skeletons for the table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(new Array(5)).map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
