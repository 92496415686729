import { useMutation } from "@apollo/client";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Download from "@mui/icons-material/Download";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { gql } from "generated-graphql";
import {
  AssignmentType,
  GetReportDetailsQuery,
  Permission,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import pluralize from "pluralize";
import { CurrentUser } from "providers/user";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { reportStepMetadata } from "util/constants";
import {
  AssigneesEditorDialog,
  AssignmentAction,
} from "../../Reports/AssigneesEditor";
import { Report, useReport } from "../useReport";
import { NotesToRegulatorsForm } from "./NotesToRegulatorsForm";
import { ReviewersList } from "./ReviewersList";
import { SubmitReviewButtons } from "./SubmitReviewButtons";
import { useAuthorization } from "hooks/useAuthorization";
import { useFacilityReportEditable } from "hooks/useFacilityReportEditable";
import { GeneratePdfButton } from "components/GeneratePdfButton";

export type Reviewer = NonNullable<
  NonNullable<GetReportDetailsQuery["tierIIReport"]>["reviewers"]
>[0];

export const SET_IN_REVIEW_STATUS = gql(`
  mutation SetInReviewStatus($reportId: ID!, $inReview: Boolean!) {
    setInReviewStatus(id: $reportId, inReview: $inReview) {
      id
      organizationStatus
      reviewers {
        id
        isComplete
      }
    }
  }
`);

export const SEND_REVIEWER_EMAIL = gql(`
  mutation EmailReviewers($reportId: ID!) {
    emailReviewers(reportId: $reportId) {
      success
      ids
    }
  }`);

const COMPLETE_REVIEW = gql(`
  mutation CompleteReview($reportId: ID!) {
    completeTierIIReportReviewStep(reportId: $reportId) {
      id
      reviewCompletedByUserId
    }
  }
`);

type FormState = {
  isReportInReview: boolean;
};

export function ReviewForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const alerts = useAlerts();
  const { reportId = "" } = useParams<{ reportId: string }>();
  const { tenantId = "" } = useParams<{ tenantId: string }>();
  const { data } = useReport();
  const { user } = useCurrentUser();
  const { hasPermissionForFacility } = useAuthorization();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [addReviewerOpen, setAddReviewerOpen] = useState(false);
  const [showReadyForReview, setShowReadyForReview] = useState(false);
  const [showNotReadyForReview, setShowNotReadyForReview] = useState(false);
  const [showNotAllReviewsComplete, setShowNotAllReviewsComplete] =
    useState(false);

  const canVerifyAndSubmit = hasPermissionForFacility(
    data?.tierIIReport?.facility.id ?? "",
    [Permission.VerifyTierIiReport]
  );

  const { canAddAssigneesOrReviewers } = useFacilityReportEditable();

  const [setInReviewStatus, { loading: settingReviewStatus }] = useMutation(
    SET_IN_REVIEW_STATUS,
    {
      update(cache, { data }) {
        const reportData = data?.setInReviewStatus;
        if (!reportData) {
          return;
        }
        cache.modify({
          id: cache.identify({
            __typename: "TierIIReport",
            id: reportData.id,
          }),
          fields: {
            organizationStatus() {
              return reportData.organizationStatus;
            },
            reviewers: (existingRefs = []) => {
              return existingRefs.map((ref: any) => {
                const updatedReviewer = reportData.reviewers.find(
                  (r: any) => r.id === ref.__ref
                );
                if (updatedReviewer) {
                  return {
                    ...ref,
                    isComplete: updatedReviewer.isComplete,
                  };
                }
                return ref;
              });
            },
          },
        });
      },
    }
  );

  const navigateToVerifyAndSubmit = useCallback(() => {
    navigate(
      `/o/${tenantId}/chemicals/reports/${reportId}/${reportStepMetadata.VERIFY_AND_SUBMIT.route}`
    );
  }, [reportId, tenantId, navigate]);

  const [completeReview, { loading: isCompletingReview }] = useMutation(
    COMPLETE_REVIEW,
    {
      update: (cache) => {
        if (user?.id) {
          cache.modify({
            id: cache.identify({
              __typename: "TierIIReport",
              id: reportId,
            }),
            fields: {
              reviewCompletedByUserId() {
                return user?.id;
              },
            },
            optimistic: true,
            broadcast: true,
          });
        }
      },
    }
  );

  const completeReviewAndNavigateToVerify = useCallback(() => {
    if (user) {
      navigateToVerifyAndSubmit();
      completeReview({ variables: { reportId } });
    }
  }, [navigateToVerifyAndSubmit, completeReview, reportId, user?.id]);

  const [sendReviewerEmail] = useMutation(SEND_REVIEWER_EMAIL, {
    variables: { reportId: reportId ?? "" },
    onCompleted: (data) => {
      if (data.emailReviewers.success) {
        if (data.emailReviewers.ids.length > 0) {
          setIsEmailSent(true);
          alerts.success(
            `Email sent to ${data.emailReviewers.ids.length} ${pluralize(
              "reviewer",
              data.emailReviewers.ids.length
            )}`
          );
        } else {
          alerts.error("No valid reviewers found to email");
        }
      } else {
        alerts.error("An error occurred while sending an email to reviewers");
      }
    },
    onError: (error) => {
      alerts.error(
        "An error occurred while sending an email to reviewers",
        error
      );
    },
  });

  const state = useMemo(
    () => calculateReviewState(data?.tierIIReport, user),
    [data?.tierIIReport, user]
  );

  const formValues: FormState = useMemo(
    () => ({
      isReportInReview: state.isReportInReview,
    }),
    [state.isReportInReview]
  );

  useForm<FormState>({
    defaultValues: formValues,
  });

  const readyForReviewClick = useCallback(() => {
    if (!state.isReportInReview) {
      setShowReadyForReview(true);
    } else {
      setShowNotReadyForReview(true);
    }
    return false;
  }, [state.isReportInReview]);

  const handleSetInReviewStatus = useCallback(
    async (inReview: boolean) => {
      try {
        await setInReviewStatus({
          variables: {
            reportId,
            inReview,
          },
        });
        alerts.success(
          `Changed report status to ${inReview ? "In Review" : "In Progress"}`
        );
      } catch (error) {
        alerts.error(`There was a problem updating the review status`);
      }
      setShowReadyForReview(false);
      setShowNotReadyForReview(false);
    },
    [alerts, reportId, setInReviewStatus]
  );

  const handleSendReviewerEmail = () => {
    sendReviewerEmail({ variables: { reportId } });
  };
  const canEditReviewers = data?.tierIIReport
    ? canAddAssigneesOrReviewers({
        facilityId: data.tierIIReport.facility?.id ?? "",
        organizationStatus: data.tierIIReport.organizationStatus,
        reportingYear: data.tierIIReport.reportingYear,
      })
    : false;
  return (
    <>
      <NotesToRegulatorsForm />

      <Stack spacing={theme.spacing(4)}>
        <Stack spacing={theme.spacing(1)}>
          <Typography variant="h5">Reviewers</Typography>
          <Stack direction="row" justifyContent="end" alignItems="end">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs="auto">
                <FormControlLabel
                  control={<Checkbox checked={!!state.isReportInReview} />}
                  label="This report is ready for review"
                  onClick={readyForReviewClick}
                />
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  direction="row"
                  justifyContent="end"
                  spacing={1}
                >
                  {!state.sendEmailHidden && (
                    <Grid item>
                      {!isEmailSent ? (
                        <Button
                          variant="text"
                          onClick={() => {
                            handleSendReviewerEmail();
                          }}
                        >
                          Send reminder email to reviewers
                        </Button>
                      ) : (
                        <Typography
                          sx={{
                            color: isEmailSent
                              ? theme.palette.success.main
                              : theme.palette.grey[400],
                            display: "flex",
                            alignItems: "center",
                            marginLeft: theme.spacing(1.3),
                            marginTop: theme.spacing(1),
                            marginRight: theme.spacing(1),
                          }}
                        >
                          <CheckCircleOutline
                            sx={{ marginRight: theme.spacing(1) }}
                          />
                          Reminder Sent
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {canEditReviewers && (
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => setAddReviewerOpen(true)}
                      >
                        Add Reviewer
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          <ReviewersList
            report={data?.tierIIReport}
            canDelete={canEditReviewers}
          />
        </Stack>
        {state.isReportInReview && state.currentUserReviewer && (
          <SubmitReviewButtons reviewer={state.currentUserReviewer} />
        )}
      </Stack>
      <FloatingSaveBar
        saveText={"Complete Review Step"}
        saveDisabled={
          isCompletingReview ||
          !canVerifyAndSubmit ||
          !state.isReportInReview ||
          !!data?.tierIIReport.reviewCompletedByUserId
        }
        onSaveClick={() => {
          if (state.allReviewsComplete) {
            completeReviewAndNavigateToVerify();
          } else {
            setShowNotAllReviewsComplete(true);
          }
        }}
        saving={settingReviewStatus}
        hideCancel={true}
        leftChildren={
          <Grid item>
            <GeneratePdfButton
              startIcon={<Download />}
              fullWidth
              variant="text"
              size="medium"
              reportId={data?.tierIIReport.id ?? ""}
            />
          </Grid>
        }
      />
      <AssigneesEditorDialog
        open={addReviewerOpen}
        assignmentType={AssignmentType.Reviewer}
        assignmentAction={AssignmentAction.Assign}
        onClose={() => setAddReviewerOpen(false)}
        refetchQueries={[]}
        clearAssigneesOnClose={false}
        tierIIReports={data?.tierIIReport ? [data?.tierIIReport] : []}
        tenantId={tenantId}
      />
      <ConfirmDialog
        open={showReadyForReview}
        onClose={() => setShowReadyForReview(false)}
        title="Ready for Review"
        loading={settingReviewStatus}
        onConfirm={() => handleSetInReviewStatus(true)}
        msg={
          <>
            <Typography>
              The status of this report will be changed to "In Review" and all
              reviewers for this report will receive an email notification to
              begin their review.
            </Typography>
            <Typography>Are you sure you want to continue?</Typography>
          </>
        }
      />
      <ConfirmDialog
        open={showNotReadyForReview}
        onClose={() => setShowNotReadyForReview(false)}
        title="Not Ready for Review"
        loading={settingReviewStatus}
        onConfirm={() => handleSetInReviewStatus(false)}
        msg={
          <>
            <Typography sx={{ marginBottom: theme.spacing(1) }}>
              The status of this report will be changed to "In Progress" and any
              completed reviews will need to be re-reviewed if this report is
              marked "ready for review" again in the future.
            </Typography>
            <Typography>Are you sure you want to continue?</Typography>
          </>
        }
      />
      <ConfirmDialog
        open={showNotAllReviewsComplete}
        onClose={() => setShowNotAllReviewsComplete(false)}
        title="Reviews Incomplete"
        loading={false}
        onConfirm={completeReviewAndNavigateToVerify}
        msg={
          <>
            <Typography sx={{ marginBottom: theme.spacing(1) }}>
              Not all reviewers have completed their review of this report.
            </Typography>
            <Typography>Are you sure you want to continue?</Typography>
          </>
        }
      />
    </>
  );
}

type ReviewState = {
  isReportInReview: boolean;
  reportHasReviewers: boolean;
  allReviewsComplete: boolean;
  currentUserReviewer: Reviewer | undefined;
  currentUserHasCompletedReview: boolean;
  sendEmailHidden: boolean;
};

/**
 * Determines the review state of a given report based on its current status and the user's role.
 *
 * This function evaluates multiple scenarios:
 * - Whether the report is currently under review.
 * - If the current user is one of the reviewers.
 * - The completion status of all reviewers.
 *
 * Based on these conditions, it returns an object detailing:
 * - The appropriate text to display on a save button (`saveText`).
 * - Whether the save action should be enabled (`saveEnabled`).
 * - If the email sending option should be hidden (`sendEmailHidden`).
 * - The type of save action to be performed (`saveType`).
 *
 * @param {Report} report - The report being evaluated.
 * @param {CurrentUser} currentUser - The currently logged-in user.
 * @returns {Object} An object with properties detailing the review state and associated UI elements.
 */
export function calculateReviewState(
  report?: Report,
  currentUser?: CurrentUser
): ReviewState {
  const status = report?.organizationStatus;
  const reviewers = report?.reviewers ?? [];
  const isReportInReview = status === TierIiReportOrgStatus.InReview;
  const reportHasReviewers = reviewers.length > 0;
  const allReviewsComplete = reviewers.every((reviewer) => reviewer.isComplete);

  const state: ReviewState = {
    isReportInReview:
      report?.organizationStatus === TierIiReportOrgStatus.InReview,
    reportHasReviewers,
    allReviewsComplete,
    currentUserReviewer: undefined,
    currentUserHasCompletedReview: false,
    sendEmailHidden: true,
  };

  if (!report || !currentUser) {
    return state;
  }

  const currentUserReviewer = reviewers.find(
    (reviewer) => reviewer.person.id === currentUser?.person?.id
  );
  const currentUserHasCompletedReview = currentUserReviewer?.isComplete;
  state.currentUserReviewer = currentUserReviewer;
  state.currentUserHasCompletedReview = !!currentUserHasCompletedReview;

  if (isReportInReview && reportHasReviewers && !allReviewsComplete) {
    state.sendEmailHidden = false;
  }
  return state;
}
