import { useApolloClient } from "@apollo/client";
import Mode from "@mui/icons-material/Mode";
import { Button, Stack, Link } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  JobStatus,
  LobMailType,
  Payee,
  PaymentMethod,
  PaymentStatus,
  PaymentsQuery,
  PaymentsQueryVariables,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { GET_PAYMENTS, useCancelPayment } from "hooks/usePayment";
import { useTenant } from "hooks/useTenant";
import { useCallback, useMemo, useState } from "react";
import { prettyPrintDateTime } from "util/dates";
import { PaymentDialog } from "./Fulfillment/ReportDetails/Payments/PaymentDialog";
import Clear from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";

type PaymentRow = PaymentsQuery["payments"]["items"][number];

export const Payments = () => {
  const alerts = useAlerts();
  const apolloClient = useApolloClient();
  const { tenantId } = useTenant();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [cancelPayment, setCancelPayment] = useState<PaymentRow | undefined>(
    undefined
  );
  const [editPayment, setEditPayment] = useState<PaymentRow | undefined>(
    undefined
  );
  const closeModal = useCallback(async () => {
    setShowPaymentForm(false);
    setShowCancelConfirmation(false);
    setEditPayment(undefined);
    setCancelPayment(undefined);
  }, []);

  const [cancelPaymentMutation, { loading: cancelling }] = useCancelPayment({
    refetchQueries: [GET_PAYMENTS],
  });

  const openPaymentsForm = (payment?: PaymentRow) => {
    payment && setEditPayment(payment);
    setShowPaymentForm(true);
  };

  const openCancelConfirmation = (payment: PaymentRow) => {
    setCancelPayment(payment);
    setShowCancelConfirmation(true);
  };

  const handleCancelPayment = useCallback(async () => {
    if (!cancelPayment) return;
    await cancelPaymentMutation({
      variables: { id: cancelPayment.id },
      refetchQueries: [GET_PAYMENTS],
      onCompleted() {
        alerts.success("Successfully cancelled the payment");
      },
      onError(err) {
        alerts.error("There was an error cancelling this payment", err);
      },
    });
    closeModal();
  }, [alerts, closeModal, cancelPayment, cancelPaymentMutation]);

  const dialogTenantId = editPayment?.tenantId ?? tenantId;

  const lobType = useMemo(
    () =>
      cancelPayment?.lobMail?.type === LobMailType.Check ? "checks" : "letters",
    [cancelPayment?.lobMail?.type]
  );

  useBreadcrumb({
    label: "Payments",
  });

  const navigate = useNavigate();

  return (
    <Stack py={3}>
      <OmnisearchDataGrid<PaymentsQuery, PaymentsQueryVariables, PaymentRow>
        persistenceKey="staffPayments"
        initialSortModel={[{ field: "paidAt", sort: "desc" }]}
        columns={[
          {
            field: "id",
            headerName: "ID",
            flex: 0.5,
          },
          {
            field: "status",
            headerName: "Payment Status",
            flex: 0.5,
            filterKeyType: "enum",
            enumValues: Object.values(PaymentStatus),
          },
          {
            field: "job.status",
            headerName: "Job Status",
            flex: 0.5,
            filterKeyType: "enum",
            enumValues: Object.values(JobStatus),
            renderCell: (params) => (
              <Link
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/staff/jobs/${params.row.job?.id}`);
                }}
              >
                {params.row.job?.status}
              </Link>
            ),
          },
          {
            field: "lobMail.checkSent",
            headerName: "Check Sent To Lob",
            flex: 0.5,
            valueGetter(params) {
              if (params.row?.paymentMethod === PaymentMethod.Check) {
                return params.row.lobMail?.lobId ? "Yes" : "No";
              }

              return "N/A";
            },
          },
          {
            field: "lobMail.id",
            headerName: "Lob ID",
            flex: 0.5,
            valueGetter(params) {
              if (params.row?.paymentMethod === PaymentMethod.Check) {
                return params.row.lobMail?.lobId;
              }

              return "N/A";
            },
          },
          {
            field: "paymentMethod",
            headerName: "Payment Method",
            flex: 0.5,
            renderCell(params) {
              return prettyPrintEnumValue(params.value);
            },
            filterKeyType: "enum",
            enumValues: Object.values(PaymentMethod),
          },
          {
            field: "organization",
            headerName: "Organization",
            valueGetter(params) {
              return params.row.tenant?.name;
            },
            flex: 1,
          },
          { field: "payee", headerName: "Payee", flex: 1 },
          {
            field: "amount",
            headerName: "Amount",
            flex: 1,
            valueFormatter(params) {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(params.value);
            },
            filterKeyType: "number",
          },
          { field: "recordedByEmail", headerName: "Recorded By", flex: 1 },
          {
            field: "payeeType",
            headerName: "Payee Type",
            flex: 0.5,
            filterKeyType: "enum",
            enumValues: Object.values(Payee),
          },
          {
            field: "paidAt",
            headerName: "Paid At",
            flex: 1,
            renderCell(params) {
              return prettyPrintDateTime(params.value);
            },
            filterKeyType: "time",
          },
          {
            field: "numFacilities",
            headerName: "# Facilities",
            valueGetter(params) {
              return params.row.facilities?.length
                ? params.row.facilities.length
                : null;
            },
            align: "center",
            flex: 0.2,
          },
          {
            field: "numTierIIReports",
            headerName: "# Tier II Reports",
            valueGetter(params) {
              return params.row.tierIIReports?.length
                ? params.row.tierIIReports.length
                : null;
            },
            align: "center",
            flex: 0.2,
          },
          {
            type: "actions",
            field: "actions",
            align: "left",
            getActions({ row }) {
              const actions = [
                <GridActionsCellItem
                  key="edit"
                  label="Edit"
                  icon={<Mode />}
                  onClick={() => openPaymentsForm(row)}
                />,
              ];

              if (row.status !== PaymentStatus.Cancelled) {
                actions.push(
                  <GridActionsCellItem
                    key="cancel"
                    label="Cancel"
                    icon={<Clear />}
                    onClick={() => openCancelConfirmation(row)}
                  />
                );
              }

              return actions;
            },
          },
        ]}
        excludeFilterColumns={["numFacilities", "numTierIIReports"]}
        dataQuery={GET_PAYMENTS}
        getItems={(data) => data.payments.items}
        getCount={(data) => data.payments.count}
        commandButtons={[
          <Button
            key="add"
            variant="contained"
            onClick={() => openPaymentsForm()}
            disabled={!dialogTenantId}
          >
            {dialogTenantId
              ? "Make or record a payment"
              : "Select organization to make a payment"}
          </Button>,
        ]}
        onRowClick={({ row }) => openPaymentsForm(row)}
      />
      {showPaymentForm && dialogTenantId && (
        <PaymentDialog
          open={showPaymentForm}
          onClose={closeModal}
          tenantId={dialogTenantId}
          onCompleted={async () => {
            await apolloClient.refetchQueries({ include: [GET_PAYMENTS] });
          }}
          paymentId={editPayment?.id}
        />
      )}
      {showCancelConfirmation && (
        <ConfirmDialog
          msg={`
            This will cancel the payment.
            This will not cancel any in-flight lob checks.
            If you need to cancel a check,
            please do so in Lob or contact #engineering-support in Slack. 
            ${
              cancelPayment?.lobMail
                ? `Lob URL: https://dashboard.lob.com/${lobType}/${cancelPayment.lobMail.lobId}`
                : ""
            }
            `}
          open={showCancelConfirmation}
          onClose={closeModal}
          onConfirm={handleCancelPayment}
          loading={cancelling}
        />
      )}
    </Stack>
  );
};
