import { YearPicker } from "components/YearPicker";
import { Stack, Typography } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { ExportDataButton } from "components/ExportDataButton";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  FacilityWasteStreamsQuery,
  FacilityWasteStreamsQueryVariables,
  WasteClassification,
} from "generated-graphql/graphql";
import { useParams } from "react-router-dom";
import { formatNumber } from "util/formatNumber";
import { useWasteFacilityState } from "./WasteFacilityContext";
import { DateTime } from "luxon";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";
import { useWasteFacility } from "./useWasteFacility";

const FACILITY_WASTE_STREAMS = gql(`
  query FacilityWasteStreams(
    $facilityId: ID!,
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facility(id: $facilityId) {
      wasteStreams(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          id
          facilityId
          dotDescription
          federalWasteCodes
          stateWasteCodes
          classification
          facilityWasteStreamYearToDates {
            shipments
            managementMethodCodes
            finalDestinations
          }
          wasteStreamHashKey
        }
        count
      }
    }
  }
`);

type WasteStreamTileRow = NonNullable<
  NonNullable<FacilityWasteStreamsQuery["facility"]>["wasteStreams"]
>["items"][number];

export const WasteStreamsTile = () => {
  return (
    <DashboardTile xs={12}>
      <WasteStreamsTileContent />
    </DashboardTile>
  );
};

const WasteStreamsTileContent = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { tenantId } = useTenant();
  const {
    wasteFacilityState: { immediateFilters },
    setWasteFacilityState,
  } = useWasteFacilityState();
  const { data } = useWasteFacility(facilityId);

  let selectedYear: number | undefined = immediateFilters.year
    ? Number(immediateFilters.year)
    : DateTime.now().year;

  if (Number.isNaN(selectedYear)) {
    selectedYear = DateTime.now().year;
  }

  const exportFilename = useMemo(
    () =>
      data.facility?.name ? `${data.facility.name}-waste-streams` : undefined,
    [data.facility?.name]
  );

  return (
    <>
      <DashboardTile.Header
        title="Waste Streams"
        infoTooltip={
          <Stack spacing={2}>
            <Typography variant="caption">
              Waste streams are created based on the unique combination of DOT
              Description and Federal and State Waste Codes.
            </Typography>
            <Typography variant="caption">
              Waste streams are currently created based on eManifest data.
            </Typography>
          </Stack>
        }
      />
      <DashboardTile.ContentArea>
        <OmnisearchDataGrid<
          FacilityWasteStreamsQuery,
          FacilityWasteStreamsQueryVariables,
          WasteStreamTileRow
        >
          columns={[
            {
              field: "dotDescription",
              headerName: "DOT Description",
              flex: 1,
              minWidth: 100,
              filterable: false, // Filters defined in additionalFilterColumns
              headerClassName: "wrapHeader",
            },
            {
              field: "wasteCodes",
              headerName: "Waste Codes",
              flex: 0.5,
              minWidth: 100,
              maxWidth: 200,
              filterable: false,
              valueGetter({ row }) {
                const stateWasteCodes = row.stateWasteCodes || [];
                const federalWasteCodes = row.federalWasteCodes || [];
                return [...stateWasteCodes, ...federalWasteCodes].join(", ");
              },
              headerClassName: "wrapHeader",
            },
            {
              field: "classification",
              headerName: "Classification",
              flex: 0.5,
              maxWidth: 150,
              valueFormatter: ({ value }) =>
                prettyPrintEnumValue(value)?.replace("Rcra", "RCRA"),
              enumPresentationFunction: (value) =>
                prettyPrintEnumValue(value)?.replace("Rcra", "RCRA"),
              filterable: false, // Filters defined in additionalFilterColumns
            },
            {
              field: "shipments",
              headerName: `Shipments (${selectedYear})`,
              flex: 0.5,
              maxWidth: 150,
              valueGetter: ({ row }) => {
                const shipments =
                  row.facilityWasteStreamYearToDates?.[0]?.shipments;
                return shipments ? `${formatNumber(shipments)} lbs` : "";
              },
              headerClassName: "wrapHeader",
              filterable: false, // Filters defined in additionalFilterColumns
            },
            {
              field: "managementMethodCodes",
              headerName: `Management Method`,
              filterable: false,
              flex: 0.5,
              minWidth: 100,
              maxWidth: 200,
              valueGetter({ row }) {
                const managementMethodCodes =
                  row.facilityWasteStreamYearToDates?.[0]
                    ?.managementMethodCodes;
                return managementMethodCodes
                  ? managementMethodCodes.join(", ")
                  : "";
              },
              headerClassName: "wrapHeader",
            },
            {
              field: "finalDestinations",
              headerName: `Final Destinations`,
              flex: 0.5,
              minWidth: 100,
              maxWidth: 200,
              filterable: false,
              valueGetter({ row }) {
                const finalDestinations =
                  row.facilityWasteStreamYearToDates?.[0]?.finalDestinations;
                return finalDestinations ? finalDestinations.join(", ") : "";
              },
              headerClassName: "wrapHeader",
            },
          ]}
          dataQuery={FACILITY_WASTE_STREAMS}
          getItems={(data) => data.facility?.wasteStreams?.items ?? []}
          getCount={(data) => data.facility?.wasteStreams?.count ?? 0}
          dataQueryVariables={{ facilityId: facilityId ?? "" }}
          initialPageSize={10}
          showFavorites={true}
          withPadding={false}
          defaultSearch={selectedYear ? `year:${selectedYear}` : ``}
          noDataMessage={"No Waste Streams found for this facility."}
          initialSortModel={[{ field: "dotDescription", sort: "asc" }]}
          onRowClick={(params: GridRowParams<WasteStreamTileRow>) => {
            window.open(
              `/o/${tenantId}/waste/manifests?omnisearch=facilityId%3A${params.row.facilityId} wasteStreamHashKey%3A${params.row.wasteStreamHashKey}&ry=${selectedYear}`,
              "_blank"
            );
          }}
          isRowSelectable={() => false}
          disableRowSelectionOnClick={true}
          additionalFilterColumns={[
            // Filter columns are all defined here to match the column order
            {
              filterKeyType: "text",
              key: "dotDescription",
              header: "DOT Description",
            },
            {
              filterKeyType: "text",
              key: "stateWasteCode",
              header: "State Waste Code",
            },
            {
              filterKeyType: "text",
              key: "federalWasteCode",
              header: "Federal Waste Code",
            },
            {
              filterKeyType: "enum",
              key: "classification",
              header: "Classification",
              enumValues: Object.values(WasteClassification),
              enumPresentationFunction: (value) =>
                prettyPrintEnumValue(value)?.replace("Rcra", "RCRA"),
            },
            {
              filterKeyType: "number",
              key: "shipments",
              header: `Shipments (${selectedYear})`,
            },
            {
              filterKeyType: "text",
              key: "managementMethodCode",
              header: "Management Method Code",
            },
            {
              filterKeyType: "text",
              key: "finalDestination",
              header: "Final Destination",
            },
          ]}
          commandButtons={[
            <YearPicker
              key="year"
              value={selectedYear}
              minYear={new Date().getFullYear() - 2}
              onChange={(year) =>
                setWasteFacilityState((f) => {
                  f.year = year?.toString();
                })
              }
            />,
            <ExportDataButton
              exportType="wasteStreams"
              disabled={!facilityId}
              facilityId={facilityId}
              reportingYear={selectedYear}
              tenantId={tenantId ?? ""}
              fileName={exportFilename}
            />,
          ]}
        />
      </DashboardTile.ContentArea>
    </>
  );
};
