import { gql } from "generated-graphql";

export const MY_FACILITY_TAGS_QUERY = gql(`
  query MyFacilitiesTags($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tags(search: $search, page: $page, pageSize: $pageSize, sort: $sort ) {
      items {
        id
        name
        type
      }
      count
    }
  }
`);
