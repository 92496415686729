import { useMemo } from "react";
import { useReport } from "./useReport";
import { useFeatureFlags } from "hooks/useFeatureFlags";

export function useHmbp() {
  const { featureFlags } = useFeatureFlags();
  const { data } = useReport();

  return useMemo(() => {
    return featureFlags?.hmbp && data?.tierIIReport.facility.state === "CA";
  }, [data, featureFlags]);
}
