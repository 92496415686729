import { useQuery } from "@apollo/client";
import Launch from "@mui/icons-material/Launch";
import { Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { IssueCount } from "components/IssueCount";
import { formatAddress } from "encamp-shared/src/utils/address";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import { ProblematicReportsQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useTenant } from "hooks/useTenant";
import { useState } from "react";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";

const PROBLEMATIC_REPORTS_QUERY = gql(`
  query ProblematicReports($tenantId: ID!, $filter: ChemicalOverviewFilter, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    problematicReports(tenantId: $tenantId, filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        tierIIReportId
        facilityId
        facilityName
        streetAddress1
        city
        state
        zip
        reportKind
        issueCount
      }
      count
    }
  }
`);

type ProblematicReport =
  ProblematicReportsQuery["problematicReports"]["items"][number];

export const ProblematicReportsDataGrid = () => {
  const { tenantId } = useTenant();
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "issueCount", sort: "desc" },
  ]);
  const {
    chemicalOverviewState: { deferredFilters: filter },
    drillDown,
  } = useChemicalOverviewState();

  const { data, previousData, loading } = useQuery(PROBLEMATIC_REPORTS_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: {
        assignedToMe: filter.assignedToMe,
        tagNames: filter.facilityTags,
      },
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
    },
  });

  return (
    <DataGrid
      columns={[
        { field: "facilityName", headerName: "Facility", flex: 1 },
        {
          field: "facilityAddress",
          headerName: "Address",
          flex: 1,
          valueGetter: ({ row: { streetAddress1, city, state, zip } }) =>
            formatAddress(streetAddress1, city, state, zip),
        },
        {
          field: "reportKind",
          headerName: "Report Type",
          valueFormatter: ({ value }) => prettyPrintEnumValue(value),
          flex: 1,
        },
        {
          field: "issueCount",
          headerName: "Issues",
          headerAlign: "center",
          align: "center",
          renderCell: ({ row: { issueCount } }) => (
            <IssueCount issueCount={issueCount} />
          ),
        },
        {
          field: "actions",
          type: "actions",
          getActions: ({ row }) => [
            <GridActionsCellItem
              key="open"
              onClick={() => {
                window.open(
                  `/o/${tenantId}/chemicals/reports/${row.tierIIReportId}`,
                  "_blank"
                );
              }}
              label={`Open Report`}
              icon={
                <Tooltip title={`Open Report`}>
                  <Launch />
                </Tooltip>
              }
            />,
          ],
        },
      ]}
      rows={
        data?.problematicReports.items ??
        previousData?.problematicReports.items ??
        []
      }
      rowCount={
        data?.problematicReports.count ??
        previousData?.problematicReports.count ??
        0
      }
      getRowId={(row) => row.tierIIReportId ?? ""}
      loading={loading}
      noRowsMessage="No problematic reports found!"
      isRowSelectable={() => false}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      onRowClick={(params: GridRowParams<ProblematicReport>) => {
        drillDown(`../reports/${params.row.tierIIReportId}`);
      }}
    />
  );
};
