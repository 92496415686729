import { Typography } from "@mui/material";

import { Stack } from "@mui/material";
import { useStateInfoIssues } from "../useReport";
import { ReportFloatingBar } from "../ReportFloatingBar";

export function HmbpForm() {
  return (
    <>
      <Stack direction="column" flex={1}>
        <Typography variant="h6">HMBP Stuff goes here!</Typography>
      </Stack>
      <ReportFloatingBar issues={useStateInfoIssues()} />
    </>
  );
}
