import { gql } from "generated-graphql";
import { useHmbp } from "../useHmbp";
import { HmbpForm } from "./HmbpForm";
import { StateInformationForm } from "./StateInformationForm";
import { reportStepMetadata } from "util/constants";
import { StepperPageHeader } from "../StepperPageHeader";

gql(`
  fragment stateInfo on Facility {
    stateFields {
      key
      value
      type
      jurisdiction
      inputType
      label
      tooltip
      selectOptions {
        label
        value
      }
    }
  }
`);

export function StateInformation() {
  const hmbp = useHmbp();

  return (
    <>
      <StepperPageHeader
        header="State Information"
        description={
          hmbp
            ? reportStepMetadata.STATE_INFORMATION.hmbpDescription
            : reportStepMetadata.STATE_INFORMATION.overviewDescription
        }
      />
      {hmbp ? <HmbpForm /> : <StateInformationForm />}
    </>
  );
}
