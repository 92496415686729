import Clear from "@mui/icons-material/Clear";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { contactRoleToLabel } from "encamp-shared/src/facilityContact/roles";
import { ContactReportRole, Facility } from "generated-graphql/graphql";
import { useState } from "react";
import ContactFacilitiesDialog from "./ContactFacilitiesDialog";

type AssociatedFacilitiesProps = {
  facilityContacts: AssociatedFacilityContact[];
  onUpdate: (updates: AssociatedFacilityContact[]) => void;
  onRemove: (indexToRemove: number) => void;
};

export type AssociatedFacilityContact = {
  id: string;
  facility: Pick<
    Facility,
    "id" | "name" | "streetAddress1" | "city" | "state" | "zip"
  >;
  reportingRoles: ContactReportRole[];
};

export default function AssociatedFacilities({
  facilityContacts,
  onUpdate,
  onRemove,
}: AssociatedFacilitiesProps) {
  const theme = useTheme();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const columns: GridColDef<AssociatedFacilityContact>[] = [
    {
      field: "name",
      headerName: "Facility",
      flex: 1,
      valueGetter: ({ row }) => row.facility.name,
      renderCell: ({ row }) => {
        const streetAddress = `${row.facility.streetAddress1}, ${row.facility.city}, ${row.facility.state} ${row.facility.zip}`;

        return (
          <Stack>
            <Typography variant="body2">{row.facility.name}</Typography>
            <Typography variant="caption">{streetAddress}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "role",
      headerName: "Roles",
      flex: 0.5,
      renderCell: ({ row }) => (
        <Stack direction="column" width="100%">
          {row.reportingRoles?.map((role) => (
            <Typography variant="body2" key={role} width="100%" noWrap>
              {contactRoleToLabel(role)}
            </Typography>
          ))}
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.15,
      align: "left",
      getActions: ({ row }) => {
        const index = facilityContacts.findIndex(
          (fc) => fc.facility.id === row.facility.id
        );
        const actions = [
          <GridActionsCellItem
            key="remove"
            onClick={() => {
              onRemove(index);
            }}
            label="Remove"
            icon={<Clear />}
          />,
        ];

        return actions;
      },
    },
  ];

  return (
    <Stack spacing={theme.spacing(2)}>
      <Stack direction="row" justifyContent="space-between" alignItems="end">
        <Typography variant="body1" fontWeight={500}>
          Associated Facilities
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDialogOpen(true)}
        >
          Link Facilities
        </Button>
      </Stack>

      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        initialState={{
          sorting: {
            sortModel: [
              { field: "name", sort: "asc" },
              { field: "role", sort: "asc" },
            ],
          },
        }}
        columns={columns}
        rows={facilityContacts}
        getRowId={(row) => row.facility.id}
        disableRowSelectionOnClick
        pagination={true}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />

      {dialogOpen && (
        <ContactFacilitiesDialog
          open={dialogOpen}
          facilityContacts={facilityContacts}
          onClose={() => {
            setDialogOpen(false);
          }}
          onSave={(updates) => {
            // Is there a better way to ensure we don't have duplicates?
            const contactsMap = new Map(
              facilityContacts.map((c) => [c.facility.id, c])
            );
            for (const update of updates) {
              contactsMap.set(update.facility.id, update);
            }
            onUpdate(Array.from(contactsMap.values()));
          }}
        />
      )}
    </Stack>
  );
}
