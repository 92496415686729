import { useSuspenseQuery } from "@apollo/client";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, deepOrange, deepPurple, lime, pink } from "@mui/material/colors";
import { BigTooltip } from "components/BigTooltip";
import ChartLegend from "components/Dashboard/ChartLegend";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { StatDisplay } from "components/StatDisplay";
import { YearPicker } from "components/YearPicker";
import { gql } from "generated-graphql";
import { UnitType } from "generated-graphql/graphql";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { prettyPrintLargeQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { useWasteFacilityState } from "../WasteFacilityContext";
import {
  ACUTELY_HAZARDOUS_THRESHOLD,
  CHART_HAZARDOUS_THRESHOLD_TOLERANCE,
  HAZARDOUS_THRESHOLD,
  WasteShipmentsGraphs,
  WasteShipmentsGraphsProps,
  allowedGraphTypes,
} from "./WasteShipmentGraph";

const WASTE_SHIPMENTS = gql(`
  query WasteShipments($facilityId: ID!, $year: Int!) {
    wasteShipmentsByFacility(facilityId: $facilityId, year: $year) {
      hazardousWasteShipments {
        totalAmount
        totalHazardousAmount
        totalAcutelyHazardousAmount
        highestWasteMonths
        highestHazardousMonths
        highestAcutelyHazardousMonths
        byMonth {
          acutelyHazardous
          hazardous
          month
        }
      }
      wasteShipmentsByMonth {
        totalNonHazardousAmount
        totalHazardousAmount
        totalUniversalAmount
        byMonth {
          month
          nonHazardous
          hazardous
          universal
        }
      }
      highestWasteStreamShipments {
        wasteStream {
          id
          dotDescription
        }
        amount
      }
    }
  }
`);

const ingestedMessage = "Based on e-Manifest data ingested by Encamp";

const graphVariations: Record<
  WasteShipmentsGraphsProps["graphType"],
  { label: string; selectLabel: string; tooltip: ReactNode }
> = {
  hazardousWaste: {
    label: "Hazardous waste shipments in lbs",
    selectLabel: "Hazardous waste shipments",
    tooltip: (
      <Typography variant="caption">
        {ingestedMessage}, see how your historical RCRA hazardous waste
        shipments relate to federal generator status thresholds. If a threshold
        is exceeded in a calendar month, you may need to file an update
        notification with the EPA.
      </Typography>
    ),
  },
  hazardousWasteByMonth: {
    label: "Waste shipments by month in lbs",
    selectLabel: "Waste shipments by month",
    tooltip: (
      <Typography variant="caption">
        {ingestedMessage}, see how your historical waste shipments are broken
        down by classification.
      </Typography>
    ),
  },
  highestGeneratingWasteStreams: {
    label: "Highest waste stream shipments in lbs",
    selectLabel: "Highest waste stream shipments",
    tooltip: (
      <Typography variant="caption">
        {ingestedMessage}, see the waste streams at your facility that
        contribute the most to your waste shipments.
      </Typography>
    ),
  },
};

export type WasteShipmentFilter = {
  hazardousWaste: { focus?: "acutelyHazardous" | "hazardous" };
};

export const WasteShipmentsTile = () => {
  return (
    <DashboardTile xs={12}>
      <WasteShipmentsContent />
    </DashboardTile>
  );
};

function WasteShipmentsContent() {
  const theme = useTheme();

  const { facilityId } = useParams<{ facilityId: string }>();
  const {
    wasteFacilityState: { immediateFilters, deferredFilters },
    setWasteFacilityState,
  } = useWasteFacilityState();

  const [localFilter, setLocalFilter] = useState<WasteShipmentFilter>({
    hazardousWaste: {},
  });

  const selectedGraphType =
    immediateFilters.graphType &&
    allowedGraphTypes.includes(immediateFilters.graphType)
      ? immediateFilters.graphType
      : "hazardousWaste";

  const selectedYear = immediateFilters.year
    ? Number(immediateFilters.year)
    : DateTime.now().year;

  const deferredYear = deferredFilters.year
    ? Number(deferredFilters.year)
    : DateTime.now().year;

  const { data } = useSuspenseQuery(WASTE_SHIPMENTS, {
    variables: {
      facilityId: facilityId ?? "",
      year: deferredYear,
    },
  });

  const hasShipmentsForSelectedGraph = useMemo(() => {
    if (selectedGraphType === "hazardousWaste") {
      return (
        (data?.wasteShipmentsByFacility.hazardousWasteShipments?.totalAmount ??
          0) > 0
      );
    }
    if (selectedGraphType === "hazardousWasteByMonth") {
      return (
        (data?.wasteShipmentsByFacility.wasteShipmentsByMonth
          ?.totalHazardousAmount ?? 0) > 0
      );
    }
    if (selectedGraphType === "highestGeneratingWasteStreams") {
      return (
        (data.wasteShipmentsByFacility.highestWasteStreamShipments?.length ??
          0) > 0
      );
    }

    return false;
  }, [selectedGraphType, data]);

  const onWasteShipmentClick = useCallback(
    (event: any, item: any) => {
      const clickedType = item.seriesId; // should be the type of waste set on the series.id
      const updatedFilter =
        clickedType === localFilter.hazardousWaste.focus
          ? undefined // This effectively toggles the filter off
          : clickedType;
      setLocalFilter({
        ...localFilter,
        hazardousWaste: {
          focus: updatedFilter,
        },
      });
    },
    [localFilter]
  );

  const showAcuteThreshold = useMemo(() => {
    if (localFilter.hazardousWaste?.focus === "hazardous") {
      return false;
    }
    const monthlyData =
      data.wasteShipmentsByFacility?.hazardousWasteShipments?.byMonth ?? [];
    return monthlyData
      .map((monthData) => ({
        acutelyHazardous: monthData.acutelyHazardous,
      }))
      .some((d) => d.acutelyHazardous >= ACUTELY_HAZARDOUS_THRESHOLD);
  }, [data, localFilter]);

  const showLqgThreshold = useMemo(() => {
    if (localFilter.hazardousWaste?.focus === "acutelyHazardous") {
      return false;
    }
    const monthlyData =
      data.wasteShipmentsByFacility?.hazardousWasteShipments?.byMonth ?? [];
    return monthlyData
      .map((monthData) => ({
        hazardous: monthData.hazardous,
      }))
      .some(
        (d) =>
          d.hazardous >=
          HAZARDOUS_THRESHOLD - CHART_HAZARDOUS_THRESHOLD_TOLERANCE
      );
  }, [data, localFilter]);

  const totalSelectedWaste = useMemo(() => {
    let amount =
      data?.wasteShipmentsByFacility.hazardousWasteShipments?.totalAmount ?? 0;

    if (localFilter.hazardousWaste?.focus === "acutelyHazardous") {
      amount =
        data?.wasteShipmentsByFacility.hazardousWasteShipments
          ?.totalAcutelyHazardousAmount ?? 0;
    }

    if (localFilter.hazardousWaste?.focus === "hazardous") {
      amount =
        data?.wasteShipmentsByFacility.hazardousWasteShipments
          ?.totalHazardousAmount ?? 0;
    }

    return prettyPrintLargeQuantity(amount, UnitType.Pounds, true);
  }, [data, localFilter]);

  const totalWasteLabel = useMemo(() => {
    if (localFilter.hazardousWaste?.focus === "acutelyHazardous") {
      return "Total acutely hazardous waste";
    }
    if (localFilter.hazardousWaste?.focus === "hazardous") {
      return "Total hazardous waste";
    }
    return "Total waste";
  }, [localFilter]);

  const highestMonths = useMemo(() => {
    const hazardousWasteShipments =
      data?.wasteShipmentsByFacility.hazardousWasteShipments;

    if (localFilter.hazardousWaste?.focus === "acutelyHazardous") {
      return hazardousWasteShipments?.highestAcutelyHazardousMonths;
    }
    if (localFilter.hazardousWaste?.focus === "hazardous") {
      return hazardousWasteShipments?.highestHazardousMonths;
    }
    return hazardousWasteShipments?.highestWasteMonths;
  }, [
    data?.wasteShipmentsByFacility.hazardousWasteShipments,
    localFilter.hazardousWaste?.focus,
  ]);

  return (
    <>
      <DashboardTile.Header
        title="Waste Shipments"
        infoTooltip={
          <Typography variant="caption">
            {ingestedMessage}, use the dropdown on the right to view historical
            information about this facility's waste shipments.
          </Typography>
        }
      >
        <Stack direction="row" spacing={2}>
          <YearPicker
            value={selectedYear}
            minYear={new Date().getFullYear() - 2}
            onChange={(year) =>
              setWasteFacilityState((f) => {
                f.year = year?.toString();
              })
            }
          />
          <FormControl sx={{ width: theme.spacing(32.5) }}>
            <InputLabel id="view-by">View by</InputLabel>
            <Select
              sx={{ height: theme.spacing(5) }}
              fullWidth
              labelId="view-by"
              label="View by"
              value={selectedGraphType}
              onChange={(event) =>
                setWasteFacilityState((f) => {
                  f.graphType = event.target
                    .value as WasteShipmentsGraphsProps["graphType"];
                })
              }
            >
              {Object.entries(graphVariations).map(([key, { selectLabel }]) => (
                <MenuItem key={key} value={key}>
                  {selectLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DashboardTile.Header>

      {hasShipmentsForSelectedGraph && (
        <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
          {selectedGraphType === "hazardousWaste" && (
            <DashboardTile.ContentArea
              sx={{
                flex: { xs: 0, lg: 0.21 },
              }}
            >
              <Stack
                pl={{ xs: 0, lg: 2 }}
                gap={2}
                direction={{ xs: "row", lg: "column" }}
              >
                <StatDisplay
                  label={totalWasteLabel}
                  value={totalSelectedWaste}
                />
                <StatDisplay
                  label={`Highest month${
                    highestMonths && highestMonths.length > 1 ? "s" : ""
                  }`}
                  value={highestMonths}
                />
              </Stack>
            </DashboardTile.ContentArea>
          )}

          {selectedGraphType === "hazardousWasteByMonth" && (
            <DashboardTile.ContentArea
              pl={{ xs: 0, lg: 2 }}
              gap={2}
              direction={{ xs: "row", lg: "column" }}
              sx={{ flex: 0.21 }}
            >
              <StatDisplay
                label="Total non-hazardous waste"
                value={prettyPrintLargeQuantity(
                  data?.wasteShipmentsByFacility.wasteShipmentsByMonth
                    ?.totalNonHazardousAmount ?? 0,
                  UnitType.Pounds,
                  true
                )}
              />
              <StatDisplay
                label="Total hazardous waste"
                value={prettyPrintLargeQuantity(
                  data?.wasteShipmentsByFacility.wasteShipmentsByMonth
                    ?.totalHazardousAmount ?? 0,
                  UnitType.Pounds,
                  true
                )}
              />
              <StatDisplay
                label="Total universal waste"
                value={prettyPrintLargeQuantity(
                  data?.wasteShipmentsByFacility.wasteShipmentsByMonth
                    ?.totalUniversalAmount ?? 0,
                  UnitType.Pounds,
                  true
                )}
              />
            </DashboardTile.ContentArea>
          )}

          <DashboardTile.ContentArea sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="center">
              <Typography>
                {graphVariations[selectedGraphType].label}
              </Typography>
              <BigTooltip title={graphVariations[selectedGraphType].tooltip}>
                <IconButton size="small">
                  <InfoOutlined color="disabled" />
                </IconButton>
              </BigTooltip>
            </Stack>

            <Box height={theme.spacing(34)}>
              {selectedGraphType === "hazardousWaste" && (
                <HazardousWasteLegend
                  showAcuteThreshold={showAcuteThreshold}
                  showLqgThreshold={showLqgThreshold}
                  filter={localFilter}
                  onItemClick={(item: number) => {
                    const isAcutelyHazardous = item === 0;
                    const wasteType = isAcutelyHazardous
                      ? "acutelyHazardous"
                      : "hazardous";
                    const currentFocus = localFilter.hazardousWaste.focus;
                    const isTogglingOff = currentFocus === wasteType;

                    setLocalFilter({
                      ...localFilter,
                      hazardousWaste: {
                        focus:
                          isTogglingOff || item > 1 ? undefined : wasteType,
                      },
                    });
                  }}
                />
              )}
              {selectedGraphType === "hazardousWasteByMonth" && (
                <HazardousWasteByMonthLegend />
              )}
              <WasteShipmentsGraphs
                filter={localFilter}
                graphType={selectedGraphType}
                data={data}
                onItemClick={onWasteShipmentClick}
              />
            </Box>
          </DashboardTile.ContentArea>
        </Stack>
      )}
      {!hasShipmentsForSelectedGraph && (
        <Stack direction={{ xs: "column", lg: "row" }} spacing={8}>
          No shipment data found for this facility in the selected year.
        </Stack>
      )}
    </>
  );
}

function HazardousWasteLegend(props: {
  filter: WasteShipmentFilter;
  onItemClick: (item: any) => void;
  showLqgThreshold: boolean;
  showAcuteThreshold: boolean;
}) {
  const selectedIndex = useMemo(() => {
    if (props.filter.hazardousWaste?.focus === "acutelyHazardous") {
      return 0;
    }
    if (props.filter.hazardousWaste?.focus === "hazardous") {
      return 1;
    }
    return undefined;
  }, [props]);
  const items: {
    color: string;
    value: string;
    tooltip?: string;
    type?: "swatch" | "dashed-line";
  }[] = [
    {
      color: deepOrange[900],
      value: `Acutely Hazardous Waste`,
      tooltip:
        "Waste that has a specific code (P, or one of the F-codes marked with an H)",
    },
    {
      color: deepOrange[300],
      value: `Hazardous Waste`,
      tooltip:
        "Waste that is flammable, corrosive, reactive, or toxic (D-codes), or is listed on one of the EPA's four lists of hazardous waste (F, K, U, or P codes)",
    },
  ];
  if (props.showAcuteThreshold) {
    items.push({
      color: pink[300],
      value: `Federal LQG acute threshold`,
      tooltip: "Shipped more than 2.2 lbs of acutely hazardous waste per month",
      type: "dashed-line",
    });
  }
  if (props.showLqgThreshold) {
    items.push({
      color: blue[900],
      value: `Federal LQG threshold`,
      tooltip:
        "Shipped more than 2,200 lbs of hazardous waste per month [or combo of hazardous + acutely hazardous]",
      type: "dashed-line",
    });
  }

  return (
    <ChartLegend
      config={{ direction: "row", position: "above" }}
      items={items}
      onItemClick={(item) => props.onItemClick(item)}
      selectedIndex={selectedIndex}
      sx={{ paddingLeft: 6, position: "absolute" }}
    />
  );
}

function HazardousWasteByMonthLegend() {
  return (
    <ChartLegend
      config={{ direction: "row", position: "above" }}
      items={[
        {
          color: lime[800],
          value: `Non-hazardous Waste`,
          tooltip: "Waste that causes no harm to human or environmental health",
        },
        {
          color: deepOrange[300],
          value: `Hazardous Waste`,
          tooltip:
            "Waste that is flammable, corrosive, reactive, or toxic (D-codes), or is listed on one of the EPA's four lists of hazardous waste (F, K, U, or P codes)",
        },
        {
          color: deepPurple[300],
          value: `Universal Waste`,
          tooltip:
            "Hazardous wastes shipped across various industries (batteries, pesticides, mercury-containing equipment, lamps, and aerosol cans) and are allowed to be handled under less stringent rules than other hazardous wastes.",
        },
      ]}
      onItemClick={(item) => console.log(item)}
      sx={{ paddingLeft: 6, position: "absolute" }}
    />
  );
}
