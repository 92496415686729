import {
  Box,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import React from "react";

type TruncateTypographyProps = {
  lines?: number;
  showTooltip?: boolean;
} & React.PropsWithChildren<any> &
  TypographyTypeMap;

export const TruncateTypography = ({
  lines = 1,
  children,
  showTooltip = false,
  ...typographyProps
}: TruncateTypographyProps) => {
  const truncateStyles: SxProps<Theme> = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: lines.toString(),
    WebkitBoxOrient: "vertical",
  };
  return (
    <Typography {...typographyProps} sx={showTooltip ? {} : truncateStyles}>
      {showTooltip ? (
        <Tooltip title={children}>
          <Box component="span" sx={truncateStyles}>
            {children}
          </Box>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </Typography>
  );
};
