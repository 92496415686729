import { useMutation } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { TenantCatalogProductFragment } from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCurrentUser } from "hooks/useCurrentUser";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getChemicalsBreadcrumb } from "util/breadcrumb";
import { ProductDetailForm } from "../Product";
import { DELETE_PRODUCT, TENANT_CATALOG_PRODUCTS } from "./schema";
import { ExportDataButton } from "components/ExportDataButton";

type Row = TenantCatalogProductFragment;

export const TenantProductsTable: React.FC = () => {
  const { tenantId, productId } = useParams();
  const alerts = useAlerts();
  const [selectedProductId, setSelectedProductId] = useState<
    string | null | undefined
  >(productId);
  const [deleteRow, setDeleteRow] = useState<Row | undefined>(undefined);

  const { isStaff } = useCurrentUser();

  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Products",
      to: `/o/${tenantId}/chemicals/catalog/products`,
    },
  ]);

  const [deleteProduct, { loading: deleting }] = useMutation(DELETE_PRODUCT);

  const handleConfirmDeletion = useCallback(async () => {
    try {
      if (deleteRow) {
        await deleteProduct({
          variables: {
            id: deleteRow.id,
          },
          refetchQueries: ["TenantCatalogProducts", "CatalogOverview"],
          onCompleted() {
            alerts.success("Successfully deleted product");
            setDeleteRow(undefined);
          },
        });
      }
    } catch (err) {
      alerts.error("An error occurred while deleting the measurement", err);
    }
  }, [alerts, deleteProduct, deleteRow]);

  const handleOpenProductDialog = useCallback((product?: Row) => {
    setSelectedProductId(product?.id ?? null);
  }, []);

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 2,
      },
      {
        field: "alternateId",
        headerName: "Alternate ID",
        flex: 1,
      },
      {
        field: "reference",
        headerName: "Reference",
        flex: 1,
      },
      {
        field: "issues",
        headerName: "Issues",
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell(params) {
          return <IssueCount issueCount={params.row.issues.length} />;
        },
      },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row }) => [
          <Tooltip title="Edit" key={1}>
            <GridActionsCellItem
              onClick={() => handleOpenProductDialog(row)}
              label="Edit Product"
              icon={<Edit />}
            />
          </Tooltip>,
          <Tooltip title="Delete" key={2}>
            <GridActionsCellItem
              onClick={() => setDeleteRow(row)}
              label="Delete Product"
              icon={<Delete />}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleOpenProductDialog, setDeleteRow]
  );

  const importExportButtons = useMemo(() => {
    const buttons = [
      <ExportDataButton
        key="export"
        disabled={!tenantId}
        tenantId={tenantId ?? ""}
        exportType="product-catalog"
        fileName="product-catalog"
      />,
    ];
    if (isStaff) {
      buttons.push(
        <ImportButtonWithDialog
          key="import"
          importers={[
            {
              name: "Products",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.Product}
                  refetchQueries={["TenantCatalogProducts"]}
                />
              ),
            },
            {
              name: "Product Chemicals",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.ProductChemical}
                />
              ),
            },
          ]}
        />
      );
    }
    return buttons;
  }, [isStaff, tenantId]);

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={TENANT_CATALOG_PRODUCTS}
        defaultSearch={`tenantId:${tenantId}`}
        getItems={(data) => data.products.items}
        getCount={(data) => data.products.count}
        noDataOnButtonClick={() => handleOpenProductDialog()}
        noDataMessage="No products have been added to the catalog yet."
        noDataButtonText="Add Product"
        initialSortModel={[{ field: "name", sort: "asc" }]}
        isRowSelectable={() => false}
        commandButtons={[
          ...importExportButtons,
          <Button
            key="add"
            variant="contained"
            onClick={() => handleOpenProductDialog()}
            sx={{ width: { xs: "100%", sm: "auto" } }}
            size="small"
          >
            Add Product
          </Button>,
        ]}
        excludeFilterColumns={["issues"]}
        onRowClick={({ row }) => handleOpenProductDialog(row)}
      />
      <ConfirmDialog
        loading={deleting}
        open={!!deleteRow}
        onClose={() => setDeleteRow(undefined)}
        onConfirm={handleConfirmDeletion}
        msg={`Are you sure you want to delete product "${deleteRow?.name}?"`}
      />
      {selectedProductId !== undefined && (
        <ProductDetailForm
          open={true}
          onClose={() => setSelectedProductId(undefined)}
          productId={selectedProductId ?? ""}
        />
      )}
    </Box>
  );
};
