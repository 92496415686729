import {
  Box,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode } from "react";

type StatDisplayProps = {
  label: string;
  value: ReactNode;
  subtext?: string;
  valueProps?: TypographyProps;
  center?: boolean;
};

export const StatDisplay = ({
  label,
  value,
  subtext,
  valueProps,
  center,
}: StatDisplayProps) => {
  return (
    <Stack
      direction="column"
      py={1}
      alignItems={center ? "center" : "flex-start"}
    >
      <Typography variant="caption" color="text.secondary" noWrap>
        {label}
      </Typography>
      <Box display="flex" alignItems="baseline" flexWrap="wrap">
        {Array.isArray(value) ? (
          <>
            <Stack direction="column" spacing={0.5}>
              {value.slice(0, 2).map((v, i) => (
                <Stat key={i} value={v} valueProps={valueProps} />
              ))}
              {value.length > 2 && (
                <Tooltip title={value.slice(2).join(", ")}>
                  <div>
                    <Stat value="..." valueProps={valueProps} />
                  </div>
                </Tooltip>
              )}
            </Stack>
          </>
        ) : (
          <Stat value={value} valueProps={valueProps} />
        )}
        {subtext && (
          <Typography
            variant="caption"
            color="text.secondary"
            component="span"
            sx={{ ml: 1, fontWeight: "500", flexShrink: 0 }}
          >
            {subtext}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

const Stat = ({
  value,
  valueProps,
}: {
  value: ReactNode;
  valueProps?: TypographyProps;
}) => {
  return (
    <Typography
      variant="h4"
      color="text.primary"
      component="span"
      {...valueProps}
      sx={{
        flexShrink: 1,
        minWidth: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...valueProps?.sx,
      }}
    >
      {value}
    </Typography>
  );
};
