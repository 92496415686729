import { FormControlLabel, Switch } from "@mui/material";
import { useQueryParams } from "hooks/useQueryParams";

type Props = {
  initialShowMyFacilities?: boolean;
};

export const ShowMyFacilitiesToggle: React.FC<Props> = ({
  initialShowMyFacilities = true,
}) => {
  const { setFilters } = useQueryParams();
  const showMyFacilities = useShowMyFacilities(initialShowMyFacilities);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={showMyFacilities}
          onChange={() =>
            setFilters((f) => {
              f.showMyFacilities = !showMyFacilities;
            })
          }
          name="showMyFacilities"
          color="primary"
        />
      }
      label="Only show my facilities"
    />
  );
};

export type ShowMyFacilitiesQueryParam = {
  showMyFacilities?: boolean;
};

/**
 * Returns the value of the showMyFacilities query param, or the default value if the query param is not set.
 * @param defaultValue - The default value to return if the query param is not set.
 * @returns The value of the showMyFacilities query param, or the default value if the query param is not set.
 */
export const useShowMyFacilities = (defaultValue?: boolean) => {
  const {
    filters: { showMyFacilities: showMyFacilitiesQueryParam },
  } = useQueryParams<ShowMyFacilitiesQueryParam>();

  return showMyFacilitiesQueryParam ?? defaultValue;
};
