import { Box } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import {
  RcraInfoFederalWasteGeneratorStatus,
  RcraInfoHdHandlersQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import invariant from "invariant";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ShowMyFacilitiesToggle,
  useShowMyFacilities,
} from "../ShowMyFacilitiesToggle";

interface RcraInfoProps {
  handleRowClick?: (params: GridRowParams) => void;
  initialPageSize?: number;
  initialFilterTenant?: boolean;
}

export type Row =
  RcraInfoHdHandlersQuery["rcraInfoHdHandlers"]["items"][number];

const RCRA_INFO_HD_HANDLERS = gql(`
  query RcraInfoHdHandlers($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String!, $filterTenantId: String) {
    rcraInfoHdHandlers(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId, filterTenantId: $filterTenantId) {
      items {
        id: handlerId
        handlerName
        activityLocation
        locationStreetNo
        locationStreet1 
        locationStreet2
        locationCity
        locationState
        locationZip
        locationCountry
        fedWasteGenerator
        contactEmailAddress
        ownerName
        operatorName
      }
      count
    }
  }
`);

export const RcraInfo: React.FC<RcraInfoProps> = ({
  handleRowClick,
  initialPageSize = 25,
  initialFilterTenant = true,
}) => {
  useBreadcrumb({ label: "RCRAInfo" });
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");

  const showMyFacilities = useShowMyFacilities(initialFilterTenant);

  const onRowClick = (params: GridRowParams) => {
    if (handleRowClick) {
      handleRowClick(params);
    } else {
      const row: Row = params.row;
      navigate(`/o/${tenantId}/insights/rcra-info/${row.id}`);
    }
  };

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      { field: "id", headerName: "EPA ID", flex: 0.6 },
      { field: "activityLocation", headerName: "State", flex: 0.25 },
      { field: "handlerName", headerName: "Handler Name", flex: 1.2 },
      {
        field: "locationAddress",
        headerName: "Address",
        flex: 1.8,
        valueGetter: (params) => {
          const {
            locationStreetNo,
            locationStreet1,
            locationStreet2,
            locationCity,
            locationState,
            locationZip,
            locationCountry,
          } = params.row;
          const streetNo = locationStreetNo ? `${locationStreetNo} ` : "";
          return (
            streetNo +
            [
              locationStreet1,
              locationStreet2,
              locationCity,
              locationState,
              locationZip,
              locationCountry,
            ]
              .filter(Boolean)
              .join(", ")
          );
        },
      },
      {
        field: "ownerName",
        headerName: "Owner Name",
        flex: 1,
      },
      {
        field: "operatorName",
        headerName: "Operator Name",
        flex: 1,
      },
      {
        field: "contactEmailAddress",
        headerName: "Contact Email",
        flex: 1,
        filterKeyType: "string",
      },
      {
        field: "fedWasteGenerator",
        headerName: "Federal Waste Generator",
        flex: 1,
        filterKeyType: "enum",
        enumValues: Object.values(RcraInfoFederalWasteGeneratorStatus),
      },
    ],
    []
  );

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <ShowMyFacilitiesToggle />
      </Box>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={RCRA_INFO_HD_HANDLERS}
        getItems={(data: any) => data?.rcraInfoHdHandlers?.items ?? []}
        getCount={(data: any) => data?.rcraInfoHdHandlers?.count ?? 0}
        noDataMessage="No RCRAInfo HD Handlers found."
        initialPageSize={initialPageSize}
        onRowClick={onRowClick}
        dataQueryVariables={{
          filterTenantId: showMyFacilities ? tenantId : null,
          tenantId,
        }}
      />
    </Box>
  );
};
