import {
  ContactReportRole,
  TankOwnerType,
} from "../generated-graphql/oak-resolver-types";
import { prettyPrintEnumValue } from "../utils/prettyPrintEnumValue";

export const getEPCRAContactRoles = (
  hmbpEnabled: boolean,
  ...facilityStates: Array<string | null | undefined>
): ContactReportRole[] => {
  const epcraRoles = [
    ContactReportRole.EpcraTierIiContact,
    ContactReportRole.EpcraEmergencyContact,
    ContactReportRole.EpcraEmergencyCoordinator,
    ContactReportRole.EpcraOwnerOperator,
    ContactReportRole.EpcraBilling,
    ContactReportRole.EpcraChemicalCarrier,
  ];

  if (
    hmbpEnabled &&
    facilityStates.length &&
    facilityStates.every((state) => state === "CA")
  ) {
    const caRoles = [
      ContactReportRole.EpcraCaOperator,
      ContactReportRole.EpcraCaOwner,
      ContactReportRole.EpcraCaPropertyOwner,
      ContactReportRole.EpcraCaTankOperator,
      ContactReportRole.EpcraCaTankOwner,
    ];
    return epcraRoles.concat(caRoles);
  }

  return epcraRoles;
};

export const contactRoleToLabel = (role: ContactReportRole) => {
  switch (role) {
    case ContactReportRole.EpcraCaOperator:
      return "Operator";
    case ContactReportRole.EpcraCaOwner:
      return "Owner";
    case ContactReportRole.EpcraCaPropertyOwner:
      return "Property Owner";
    case ContactReportRole.EpcraCaTankOperator:
      return "Tank Operator";
    case ContactReportRole.EpcraCaTankOwner:
      return "Tank Owner";
    case ContactReportRole.EpcraOwnerOperator:
      return "Owner / Operator";
    case ContactReportRole.EpcraEmergencyContact:
      return "Emergency Contact";
    case ContactReportRole.EpcraEmergencyCoordinator:
      return "Emergency Coordinator";
    case ContactReportRole.EpcraTierIiContact:
      return "Tier II Contact";
    case ContactReportRole.EpcraBilling:
      return "Billing Contact";
    case ContactReportRole.EpcraChemicalCarrier:
      return "Chemical Carrier";
    default:
      return prettyPrintEnumValue(role);
  }
};

export const getTankOwnerTypeOptions = () => {
  return [
    { display: "None", value: null },
    {
      display: "Local Agency/District",
      value: TankOwnerType.LocalAgency,
    },
    {
      display: "County Agency",
      value: TankOwnerType.CountyAgency,
    },
    {
      display: "State Agency",
      value: TankOwnerType.StateAgency,
    },
    {
      display: "Federal Agency",
      value: TankOwnerType.FederalAgency,
    },
    {
      display: "Non-Government",
      value: TankOwnerType.NonGovernment,
    },
  ];
};
