import {
  NavigateCallback,
  useNavigateWithOmnisearch,
} from "hooks/useNavigateWithOmnisearch";
import { useQueryParams } from "hooks/useQueryParams";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useDeferredValue,
  useMemo,
  useTransition,
} from "react";

type Filter = {
  programArea: string;
  naicsCode: string;
  naicsType: string;
};

export type OverviewState = Partial<Filter> & {
  isPending: boolean;
};

type OverviewContextType = {
  overviewState: OverviewState;
  setOverviewState: (cb: (state: Partial<Filter>) => void) => void;
  drillDownToEcho: NavigateCallback<{
    programArea?: string;
    naicsCode?: string;
  }>;
};

const OverviewStateContext = createContext<OverviewContextType | undefined>(
  undefined
);

export function useOverviewState() {
  const context = useContext(OverviewStateContext);
  if (!context) {
    throw new Error("useOverviewState must be used within an OverviewContext");
  }
  return context;
}

export function OverviewContext({ children }: { children: ReactNode }) {
  const { filters, setFilters } = useQueryParams<Filter>();

  const { programArea, naicsCode, naicsType } = useDeferredValue(filters);

  const [isPending, startTransition] = useTransition();

  // This is a combination of the url filter state and the pending suspense state
  const overviewState = useMemo(
    () => ({ programArea, naicsCode, naicsType, isPending }),
    [programArea, naicsCode, naicsType, isPending]
  );

  // This is a way to update the mutable state
  const setOverviewState = useCallback(
    (cb: (state: Partial<Filter>) => void) => {
      startTransition(() => {
        setFilters((f) => {
          cb(f);
        });
      });
    },
    [setFilters]
  );

  const drillDownToEcho = useNavigateWithOmnisearch({
    to: "../echo",
    filters: {
      programArea,
      naicsCode: naicsCode === "Other" ? undefined : naicsCode,
    },
  });

  const value = useMemo(
    () => ({ overviewState, setOverviewState, drillDownToEcho }),
    [overviewState, setOverviewState, drillDownToEcho]
  );

  return (
    <OverviewStateContext.Provider value={value}>
      {children}
    </OverviewStateContext.Provider>
  );
}
