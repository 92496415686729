import { Box, Paper, Typography, useTheme } from "@mui/material";
import { TruncateTypography } from "components/TruncateTypography";
import { AMOUNT_CODES_DISPLAY } from "encamp-shared/src/constants/storageAmounts";
import { ReportingFacilityChemicalQuery } from "generated-graphql/graphql";
import { useProducts } from "hooks/useProducts";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";

export const SummaryCard = ({
  rfc,
}: {
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"];
}) => {
  const { hasProducts } = useProducts();
  const maxWidthAmountBoxes = hasProducts ? "20%" : "30%";
  const maxWidthSummaryBoxes = hasProducts ? "27%" : "30%";
  return (
    <Paper
      variant="outlined"
      sx={{ p: 3, display: "flex", justifyContent: "space-between", gap: 2 }}
    >
      <Box maxWidth={maxWidthAmountBoxes}>
        <Typography variant="body2">Max Daily Amount</Typography>
        <TruncateTypography variant="h6" showTooltip>
          {prettyPrintQuantity(rfc.maxAmount, rfc.unit, true)}
        </TruncateTypography>
        <TruncateTypography variant="body2" lines={1} showTooltip>
          {rfc.maxAmountCode
            ? AMOUNT_CODES_DISPLAY[rfc.maxAmountCode]
            : undefined}
        </TruncateTypography>
      </Box>
      <Box flex={1} />
      <Box maxWidth={maxWidthAmountBoxes}>
        <Typography variant="body2">Avg Daily Amount</Typography>
        <TruncateTypography variant="h6" showTooltip>
          {prettyPrintQuantity(rfc.averageAmount, rfc.unit, true)}
        </TruncateTypography>
        <TruncateTypography variant="body2" showTooltip>
          {rfc.averageAmountCode
            ? AMOUNT_CODES_DISPLAY[rfc.averageAmountCode]
            : undefined}
        </TruncateTypography>
      </Box>

      <Box flex={1} />
      {hasProducts && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            maxWidth={maxWidthSummaryBoxes}
          >
            <LineItem
              label="Products"
              value={
                rfc.maxAmountProductChemicalBreakdown?.productMaxAmount
                  ? prettyPrintQuantity(
                      rfc.maxAmountProductChemicalBreakdown.productMaxAmount
                        .amount,
                      rfc.maxAmountProductChemicalBreakdown.productMaxAmount
                        .unit,
                      true
                    )
                  : prettyPrintQuantity(0, rfc.unit, true)
              }
              valueColumnAlign="left"
            />
            <LineItem
              label="Other Chemicals"
              value={
                rfc.maxAmountProductChemicalBreakdown?.chemicalMaxAmount
                  ? prettyPrintQuantity(
                      rfc.maxAmountProductChemicalBreakdown.chemicalMaxAmount
                        .amount,
                      rfc.maxAmountProductChemicalBreakdown.chemicalMaxAmount
                        .unit,
                      true
                    )
                  : prettyPrintQuantity(0, rfc.unit, true)
              }
              valueColumnAlign="left"
            />
            <LineItem
              label="Total"
              value={prettyPrintQuantity(rfc.maxAmount, rfc.unit, true)}
              bold
              valueColumnAlign="left"
            />
          </Box>
          <Box flex={1} />
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        maxWidth={maxWidthSummaryBoxes}
      >
        <LineItem
          label="Largest Container"
          value={prettyPrintQuantity(
            rfc.maxAmountLargestContainer,
            rfc.unit,
            true
          )}
          valueColumnAlign="right"
        />
        <LineItem
          label="Days on Site"
          value={`${rfc.daysOnSite} days`}
          valueColumnAlign="right"
        />
        <LineItem
          label={`Last Year (${rfc.reportingYear - 1})`}
          value={
            rfc.previousYearMaxAmount
              ? prettyPrintQuantity(
                  rfc.previousYearMaxAmount.amount,
                  rfc.previousYearMaxAmount.unit,
                  true
                )
              : "N/A"
          }
          valueColumnAlign="right"
        />
      </Box>
    </Paper>
  );
};

const LineItem: React.FC<{
  label: string;
  value: string | number | undefined;
  bold?: boolean;
  valueColumnAlign?: "left" | "right";
}> = ({ valueColumnAlign, label, value, bold }) => {
  const fontWeight = bold ? "bold" : undefined;
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="baseline" flexWrap="nowrap">
      <Typography fontWeight={fontWeight} variant="body2" noWrap flexShrink={0}>
        {label}
      </Typography>
      <Box
        sx={{
          minWidth: "1rem",
          flex: 1,
          borderBottom: value ? "1px dotted gray" : undefined,
          mx: 0.5,
        }}
      />
      <TruncateTypography fontWeight={fontWeight} variant="body2" showTooltip>
        {value}
      </TruncateTypography>
    </Box>
  );
};
