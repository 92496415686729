import { useState, useEffect, useRef } from "react";
import { isEqual } from "lodash";

export const useDebounce = <T = any>(value: T, delay: number): [T, boolean] => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isWaiting, setIsWaiting] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const previousValueRef = useRef<T>(value);

  useEffect(() => {
    if (!isEqual(value, previousValueRef.current)) {
      setIsWaiting(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setDebouncedValue(value);
        setIsWaiting(false);
        previousValueRef.current = value;
      }, delay);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [value, delay]);

  return [debouncedValue, isWaiting];
};
