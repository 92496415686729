import { useParams } from "react-router-dom";
import {
  Echo,
  EchoMyFacilitiesRow as EchoRow,
} from "routes/Customer/Insights/Echo";
import { EchoDetail } from "routes/Customer/Insights/Echo/EchoDetail";
import { ComparisonFields, InsightsLinker } from "./InsightsLinker";

export function EchoProfile() {
  const { facilityId } = useParams<{ facilityId: string }>();

  const mapper = (rowData: EchoRow): ComparisonFields => ({
    name: rowData["echoFacilityName"] ?? "",
    streetAddress: rowData["facilityStreet"] ?? "",
    streetAddress2: "",
    city: rowData["facilityCity"] ?? "",
    state: rowData["facilityState"] ?? "",
    zip: rowData["facilityZip"] ?? "",
    id: rowData["id"] ?? "",
  });

  return (
    <>
      <InsightsLinker
        componentIfLinked={EchoDetail}
        componentIfUnlinked={Echo}
        facilityId={facilityId || ""}
        type="FRS"
        comparisonMapper={mapper}
      />
    </>
  );
}
