import { useQuery } from "@apollo/client";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useTenant } from "hooks/useTenant";
import { MY_FACILITY_TAGS_QUERY } from "queries/myFacilityTags";

export function FacilityTagsPicker({
  value,
  onChange,
}: {
  value?: string[] | null;
  onChange: (tags: string[]) => void;
}) {
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    changeValue: string[]
  ) => {
    onChange(changeValue.sort());
  };

  const { tenantId } = useTenant();

  const { data, loading } = useQuery(MY_FACILITY_TAGS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      search: `tenantId:${tenantId} type:Facility`,
    },
  });

  const tags = data ? data?.tags.items.map((tag) => tag.name).sort() : [];

  return (
    <FormControl fullWidth>
      <Autocomplete
        size="small"
        multiple
        options={tags}
        value={value ?? undefined}
        loading={loading}
        renderInput={(params) => (
          <TextField {...params} label="Facility Tags" />
        )}
        onChange={handleChange}
      />
    </FormControl>
  );
}
