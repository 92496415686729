import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type YearPickerProps = {
  value?: number | null;
  onChange: (newValue?: number | null) => void;
  minYear?: number;
  maxYear?: number;
};

export function YearPicker(props: YearPickerProps) {
  const currentYear = new Date().getFullYear();

  // Calculate bounds with defaults
  const maxYear = props.maxYear ?? currentYear;
  const minYear = props.minYear ?? maxYear - 9;

  // Generate array of years between bounds (inclusive)
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => maxYear - i
  ).sort((a, b) => b - a); // Sort descending for conventional display
  return (
    <FormControl>
      <InputLabel id="year-label">Year</InputLabel>
      <Select
        labelId="year-label"
        label="Year"
        value={props.value}
        size="small"
        onChange={(e) => props.onChange(Number(e.target.value))}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
