import {
  FormControl,
  GridProps,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { ExportDataButton } from "components/ExportDataButton";
import { useQueryParams } from "hooks/useQueryParams";
import { AnomaliesDataGrid } from "./AnomaliesDataGrid";
import { ProblematicReportsDataGrid } from "./ProblematicReportsDataGrid";
import { ProlificIssuesDataGrid } from "./ProlificIssuesDataGrid";
import { useMemo } from "react";
import { useTenant } from "hooks/useTenant";
import { kebabCase } from "lodash";

export function IssuesAndAnomaliesTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <IssuesAndAnomaliesContent />
    </DashboardTile>
  );
}

const GRID_TYPES = {
  ISSUES: { value: "issues", label: "Prolific issues" },
  REPORTS: { value: "reports", label: "Problematic reports" },
  ANOMALIES: { value: "anomalies", label: "Anomalies" },
} as const;

type GridTypeKey = keyof typeof GRID_TYPES;
type GridTypeValue = (typeof GRID_TYPES)[GridTypeKey]["value"];

function IssuesAndAnomaliesContent() {
  const theme = useTheme();
  const { filters, setFilters } = useQueryParams<{ gridType: string }>();
  const { tenantId } = useTenant();

  const gridType: GridTypeValue = useMemo(
    () =>
      Object.values(GRID_TYPES)
        .map<string>((type) => type.value)
        .includes(filters.gridType ?? "")
        ? (filters.gridType as GridTypeValue)
        : "issues",
    [filters.gridType]
  );

  const exportType = useMemo(() => {
    if (gridType === "issues") {
      return "prolificIssues";
    } else if (gridType === "reports") {
      return "problematicReports";
    } else if (gridType === "anomalies") {
      return "chemicalAnomalies";
    } else {
      return "";
    }
  }, [gridType]);

  return (
    <>
      <DashboardTile.Header
        title="Issues & Anomalies"
        infoTooltip={
          <Stack direction="column" spacing={2}>
            <Typography variant="caption">
              <Typography component="span" variant="caption" fontWeight={800}>
                Issues & Anomalies
              </Typography>{" "}
              displays reports detailing various compliance issues and data
              differences.
            </Typography>
            <Typography variant="caption">
              <Typography component="span" variant="caption" fontWeight={800}>
                Prolific Issues
              </Typography>{" "}
              shows compliance issues that appear on several reports.
            </Typography>
            <Typography variant="caption">
              <Typography component="span" variant="caption" fontWeight={800}>
                Problematic Reports
              </Typography>{" "}
              shows reports that have the most compliance issues.
            </Typography>
            <Typography variant="caption">
              <Typography component="span" variant="caption" fontWeight={800}>
                Anomalies
              </Typography>{" "}
              shows chemicals that were not reported last year, are no longer
              reported in the current year, or where the difference between
              years (increase or decrease) is greater than 50%.
            </Typography>
          </Stack>
        }
      >
        <Stack
          spacing={1.5}
          direction={{ xs: "column", sm: "row" }}
          sx={{ width: { xs: "100%", sm: "inherit" } }}
        >
          {exportType && (
            <ExportDataButton
              key="export"
              exportType={exportType}
              fileName={kebabCase(exportType)}
              tenantId={tenantId ?? ""}
            />
          )}
          <FormControl sx={{ width: theme.spacing(32.5) }}>
            <InputLabel id="view-by">View by</InputLabel>
            <Select
              sx={{ height: theme.spacing(5) }}
              fullWidth
              labelId="view-by"
              label="View by"
              value={gridType}
              onChange={(e) =>
                setFilters((f) => {
                  f.gridType = e.target.value;
                })
              }
            >
              {Object.entries(GRID_TYPES).map(([key, { value, label }]) => (
                <MenuItem key={key} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DashboardTile.Header>
      <DashboardTile.ContentArea sx={{ minHeight: "500px" }}>
        {gridType === "issues" && <ProlificIssuesDataGrid />}
        {gridType === "reports" && <ProblematicReportsDataGrid />}
        {gridType === "anomalies" && <AnomaliesDataGrid />}
      </DashboardTile.ContentArea>
    </>
  );
}
