import { cloneElement, useEffect } from "react";
import { useLongPollingForFile } from "../hooks/useLongPollingForFile";
import { useLongPollingForJob } from "hooks/useLongPollingForJob";
import { GetJobQuery } from "generated-graphql/graphql";
interface ChildWithOnClickAndLoadingProps extends React.ReactElement {
  props: {
    onClick: (event: React.MouseEvent) => void;
    loading: boolean;
  };
}

export interface LongPollingJobWrapperProps {
  jobId: string | undefined;
  children: ChildWithOnClickAndLoadingProps;
  timeoutInSeconds?: number;
  pollingCompleted: (job: GetJobQuery["job"]) => Promise<void>;
}

export function LongPollingJobWrapper({
  jobId,
  children,
  timeoutInSeconds,
  pollingCompleted,
}: LongPollingJobWrapperProps) {
  const { polling, startPolling, job } = useLongPollingForJob({
    timeoutInSeconds,
  });

  useEffect(() => {
    if (jobId) {
      startPolling(jobId);
    }
  }, [jobId, startPolling]);

  useEffect(() => {
    if (!job) return;
    if (!polling && job) pollingCompleted(job);
  }, [job]);

  const handleClick = (event: React.MouseEvent) => {
    if (children.props.onClick) {
      children.props.onClick(event);
    }
  };

  return cloneElement(children, {
    onClick: handleClick,
    loading: polling,
  });
}
