import { NetworkStatus, useQuery, useSuspenseQuery } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import InsightsIcon from "@mui/icons-material/Insights";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  GridProps,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { cyan, grey, orange } from "@mui/material/colors";
import { DefaultizedPieValueType } from "@mui/x-charts";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import DonutChart from "components/Dashboard/DonutChart";
import { gql } from "generated-graphql";
import { useTenant } from "hooks/useTenant";
import { MY_FACILITY_TAGS_QUERY } from "queries/myFacilityTags";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";

const MY_FACILITIES_QUERY = gql(`
  query MyFacilities($tenantId: ID!, $filter: ChemicalOverviewFilter) {
    myTierIIFacilities(tenantId: $tenantId, filter: $filter) {
      myReportingFacilityCount
      myNonReportingFacilityCount
      totalFacilityCount
      myAssignedFacilityCount
      myAuthorizedFacilityCount
    }
  }
`);

export function MyFacilitiesTile(props: GridProps) {
  return (
    <DashboardTile {...props}>
      <MyFacilityTileContent />
    </DashboardTile>
  );
}

function MyFacilityTileContent() {
  const theme = useTheme();
  const { tenantId } = useTenant();
  const {
    drillDown,
    chemicalOverviewState: {
      deferredFilters: { assignedToMe, facilityTags },
    },
  } = useChemicalOverviewState();

  const { data } = useSuspenseQuery(MY_FACILITIES_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: { assignedToMe, tagNames: facilityTags },
    },
    fetchPolicy: "cache-and-network",
  });

  const myFacilities = data?.myTierIIFacilities;

  const myTotalFacilities =
    (myFacilities?.myReportingFacilityCount ?? 0) +
    (myFacilities?.myNonReportingFacilityCount ?? 0);
  const myAuthorizedFacilityPercentage = Math.round(
    (myFacilities?.myAuthorizedFacilityCount /
      (myFacilities?.totalFacilityCount ?? 1)) *
      100
  );

  const seriesData = useMemo(() => {
    return [
      {
        id: 1,
        label: `Reporting (${(
          myFacilities?.myReportingFacilityCount ?? 0
        ).toLocaleString()})`,
        value: myFacilities?.myReportingFacilityCount ?? 0,
      },
      {
        id: 2,
        label: `Non-Reporting (${(
          myFacilities?.myNonReportingFacilityCount ?? 0
        ).toLocaleString()})`,
        value: myFacilities?.myNonReportingFacilityCount ?? 0,
      },
    ];
  }, [myFacilities]);

  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrillDown = (item: DefaultizedPieValueType) => {
    drillDown((f) => ({
      ...f,
      organizationStatus: item.id === 2 ? "NOT_REPORTING" : "!NOT_REPORTING",
    }));
  };

  return (
    <>
      <DashboardTile.Header
        title="My Tier II Facilities"
        infoTooltip={<MyFacilitiesTooltip />}
      >
        <MyFacilitiesFilterOptions
          disableAssignedToMe={myFacilities?.myAssignedFacilityCount === 0}
        />
      </DashboardTile.Header>
      <DashboardTile.ContentArea>
        <DonutChart
          seriesData={seriesData}
          label={myTotalFacilities.toLocaleString()}
          subLabel="Total"
          containerHeight="18rem"
          legend={{
            direction: "column",
            position: isLg ? (isMd ? "right" : "below") : "right",
          }}
          onItemClick={(event, pieItemIdentifier, item) =>
            handleDrillDown(item)
          }
          onLegendItemClick={(item) => handleDrillDown(item)}
          colors={[cyan[400], orange[400], grey[200]]}
          showTooltipTitle={false}
        />
      </DashboardTile.ContentArea>
      <DashboardTile.Footer>
        <Alert severity="info" icon={<InsightsIcon />}>
          <Typography>
            You have access to view{" "}
            <Typography component="span" fontWeight="800">
              {myFacilities?.myAuthorizedFacilityCount}
            </Typography>{" "}
            ({myAuthorizedFacilityPercentage}%) of the{" "}
            {myFacilities?.totalFacilityCount} facilities in your organization.
          </Typography>
        </Alert>
      </DashboardTile.Footer>
    </>
  );
}

function MyFacilitiesTooltip() {
  return (
    <Typography variant="caption">
      <Typography component="span" variant="caption" fontWeight={800}>
        My Tier II Facilities
      </Typography>{" "}
      displays the number of facilities in your organization that you have
      access to that file or don't file an annual Tier II report.
      <br />
      <br />
      You can apply a filter to show only the facilities that have reports that
      are assigned to you, and by any facility tags you have created.
    </Typography>
  );
}

function MyFacilitiesFilterOptions(props: { disableAssignedToMe?: boolean }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const {
    setChemicalOverviewState,
    chemicalOverviewState: {
      immediateFilters: { assignedToMe, facilityTags = [] },
    },
  } = useChemicalOverviewState();

  const handleTagsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTagsChange = (tags: string[]) => {
    setChemicalOverviewState((state) => {
      state.facilityTags = tags;
    });
  };

  const open = Boolean(anchorEl);

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip
        title={
          props.disableAssignedToMe
            ? "You don't have any facilities assigned to you."
            : ""
        }
        disableHoverListener={!props.disableAssignedToMe}
        disableFocusListener={!props.disableAssignedToMe}
        disableTouchListener={!props.disableAssignedToMe}
      >
        <span>
          {" "}
          {/* Wrapper to allow tooltip on disabled button */}
          <Button
            size="small"
            variant={assignedToMe ? "contained" : "outlined"}
            disabled={props.disableAssignedToMe}
            sx={{ borderRadius: 5, textTransform: "none" }}
            onClick={() => {
              setChemicalOverviewState((state) => {
                state.assignedToMe
                  ? delete state.assignedToMe
                  : (state.assignedToMe = true);
              });
            }}
          >
            Assigned to me
          </Button>
        </span>
      </Tooltip>
      <Button
        size="small"
        variant={facilityTags.length > 0 ? "contained" : "outlined"}
        sx={{
          borderRadius: 5,
          textTransform: "none",
          minWidth: theme.spacing(12),
          justifyContent: "space-between",
        }}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleTagsClick}
      >
        Tags {facilityTags.length > 0 && `(${facilityTags.length})`}
      </Button>
      <TagFilterPopover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        onTagsChange={handleTagsChange}
      />
    </Stack>
  );
}

const TAG_PAGE_SIZE = 10;
function TagFilterPopover(props: {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  open: boolean;
  onTagsChange: (tags: string[]) => void;
}) {
  const { tenantId } = useTenant();
  const {
    setChemicalOverviewState,
    chemicalOverviewState: {
      immediateFilters: { facilityTags: facilityTags = [] },
    },
  } = useChemicalOverviewState();

  const [search, setSearch] = useState("");
  const [searchQuery] = useDebounce(
    `tenantId:${tenantId} type:Facility${search ? ` name:${search}` : ""}`,
    300
  );
  const [currentPage, setCurrentPage] = useState(0);
  const {
    data: tagData,
    fetchMore,
    networkStatus,
    previousData,
  } = useQuery(MY_FACILITY_TAGS_QUERY, {
    variables: {
      search: searchQuery,
      sort: [{ field: "name", sort: "asc" }],
      page: 0,
      pageSize: TAG_PAGE_SIZE,
    },
    skip: !searchQuery,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const data = tagData ?? previousData;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        page: currentPage + 1,
        pageSize: TAG_PAGE_SIZE,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          tags: {
            ...fetchMoreResult.tags,
            items: [...(prev?.tags.items ?? []), ...fetchMoreResult.tags.items],
          },
        };
      },
    }).then(() => {
      setCurrentPage(currentPage + 1);
    });
  };

  const hasMoreTags =
    (data?.tags.count ?? 0) > (currentPage + 1) * TAG_PAGE_SIZE;

  const handleTagToggle = (tagName: string) => {
    setChemicalOverviewState((state) => {
      state.facilityTags = facilityTags.includes(tagName)
        ? facilityTags.filter((name) => name !== tagName)
        : [...facilityTags, tagName];
    });
  };

  const handleDeselectAll = () => {
    setChemicalOverviewState((state) => {
      state.facilityTags = [];
    });
    props.onClose();
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Stack sx={{ width: 300, p: 1.5 }} spacing={1}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Stack
          spacing={1}
          sx={{
            maxHeight: 300,
            overflow: "auto",
          }}
        >
          <Box
            onClick={handleDeselectAll}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": { bgcolor: "action.hover" },
            }}
          >
            <CloseIcon sx={{ mr: 1 }} />
            <Typography>Deselect All</Typography>
          </Box>
          {data?.tags.items.length === 0 ? (
            <Typography sx={{ color: "text.secondary" }}>
              No tags found
            </Typography>
          ) : (
            data?.tags.items.map((tag) => (
              <Box
                key={tag.id}
                onClick={() => handleTagToggle(tag.name)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": { bgcolor: "action.hover" },
                }}
              >
                <Checkbox
                  checked={facilityTags.includes(tag.name)}
                  sx={{ p: 0, mr: 1 }}
                />
                <Typography>{tag.name}</Typography>
              </Box>
            ))
          )}
          {hasMoreTags && (
            <LoadingButton
              onClick={handleLoadMore}
              loading={isLoadingMore}
              variant="outlined"
              size="small"
              fullWidth
            >
              Load More
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Popover>
  );
}
