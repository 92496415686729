import { ExportDataButton } from "components/ExportDataButton";
import { useMemo } from "react";
import { ContactGrid } from "components/Contacts/ContactGrid";
import { useTenant } from "hooks/useTenant";

export function OnboardingContacts({ tenantId }: { tenantId: string }) {
  const { tenant } = useTenant();
  // an isStaff check here may be a little overkill since we are nested under
  // a "staff" route... but the component doesn't know that, and it seems like
  // a decent candidate for getting refactored into a general-purpose contact
  // table
  const importExportButtons = useMemo(() => {
    const buttons = [
      <ExportDataButton
        key="export"
        disabled={!tenantId}
        tenantId={tenantId}
        exportType="contact"
        fileName={`${tenant?.name ?? tenantId}-contact-catalog`}
      />,
    ];

    return buttons;
  }, [tenantId]);

  return (
    <ContactGrid tenantId={tenantId} commandButtons={importExportButtons} />
  );
}
