import { Box } from "@mui/material";
import { TabLinks } from "components/TabLinks";
import InsightsUpsell from "components/UpsellLandingPages/InsightsUpsell";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { LoadingState } from "./LoadingState";

export function Insights() {
  const { tenant } = useTenant();
  useBreadcrumb({
    label: "Insights",
  });
  const match = useMatch({
    path: `/o/${tenant?.id}/insights/:tab`,
    end: false,
  });
  const { featureFlags: flags, loading: featureFlagsLoading } =
    useFeatureFlags();

  const hasInsights = flags?.["insights-ui"];

  const tabs = useMemo(
    () => [
      {
        title: "Overview",
        to: "overview",
      },
      {
        title: "Benchmark",
        to: "benchmark",
      },
      {
        title: "ECHO",
        to: "echo",
      },
      {
        title: "RCRAInfo",
        to: "rcra-info",
      },
      {
        title: "NPDES",
        to: "npdes",
      },
    ],
    []
  );

  const currentTab = useMemo(
    () => tabs.find((t) => t.to === match?.params.tab),
    [match?.params.tab, tabs]
  );

  if (featureFlagsLoading) {
    return <LoadingState />;
  }

  // Redirect to first tab if we don't have a match
  if (!currentTab) {
    return <Navigate to={tabs[0].to} />;
  }

  if (!hasInsights) {
    return <InsightsUpsell />;
  }

  return (
    <Box>
      <TabLinks
        value={currentTab.to}
        tabs={tabs}
        aria-label="insights tabs nav"
        role="navigation"
      />
      <Outlet />
    </Box>
  );
}
