import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { programAreaToStatuteCodes } from "encamp-shared/src/constants/echo";
import { gql } from "generated-graphql";
import {
  FacilityStatus,
  InsightsEchoFacilitiesQuery,
  InsightsEchoAllFacilitiesQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import invariant from "invariant";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ShowMyFacilitiesToggle,
  useShowMyFacilities,
} from "../ShowMyFacilitiesToggle";

const sortedProgramAreas = Array.from(programAreaToStatuteCodes.keys()).sort(
  (a, b) => a.localeCompare(b)
);

interface EchoProps {
  handleRowClick?: (params: GridRowParams) => void;
  initialPageSize?: number;
  initialFilterTenant?: boolean;
}

export type EchoMyFacilitiesRow =
  InsightsEchoFacilitiesQuery["insightsEchoMyFacilities"]["items"][number];

const FACILITY_ECHO_EXPORTER = gql(`
  query InsightsEchoFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String!) {
    insightsEchoMyFacilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        id: key
        registryId
        facilityId
        customerFacilityId
        echoFacilityName
        facilityStreet
        facilityCity
        facilityState
        facilityZip
        facilityCounty
        nonComplianceCountLast12Qrtrs
        violationCountLast12Qrtrs
        penaltiesLast12Qrtrs
        echoFirstNaicsCode
      }
      count
    }
  }
`);

const ECHO_ALL_FACILITIES = gql(`
  query InsightsEchoAllFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String!) {
    insightsEchoAllFacilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        id: registryId
        registryId
        echoFacilityName
        facilityStreet
        facilityCity
        facilityState
        facilityZip
        facilityCounty
        nonComplianceCountLast12Qrtrs
        violationCountLast12Qrtrs
        penaltiesLast12Qrtrs
        echoFirstNaicsCode
      }
      count
    }
  }
`);

export type AllFacilitiesRow =
  InsightsEchoAllFacilitiesQuery["insightsEchoAllFacilities"]["items"][number];

export const Echo: React.FC<EchoProps> = ({
  handleRowClick,
  initialPageSize = 25,
  initialFilterTenant = true,
}) => {
  useBreadcrumb({ label: "ECHO" });
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");

  const showMyFacilities = useShowMyFacilities(initialFilterTenant);

  return (
    <Grid item>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <ShowMyFacilitiesToggle />
      </Box>
      {showMyFacilities ? (
        <EchoMyFacilitiesOmnisearchGrid
          initialPageSize={initialPageSize}
          tenantId={tenantId}
          dataQuery={FACILITY_ECHO_EXPORTER}
          defaultSearch={`status:${FacilityStatus.Open} tenantId:${tenantId}`}
          handleRowClick={handleRowClick}
        />
      ) : (
        <EchoAllFacilitiesOmnisearchGrid
          initialPageSize={initialPageSize}
          tenantId={tenantId}
          dataQuery={ECHO_ALL_FACILITIES}
          defaultSearch={``}
          handleRowClick={handleRowClick}
        />
      )}
    </Grid>
  );
};

function EchoMyFacilitiesOmnisearchGrid({
  initialPageSize = 25,
  tenantId,
  dataQuery,
  defaultSearch,
  handleRowClick,
}: {
  initialPageSize?: number;
  tenantId: string;
  dataQuery: typeof FACILITY_ECHO_EXPORTER;
  defaultSearch?: string;
  handleRowClick?: (params: GridRowParams) => void;
}) {
  const navigate = useNavigate();
  const onRowClick = (params: GridRowParams) => {
    if (handleRowClick) {
      handleRowClick(params);
    } else {
      const row: EchoMyFacilitiesRow = params.row;
      navigate(`/o/${tenantId}/insights/echo/${row.registryId}`);
    }
  };

  const columns: GridColDef<EchoMyFacilitiesRow>[] = useMemo(
    () => [
      { field: "registryId", headerName: "Registry ID", flex: 0.6 },
      {
        field: "facilityName",
        headerName: "Facility Name",
        filterKeyType: "facility",
        flex: 1.2,
        valueGetter: (params) => {
          return params.row.echoFacilityName;
        },
      },
      {
        field: "customerFacilityId",
        headerName: "Customer Facility Id",
        flex: 0.6,
      },
      {
        field: "facilityState",
        headerName: "State",
        filterKeyType: "facilityState",
        flex: 1.2,
        valueGetter: (params) => {
          return params.row.facilityState;
        },
      },
      {
        field: "facilityAddress",
        headerName: "Address",
        flex: 1.8,
        valueGetter: (params) => {
          const { facilityStreet, facilityCity, facilityState, facilityZip } =
            params.row;
          return [facilityStreet, facilityCity, facilityState, facilityZip]
            .filter(Boolean)
            .join(", ");
        },
      },
      {
        field: "naicsCode",
        headerName: "NAICS Code",
        filterKeyType: "naicsCode",
        flex: 1.2,
        valueGetter: (params) => {
          return params.row.echoFirstNaicsCode;
        },
      },
      {
        field: "penaltiesLast12Qrtrs",
        headerName: "Penalties (last 12 quarters)",
        renderHeader: () => (
          <Tooltip title="Penalties (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Penalties
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.penaltiesLast12Qrtrs == 0
            ? null
            : `$${params.row.penaltiesLast12Qrtrs?.toLocaleString()}`,
      },
      {
        field: "violationCountLast12Qrtrs",
        headerName: "Violations (last 12 quarters)",
        renderHeader: () => (
          <Tooltip title="Violations (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Violations
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.violationCountLast12Qrtrs == 0
            ? null
            : params.row.violationCountLast12Qrtrs?.toLocaleString(),
      },
      {
        field: "nonComplianceCountLast12Qrtrs",
        headerName: "Quarters in Non-compliance (last 12 quarters)",
        renderHeader: () => (
          <Tooltip title="Quarters in Non-compliance (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Quarters in Non-compliance
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.nonComplianceCountLast12Qrtrs == 0
            ? null
            : params.row.nonComplianceCountLast12Qrtrs?.toLocaleString(),
      },
    ],
    []
  );

  return (
    <OmnisearchDataGrid
      columns={columns}
      dataQuery={dataQuery}
      excludeFilterColumns={[
        "nonComplianceCountLast12Qrtrs",
        "violationCountLast12Qrtrs",
        "penaltiesLast12Qrtrs",
      ]}
      showFavorites
      additionalFilterColumns={[
        {
          key: "programArea",
          header: "Facility Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas.filter(
            (programArea) => programArea !== "Chemicals"
          ),
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "programAreaPenalty",
          header: "Penalty Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas,
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "programAreaViolation",
          header: "Violation Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas,
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "programAreaNonCompliance",
          header: "Non-compliance Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas,
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "hasViolations",
          header: "Has Violations",
          filterKeyType: "boolean",
        },
        {
          key: "hasPenalties",
          header: "Has Penalties",
          filterKeyType: "boolean",
        },
        {
          key: "hasCurrentNonCompliance",
          header: "Has Current Non-compliance",
          filterKeyType: "boolean",
        },
        {
          key: "hasHistoricalNonCompliance",
          header: "Has Historical Non-compliance",
          filterKeyType: "boolean",
        },
      ]}
      getItems={(data) => data?.insightsEchoMyFacilities?.items ?? []}
      getCount={(data) => data?.insightsEchoMyFacilities?.count ?? 0}
      noDataMessage="No ECHO Exporters found."
      initialPageSize={initialPageSize}
      onRowClick={onRowClick}
      defaultSearch={defaultSearch}
      dataQueryVariables={{ tenantId }}
    />
  );
}

function EchoAllFacilitiesOmnisearchGrid({
  initialPageSize = 25,
  tenantId,
  dataQuery,
  defaultSearch,
  handleRowClick,
}: {
  initialPageSize?: number;
  tenantId: string;
  dataQuery: typeof ECHO_ALL_FACILITIES;
  defaultSearch?: string;
  handleRowClick?: (params: GridRowParams) => void;
}) {
  const navigate = useNavigate();
  const onRowClick = (params: GridRowParams) => {
    if (handleRowClick) {
      handleRowClick(params);
    } else {
      const row: AllFacilitiesRow = params.row;
      navigate(`/o/${tenantId}/insights/echo/${row.registryId}`);
    }
  };

  const columns: GridColDef<AllFacilitiesRow>[] = useMemo(
    () => [
      { field: "registryId", headerName: "Registry ID", flex: 0.6 },
      {
        field: "facilityName",
        headerName: "Facility Name",
        filterKeyType: "facility",
        flex: 1.2,
        valueGetter: (params) => {
          return params.row.echoFacilityName;
        },
      },
      {
        field: "facilityState",
        headerName: "State",
        filterKeyType: "facilityState",
        flex: 1.2,
        valueGetter: (params) => {
          return params.row.facilityState;
        },
      },
      {
        field: "facilityAddress",
        headerName: "Address",
        flex: 1.8,
        valueGetter: (params) => {
          const { facilityStreet, facilityCity, facilityState, facilityZip } =
            params.row;
          return [facilityStreet, facilityCity, facilityState, facilityZip]
            .filter(Boolean)
            .join(", ");
        },
      },
      {
        field: "naicsCode",
        headerName: "NAICS Code",
        filterKeyType: "naicsCode",
        flex: 1.2,
        valueGetter: (params) => {
          return params.row.echoFirstNaicsCode;
        },
      },
      {
        field: "penaltiesLast12Qrtrs",
        headerName: "Penalties (last 12 quarters)",
        renderHeader: () => (
          <Tooltip title="Penalties (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Penalties
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.penaltiesLast12Qrtrs == 0
            ? null
            : `$${params.row.penaltiesLast12Qrtrs?.toLocaleString()}`,
      },
      {
        field: "violationCountLast12Qrtrs",
        headerName: "Violations (last 12 quarters)",
        renderHeader: () => (
          <Tooltip title="Violations (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Violations
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.violationCountLast12Qrtrs == 0
            ? null
            : params.row.violationCountLast12Qrtrs?.toLocaleString(),
      },
      {
        field: "nonComplianceCountLast12Qrtrs",
        headerName: "Quarters in Non-compliance (last 12 quarters)",
        renderHeader: () => (
          <Tooltip title="Quarters in Non-compliance (last 12 quarters)">
            <Typography fontWeight="medium" fontSize="14px" noWrap>
              Quarters in Non-compliance
              <br />
              <span style={{ fontSize: "12px" }}>Last 12 quarters</span>
            </Typography>
          </Tooltip>
        ),
        flex: 1,
        valueGetter: (params) =>
          params.row.nonComplianceCountLast12Qrtrs == 0
            ? null
            : params.row.nonComplianceCountLast12Qrtrs?.toLocaleString(),
      },
    ],
    []
  );

  return (
    <OmnisearchDataGrid
      columns={columns}
      dataQuery={dataQuery}
      excludeFilterColumns={[
        "nonComplianceCountLast12Qrtrs",
        "violationCountLast12Qrtrs",
        "penaltiesLast12Qrtrs",
        "facilityName",
      ]}
      showFavorites
      additionalFilterColumns={[
        {
          key: "programArea",
          header: "Facility Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas.filter(
            (programArea) => programArea !== "Chemicals"
          ),
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "programAreaPenalty",
          header: "Penalty Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas,
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "programAreaViolation",
          header: "Violation Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas,
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "programAreaNonCompliance",
          header: "Non-compliance Program Area",
          filterKeyType: "enum",
          enumValues: sortedProgramAreas,
          enumPresentationFunction: (programArea: string) => programArea,
        },
        {
          key: "hasViolations",
          header: "Has Violations",
          filterKeyType: "boolean",
        },
        {
          key: "hasPenalties",
          header: "Has Penalties",
          filterKeyType: "boolean",
        },
        {
          key: "hasCurrentNonCompliance",
          header: "Has Current Non-compliance",
          filterKeyType: "boolean",
        },
        {
          key: "hasHistoricalNonCompliance",
          header: "Has Historical Non-compliance",
          filterKeyType: "boolean",
        },
      ]}
      getItems={(data) => data?.insightsEchoAllFacilities?.items ?? []}
      getCount={(data) => data?.insightsEchoAllFacilities?.count ?? 0}
      noDataMessage="No ECHO Exporters found."
      initialPageSize={initialPageSize}
      onRowClick={onRowClick}
      defaultSearch={defaultSearch}
      dataQueryVariables={{ tenantId }}
    />
  );
}
