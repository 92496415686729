// When adding a new template, add the template_key to this enum
export enum OneSchemaTemplateType {
  Chemical = "chemical",
  ChemicalComponent = "chemicalComponent",
  ChemicalStateField = "chemicalStateField",
  Contact = "contact",
  Facility = "facility",
  FacilityAlternateId = "facilityAlternateId",
  FacilityChemical = "facilityChemical",
  FacilityChemicalMeasurement = "facilityChemicalMeasurement",
  FacilityChemicalStateField = "facilityChemicalStateField",
  FacilityChemicalStorageLocation = "facilityChemicalStorageLocation",
  FacilityContact = "facilityContact",
  FacilityStateField = "facilityStateField",
  FacilityProduct = "facilityProduct",
  FacilityProductMeasurement = "facilityProductMeasurement",
  FacilityProductStorageLocation = "facilityProductStorageLocation",
  Product = "product",
  ProductChemical = "productChemical",
  StorageLocation = "storageLocation",
  Manifest = "manifest",
  UsEcologyManifest = "usEcologyManifest",
  RepublicManifest = "republicManifest",
  LEPC = "lepc",
  FireDepartment = "fireDepartment",
  CleanHarbors = "cleanHarbors",
  CleanEarthWaste = "cleanEarthWaste",
  OtherWasteManifest = "otherWasteManifest",
}

export const OneSchemaApiUrl = "https://api.oneschema.co/v1";

export type OneSchemaAuth = {
  clientId: string;
  clientSecret: string;
  apiKey: string;
  webhookSecret: string;
};

export type OneSchemaJWTPayload = {
  user_id: string;
  tenant_id: string;
};

/**
 * Creates a type from Encamp DB types omitting `created*` and `updated*` fields
 */
export type OmitMetadata<
  T extends object,
  TOmit extends keyof T = never
> = Omit<T, "createdAt" | "createdBy" | "updatedAt" | "updatedBy" | TOmit>;

// https://docs.oneschema.co/reference/template-resource#validation-types
export enum OneSchemaValidation {
  PICKLIST = "PICKLIST",
  NUMBER = "NUMBER",
  PERCENTAGE = "PERCENTAGE",
  DATE_MDY = "DATE_MDY",
  DATE_DMY = "DATE_DMY",
  DATE_ISO = "DATE_ISO",
  DATETIME_ISO = "DATETIME_ISO",
  DATETIME_MDYHM = "DATETIME_MDYHM",
  DATE_YMD = "DATE_YMD",
  DATE_DMMMY = "DATE_DMMMY",
  TIME_HHMM = "TIME_HHMM",
  UNIX_TIMESTAMP = "UNIX_TIMESTAMP",
  LOCATION_ADDRESS = "LOCATION_ADDRESS",
  URL = "URL",
  DOMAIN = "DOMAIN",
  FULL_NAME = "FULL_NAME",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
  UNIT_OF_MEASURE = "UNIT_OF_MEASURE",
  CURRENCY_CODE = "CURRENCY_CODE",
  PHONE_NUMBER = "PHONE_NUMBER",
  US_PHONE_NUMBER_EXT = "US_PHONE_NUMBER_EXT",
  MONEY = "MONEY",
  COUNTRY_CODE_A2 = "COUNTRY_CODE_A2",
  COUNTRY_CODE_A3 = "COUNTRY_CODE_A3",
  IANA_TIMEZONE = "IANA_TIMEZONE",
  CUSTOM_REGEX = "CUSTOM_REGEX",
  ALPHABETICAL = "ALPHABETICAL",
  TEXT = "TEXT",
  CAS_NUMBER = "CAS_NUMBER",
  SSN_MASKED = "SSN_MASKED",
  SSN_UNMASKED = "SSN_UNMASKED",
  ENUM_US_STATE_TERRITORY = "ENUM_US_STATE_TERRITORY",
  LOCATION_POSTALCODE = "LOCATION_POSTALCODE",
  UUID = "UUID",
}

export interface OneSchemaRecord {
  $custom?: { [key: string]: string };
  customKeys?: { [key: string]: string };
}

export type OneSchemaColumn<T extends OneSchemaRecord> = {
  label: string;
  key: keyof T | keyof T["customKeys"];
  data_type: OneSchemaValidation | null; // Must be set to match OneSchema API
  letter_case?: "LETTER_CASE_LOWER" | "LETTER_CASE_UPPER";
  is_required?: boolean;
  must_exist?: boolean;
  validation_options?: { [key: string]: any };
  mapping_hints?: string[];
  delimiter?: string;
  description?: string;
  is_custom?: boolean;
  is_unique?: boolean;
  min_char_limit?: number;
  max_char_limit?: number;
  default_value?: string;
};

export type OneSchemaValidationHook<T> = {
  name: string;
  url: string;
  secret_key: string;
  column_keys: (keyof T)[];
  hook_type: "row";
  batch_size?: number;
};

export type OneSchemaValidationHookStatic<T> = Omit<
  OneSchemaValidationHook<T>,
  "secret_key" | "url"
>;

export type OneSchemaTemplate<
  T extends OneSchemaRecord,
  V extends Partial<T> = {}
> = {
  name: string;
  columns: OneSchemaColumn<T>[];
  validation_hooks?: OneSchemaValidationHook<V>[];
  template_key: OneSchemaTemplateType | string;
  code_hooks?: string[];
};

export type OneSchemaTemplateStatic<
  T extends OneSchemaRecord,
  V extends Partial<T> = {}
> = Omit<OneSchemaTemplate<T, V>, "validation_hooks"> & {
  validation_hooks?: OneSchemaValidationHookStatic<V>[];
};

export interface OneSchemaValidationRequest<T> {
  rows: OneSchemaValidationRow<T>[];
  template_key: OneSchemaTemplateType;
  hook_id: number;
  hook_name: string;
  workspace_id: number;
  sheet_id: number;
  sheet_metadata: {};
  embed_user_jwt: string;
}

export interface OneSchemaValidationRow<T> {
  row_id: number;
  values: T;
}

export interface OneSchemaValidationRowToReturn<T> {
  row_id: number;
  column: keyof T;
  severity: "warning" | "error";
  message: string;
  suggestion?: string;
  options?: {};
}

export interface OneSchemaImportColumn<T extends OneSchemaRecord> {
  sheet_column_name: string;
  template_column_name: string;
  template_column_key: keyof T | keyof T["customKeys"];
  is_custom?: boolean;
}

export interface OneSchemaImportJson<T extends OneSchemaRecord> {
  template_key: OneSchemaTemplateType;
  workspace_id: number;
  workspace_metadata: {};
  sheet_id: number;
  sheet_metadata: {
    original_file_name: string;
    original_sheet_name?: string;
  };
  columns: OneSchemaImportColumn<T>[];
  count: number;
  records: T[];
  error_records: [
    {
      data: T;
      errors: { [x in keyof T]: [number] };
    }
  ];
}
