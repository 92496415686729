import { useEffect, useState } from "react";
import { useDownloadReportPreview } from "routes/Customer/Chemicals/Report/useDownloadReportPreview";
import { LongPollingJobWrapper } from "./LongPollingJobWrapper";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import Download from "@mui/icons-material/Download";
import { useLongPollingForJob } from "hooks/useLongPollingForJob";
import { useCheckForFile } from "hooks/useCheckForFile";
import { useAlerts } from "./Alerts/AlertProvider";
import { GetJobQuery } from "generated-graphql/graphql";
import { JobStatus } from "encamp-shared/src/generated-graphql/oak-resolver-types";

export function GeneratePdfButton({
  reportId,
  label,
  ...loadingButtonProps
}: {
  reportId: string;
  label?: string;
} & LoadingButtonProps) {
  const { downloadReportPreview } = useDownloadReportPreview();
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [reportPdfS3Key, setReportPdfS3Key] = useState<string | undefined>(
    undefined
  );
  const { fetchFile } = useCheckForFile({ key: reportPdfS3Key });
  const alerts = useAlerts();

  const handleDownloadClick = async () => {
    const result = await downloadReportPreview(reportId);
    setReportPdfS3Key(result?.s3Key);
    setJobId(result?.jobId);
  };

  const pollingCompleted = async (completedJob: GetJobQuery["job"]) => {
    if (completedJob?.status === JobStatus.Failed) {
      alerts.error("Failed to generate PDF");
      return;
    }

    const signedUrl = await fetchFile();

    if (signedUrl) {
      window.open(signedUrl, "_blank");
      return;
    }
    alerts.error("Failed to generate PDF");
  };

  return (
    <LongPollingJobWrapper jobId={jobId} pollingCompleted={pollingCompleted}>
      <LoadingButton
        startIcon={<Download />}
        color="primary"
        size="small"
        onClick={handleDownloadClick}
        {...loadingButtonProps}
      >
        {label ?? "Download Preview"}
      </LoadingButton>
    </LongPollingJobWrapper>
  );
}
